import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../components/layouts/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import { errorConverter, FastapiErrorT } from "../../../utils/fastapi";
import { UserEditType, UserPasswordType } from "../../../types/companyUser";
import CompanyUserService from "../../../infrastructure/api/company/user";
import useAuthAxios from "../../../hooks/useAuthAxios";
import { CompanyUserEditForm } from "./EditForm";
import { EditModal } from "../../../components/ui/EditModal";
import { PasswordField } from "../../../components/forms/InputPassword";

const CompanyUserEdit: React.FC = () => {
  const [errors, setErrors] = useState<FastapiErrorT>();
  const [user, setUser] = useState<UserEditType>({
    full_name: "",
    email: "",
    is_superuser: false,
    thumbnail: "",
    is_active: false,
  });
  const [passwords, setPasswords] = useState<UserPasswordType>({
    password: "",
    password2: "",
  });
  const { id } = useParams();
  const service = new CompanyUserService(useAuthAxios());
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      service.get(id).then((data) => setUser(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangePassword = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setPasswords((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (id) {
      service
        .update(id, user)
        .then(() => {
          navigate("/companies/user/");
        })
        .catch((err) => {
          setErrors(err);
        });
    }
  };

  const handlePasswordSave = () => {
    if (id) {
      return service
        .updatePassword(id, passwords)
        .then(() => {})
        .catch((err) => {
          setErrors(err);
          throw err;
        });
    }
    throw new Error("ID is null");
  };

  return (
    <MainLayout title="アカウント編集">
      <CompanyUserEditForm<UserEditType>
        user={user}
        setUser={setUser}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={errors}
      >
        <EditModal
          handleSave={() => {
            return handlePasswordSave().catch((err) => {
              throw err;
            });
          }}
          buttonClassName="bg-transparent"
          button={<span className="text-blue-500">パスワードを変更</span>}
          openCallback={() => {
            setPasswords({ password: "", password2: "" });
          }}
        >
          <PasswordField
            label="パスワード"
            name="password"
            value={passwords.password || ""}
            handleChange={handleChangePassword}
            error={errorConverter(errors?.detail, "password")}
          />
          <PasswordField
            label="パスワード確認"
            name="password2"
            value={passwords.password2 || ""}
            handleChange={handleChangePassword}
            error={errorConverter(errors?.detail, "password2")}
          />
        </EditModal>
      </CompanyUserEditForm>
    </MainLayout>
  );
};

export default CompanyUserEdit;
