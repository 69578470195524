import axios from "axios";
import { SortingRule } from "react-table";
import { PostType } from "../../../types/post";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class PostService {
  getList(
    post_type: "blog" | "news",
    page: number,
    limit: number,
    order_by?: SortingRule<PostListType>[]
  ) {
    return axios
      .post<PaginatedDataType<PostListType>>(API_URL + "/api/v1/admin/posts/post/q/", {
        post_type: post_type,
        page: page,
        limit: limit,
        order_by: order_by,
      })
      .then<PaginatedDataType<PostListType>>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  getPost(id: string) {
    return axios
      .get<PostType>(API_URL + `/api/v1/admin/posts/post/${id}/`)
      .then((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  createPost(data: PostType) {
    return axios
      .post<PostType>(API_URL + "/api/v1/admin/posts/post/", data)
      .then<PostType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  updatePost(id: string | number, data: PostType) {
    return axios
      .put<PostType>(API_URL + `/api/v1/admin/posts/post/${id}/`, data)
      .then<PostType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  deletePost(id: string | number) {
    return axios
      .delete<PostType>(API_URL + `/api/v1/admin/posts/post/${id}/`)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default PostService;
