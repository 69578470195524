import { errorConverter, FastapiErrorT } from "../../../utils/fastapi";
import { CompanyType } from "../../../types/company";
import React from "react";
import { GoogleMapType, ObjectDataType } from "../../../types/fund";
import { Form } from "../../../components/ui/Form";
import { InputText } from "../../../components/forms/InputText";
import { Wrapper } from "@googlemaps/react-wrapper";
import { MapComponent, renderMap } from "../../../components/ui/MapComponent";
import { AppendTable } from "../../../components/ui/AppendTable";
import { FormButtons } from "../../../components/ui/FormButtons";

interface CompanyEditFormType {
  errors?: FastapiErrorT;
  inputValues: CompanyType;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setInputValues: React.Dispatch<React.SetStateAction<CompanyType>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyEditForm: React.FC<CompanyEditFormType> = ({
  errors,
  handleChange,
  inputValues,
  setInputValues,
  handleSubmit,
  setEditMode,
}) => {
  const handleGoogleMapChange = (
    value: google.maps.LatLngLiteral | number | null,
    name: keyof GoogleMapType
  ) => {
    setInputValues((prev) => ({
      ...prev,
      google_map: { ...prev.google_map, [name]: value },
    }));
  };

  const handleChangeHistory = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const cpData = { ...inputValues };
    if (cpData.history) {
      cpData.history[index][name as keyof ObjectDataType] = value;
    }
    setInputValues(cpData);
  };

  const handleDelete = (i: number) => {
    const cpData = { ...inputValues };
    cpData.history?.splice(i, 1);
    setInputValues(cpData);
  };

  const handleAdd = () => {
    const cpData = { ...inputValues };
    if (!cpData.history) {
      cpData.history = [];
    }
    cpData.history.push({ label: "", value: "" });
    setInputValues(cpData);
  };
  return (
    <Form errors={errors} handleSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="flex flex-col gap-2">
          <InputText
            label="会社名"
            name="company_name"
            value={inputValues.company_name}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "company_name")}
          />
          <InputText
            label="サイトURL"
            name="company_url"
            value={inputValues.company_url}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "company_url")}
          />
          <InputText
            label="代表者"
            name="representative"
            value={inputValues.representative}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "representative")}
          />
          <InputText
            label="資本金"
            name="capital_stock"
            value={inputValues.capital_stock}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "capital_stock")}
          />
          <InputText
            label="設立"
            name="establishment"
            value={inputValues.establishment}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "establishment")}
          />
          <InputText
            label="郵便番号"
            name="postal_code"
            value={inputValues.postal_code}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "postal_code")}
          />
          <InputText
            label="住所"
            name="address"
            value={inputValues.address}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "address")}
          />
          <InputText
            label="所在地"
            type="textarea"
            name="licenses"
            value={inputValues.licenses}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "licenses")}
          />
          <InputText
            label="事業内容"
            type="textarea"
            name="business_activities"
            value={inputValues.business_activities}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "business_activities")}
          />
          <InputText
            label="電話番号"
            name="tel"
            value={inputValues.tel}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "tel")}
          />
          <InputText
            label="メール"
            name="email"
            value={inputValues.email}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "email")}
          />
        </div>
        <Wrapper
          apiKey={"AIzaSyD_twS9lHZSXoPvga-N1rHMO4qa_UkSN08"}
          render={renderMap}
        >
          <MapComponent
            address={inputValues.address || ""}
            center={
              inputValues.google_map?.center || {
                lat: -34,
                lng: 151,
              }
            }
            zoom={inputValues.google_map?.zoom || 10}
            handleGoogleMapChange={handleGoogleMapChange}
          />
        </Wrapper>
      </div>
      <div className="overflow-scroll">
        <AppendTable<ObjectDataType>
          data={inputValues.history || [{ label: "", value: "" }]}
          label="沿革"
          labelKey="label"
          valueKey="value"
          handleChange={handleChangeHistory}
          handleDelete={handleDelete}
          handleAdd={handleAdd}
          deleteStartIndex={0}
        />
      </div>
      <FormButtons
        handleCancel={() => {
          setEditMode(false);
        }}
      />
    </Form>
  );
};
