import ImageFloatNone from "./FloatNone";
import ImageFloatLeft from "./FloatLeft";
import ImageFloatRight from "./FloatRight";
import Absolute from "./Absolute";

export const imageFloatNoneMenuConf = {
  key: "imageFloatNone",
  factory() {
    return new ImageFloatNone();
  },
};

export const imageFloatLeftMenuConf = {
  key: "imageFloatLeft",
  factory() {
    return new ImageFloatLeft();
  },
};

export const imageFloatRightMenuConf = {
  key: "imageFloatRight",
  factory() {
    return new ImageFloatRight();
  },
};


export const imageAbsoluteMenuConf = {
  key: "imageAbsolute",
  factory() {
    return new Absolute();
  }
}