import MediaService from "../../../infrastructure/api/post/media";
import React, { ChangeEvent, useState } from "react";
import { Modal } from "../Modal";
import {
  ArrowPathIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Tab } from "@headlessui/react";
import { classNames } from "../../../utils";
import { DeleteConfirmation } from "../DeleteConfirmation";
import { EditModal } from "../EditModal";
import { InputText } from "../../forms/InputText";
import { CloudArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ImageType, NewImageType } from "../../../types/media";

type ImageContainerProps = {
  onSelect: (image: ImageType | ImageType[] | null) => void;
  service: typeof MediaService;
  button?: React.ReactElement | string;
  selected: number[];
  multiple?: boolean;
  buttonClassName?: string;
};

type EditImageType = {
  name: string;
};

export function ImageContainer(props: ImageContainerProps): React.ReactElement {
  const [images, setImages] = useState<ImageType[] | null>(null);
  const {
    onSelect,
    service,
    button,
    selected,
    multiple = false,
    buttonClassName,
  } = props;
  const [newImages, setNewImages] = useState<NewImageType[]>([]);
  const [editingImage, setEditingImage] = useState<EditImageType>({
    name: "",
  });
  const [selectedImages, setSelectedImages] = useState<number[]>(selected);
  const [show, setShow] = useState<{
    show: boolean;
    data?: ImageType | null;
    index?: number | null;
  }>({
    show: false,
  });
  const fetchImages = () => {
    if (!images) {
      getImages();
    }
  };

  const getImages = () => {
    new service()
      .getList()
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleFiles = (files: FileList) => {
    Array.from(files).forEach((image) => {
      let reader = new FileReader();
      // TODO: get author id
      reader.onloadend = function () {
        const lastIndex = image.name.lastIndexOf(".");
        setNewImages((prev) => [
          {
            name: lastIndex ? image.name.slice(0, lastIndex) : image.name,
            image: reader.result,
            author_id: 11,
          },
          ...prev,
        ]);
      };
      reader.readAsDataURL(image);
    });
  };

  const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeImage = (index: number) => {
    let cpNewImages = [...newImages];
    cpNewImages.splice(index, 1);
    setNewImages(cpNewImages);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      handleFiles(e.target.files);
    }
  };

  function handleChangeName<T extends EditImageType, D extends NewImageType>(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    state: T | D[],
    setState: React.Dispatch<React.SetStateAction<T | D[]>>
  ) {
    if (state) {
      const { value } = e.target;
      if (Array.isArray(state)) {
        const cpImages = [...state];
        cpImages[index].name = value;
        setState(cpImages);
      } else {
        setEditingImage({ name: value });
      }
    }
  }

  const handleUpload = () => {
    new service()
      .createImages(newImages)
      .then((data) => {
        setImages((prev) => {
          if (!prev) {
            return data;
          }
          return [...data, ...prev];
        });
        setNewImages([]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id: number, index: number) => {
    new service()
      .deleteImage(id)
      .then(() => {
        let cpImages = images ? [...images] : [];
        cpImages.splice(index, 1);
        setImages(cpImages);
        setSelectedImages([]);
        setShow({ show: false });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleSave = (index: number) => {
    const id = images ? images[index].id : null;
    if (id) {
      return new service()
        .updateImage(id, editingImage)
        .then((data) => {
          let cpImages = images ? [...images] : [];
          cpImages[index] = data;
          setImages(cpImages);
        })
        .catch((error) => {
          throw error;
        });
    }
    throw new Error("ID is null");
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.getAttribute("data-index") || "0");
    if (event.target.checked) {
      images && setSelectedImages([images[index].id]);
      setShow({
        show: true,
        data: images && images[index],
        index: index,
      });
    } else {
      setSelectedImages([]);
      setShow({ show: false });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.getAttribute("data-index") || "0");
    if (event.target.checked) {
      if (selectedImages?.length === 0) {
        setShow({
          show: true,
          data: images && images[index],
          index: index,
        });
      } else {
        setShow({
          show: false,
        });
      }
      images &&
        setSelectedImages((prevState) => [...prevState, images[index].id]);
    } else {
      const cpSelected = [...selectedImages];
      const i = cpSelected.findIndex((value) => {
        if (images) {
          return value === images[index].id;
        }
        return false;
      });
      cpSelected.splice(i, 1);
      if (cpSelected?.length === 1) {
        setShow({
          show: true,
          data:
            images && images.filter((image) => image.id === cpSelected[0])[0],
          index: index,
        });
      } else {
        setShow({
          show: false,
        });
      }
      setSelectedImages(cpSelected);
    }
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!multiple) {
      await onSelect(show.data || null);
    } else {
      let cpImages = images?.filter((image) =>
        selectedImages.includes(image.id)
      );
      await onSelect(cpImages || null);
    }
  };

  return (
    <>
      <Modal
        button={
          button ? (
            button
          ) : (
            <>
              <span>アイキャッチ画像</span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80"
                aria-hidden="true"
              />
            </>
          )
        }
        buttonClassName={buttonClassName}
        openCallback={() => {
          setSelectedImages(selected);
          fetchImages();
        }}
      >
        {(open) => {
          return (
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  画像
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  アップロード
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2 h-5/6 relative pb-2">
                <Tab.Panel
                  className={classNames(
                    "rounded-xl bg-white h-full overflow-y-scroll overflow-x-hidden",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                  )}
                >
                  <div className="bg-white w-full border-b-2 mb-2 p-1 px-4 flex justify-end">
                    <ArrowPathIcon
                      onClick={getImages}
                      className="text-blue-300 h-6 w-6 cursor-pointer"
                    />
                  </div>
                  <form
                    method="post"
                    onSubmit={async (event) => {
                      await handleSubmit(event);
                      open.close();
                    }}
                  >
                    <ul className="-m-1 flex flex-wrap md:-m-2 relative p-3">
                      {images?.map((image, index) => (
                        <li
                          key={image.id}
                          className="flex w-1/3 flex-wrap relative"
                          tabIndex={index}
                        >
                          <input
                            id={`id_image_${index}`}
                            type="checkbox"
                            className="peer hidden"
                            value={image.id}
                            name="image_container"
                            checked={selectedImages.includes(image.id)}
                            onChange={(event) => {
                              multiple
                                ? handleCheckboxChange(event)
                                : handleRadioChange(event);
                            }}
                            {...{ "data-index": index }}
                          />
                          <label
                            htmlFor={`id_image_${index}`}
                            className="w-full relative p-1 md:p-2 peer-checked:border-2 border-accent-main rounded"
                          >
                            <img
                              src={image.url}
                              className="block h-20 w-full rounded-lg object-cover object-center"
                              alt={image.name}
                            />
                          </label>
                          <ul className="mt-1 space-y-1 text-xs font-normal leading-4 text-gray-500 px-1 md:px-2 w-full">
                            <li>{image.readable_created_at}</li>
                            <li className="truncate">{image.name}</li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                    <div className="w-full sticky bottom-0 bg-blue-100 p-3 flex justify-between items-center">
                      <button
                        type="button"
                        className="bg-grey-boarder rounded p-1"
                        onClick={() => {
                          open.close();
                        }}
                      >
                        キャンセル
                      </button>
                      <div className="flex gap-2">
                        {show.show && (
                          <>
                            <DeleteConfirmation
                              onDelete={() => {
                                handleDelete(
                                  show.data?.id as number,
                                  show.index as number
                                );
                              }}
                            />
                            <EditModal
                              handleSave={() => {
                                return handleSave(show.index as number).catch(
                                  (error) => {
                                    throw error;
                                  }
                                );
                              }}
                              openCallback={() => {
                                setEditingImage({
                                  name: show.data?.name as string,
                                });
                              }}
                            >
                              <InputText
                                label="Name"
                                name="name"
                                value={editingImage.name}
                                handleChange={(e) =>
                                  handleChangeName<EditImageType, NewImageType>(
                                    e,
                                    show.index as number,
                                    editingImage,
                                    setEditingImage as React.Dispatch<
                                      React.SetStateAction<
                                        EditImageType | NewImageType[]
                                      >
                                    >
                                  )
                                }
                                error=""
                              />
                            </EditModal>
                          </>
                        )}
                        <button
                          type="submit"
                          className="bg-primary-dark rounded p-1"
                        >
                          選択
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab.Panel>
                <Tab.Panel
                  className={classNames(
                    "rounded-xl bg-white p-3 h-full overflow-scroll",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                  )}
                >
                  {newImages.length > 0 ? (
                    <div className="relative">
                      <div className="sticky top-0 w-full flex items-center justify-end gap-2 text-xs">
                        <label className="font-normal flex items-center cursor-pointer text-blue-600/50 hover:text-blue-300/50">
                          <PlusIcon className="w-3 h-3" />
                          Add More
                          <input
                            type="file"
                            onChange={handleChangeFile}
                            multiple
                            className="hidden"
                          />
                        </label>
                        <span className="text-gray-900/40">
                          Selected: {newImages.length}
                        </span>
                      </div>
                      <ul className="-m-1 flex flex-col md:-m-2 divide-y divide-dashed">
                        {newImages?.map((image, index) => (
                          <li key={index} className="flex w-full">
                            <div className="w-2/4 p-1 md:p-2">
                              <img
                                src={image.image as string}
                                className="block h-28 w-full rounded-lg object-cover object-center"
                                alt={image.name}
                              />
                            </div>
                            <ul className="w-2/4 p-2 flex flex-col justify-between">
                              <li>
                                <label className="block text-sm text-gray-400 font-semibold">
                                  Name:
                                </label>
                                <input
                                  type="text"
                                  className="border rounded p-1 w-full"
                                  value={image.name}
                                  onChange={(e) => {
                                    handleChangeName<
                                      EditImageType,
                                      NewImageType
                                    >(
                                      e,
                                      index,
                                      newImages,
                                      setNewImages as React.Dispatch<
                                        React.SetStateAction<
                                          EditImageType | NewImageType[]
                                        >
                                      >
                                    );
                                  }}
                                />
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => {
                                    removeImage(index);
                                  }}
                                  className="text-red-600 font-normal flex items-center gap-1 hover:text-red-300"
                                >
                                  <TrashIcon className="h-4 w-4" />
                                  Remove
                                </button>
                              </li>
                            </ul>
                          </li>
                        ))}
                      </ul>
                      <div className="sticky bottom-0 h-8 w-full flex justify-between gap-1 mt-2">
                        <button
                          type="button"
                          onClick={() => {
                            setNewImages([]);
                          }}
                          className="bg-gray-200 rounded-xl w-2/3 p-1"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleUpload}
                          className="bg-blue-500 text-white rounded-xl w-2/3 p-1"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <form
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      className="h-full w-full transition flex justify-center border-dashed border-blue-500 border-2 rounded-md hover:border-gray-400 focus:outline-none"
                    >
                      <span className="flex flex-col justify-center items-center">
                        <CloudArrowUpIcon className="h-20 w-20" />
                        <span className="text-sm font-semibold">
                          ドラッグ＆ドロップでアップロード
                        </span>
                        <label className="appearance-none cursor-pointer text-xs text-blue-600 underline">
                          またはブラウズ
                          <input
                            type="file"
                            onChange={handleChangeFile}
                            multiple
                            className="hidden"
                          />
                        </label>
                      </span>
                    </form>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          );
        }}
      </Modal>
    </>
  );
}
