import React, {ReactNode, useState} from "react";
import {tab} from "@testing-library/user-event/dist/tab";

interface TabProps {
  tabs: string[];
  children: ReactNode[] | ReactNode;
  onClick?: (index: number) => void;
}

export const TabLayout: React.FC<TabProps> = ({tabs, children, onClick}) => {
  const [activeTab, setActiveTab] = useState(0);
  if (!children || (Array.isArray(children) && children.length === 0)) {
    return null;
  }
  return (
    <div className="w-full">
      <div className="flex justify-between items-center overflow-x-scroll border-b-2 border-secondary-main w-full">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`py-3 px-5 text-lg font-bold flex justify-center items-center w-full ${
              index === activeTab
                ? 'text-secondary-main bg-secondary-light' : 'text-grey-text-main'}`}
            onClick={() => {
              setActiveTab(index);
              onClick && onClick(index);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="py-4 bg-white">{Array.isArray(children) ? children[activeTab] : children}</div>
    </div>
  );
};