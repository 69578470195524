import { Label } from "../ui/Label";

import React from "react";

type InputLayoutType = {
  label: string;
  name: string;
  error: string | string[];
  children: React.ReactNode;
  parentClassName?: string;
  labelClassName?: string;
};

export const InputLayout: React.FC<InputLayoutType> = ({
  label,
  name,
  children,
  error = "",
  parentClassName = "",
  labelClassName = "",
}) => {
  return (
    <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
      {label && (
        <Label
          htmlFor={`id_${name}`}
          className={labelClassName}
          label={label}
          error={error.length > 0}
        />
      )}
      {children}

      {Array.isArray(error) ? (
        error.map((text, index) => (
          <span className="text-sm text-red-500 block" key={index}>
            {text}
          </span>
        ))
      ) : (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </div>
  );
};
