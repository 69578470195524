import React, { useEffect, useRef, useId } from "react";
import EditorJS, {
  OutputData,
  ToolConstructable,
  ToolSettings,
} from "@editorjs/editorjs";

const Header = require("@editorjs/header");
const Paragraph = require("@editorjs/paragraph");
const Quote = require("@editorjs/quote");
const Warning = require("@editorjs/warning");
const Delimiter = require("@editorjs/delimiter");
const Alert = require("editorjs-alert");
const NestedList = require("@editorjs/nested-list");
const ImageTool = require("@editorjs/image");
const AttachesTool = require("@editorjs/attaches");
const Embed = require("@editorjs/embed");
const Table = require("editorjs-table");
const LinkButton = require("editorjs-button");
const InlineCode = require("@editorjs/inline-code");
const Underline = require("@editorjs/underline");
const Tooltip = require("editorjs-tooltip");
const Strikethrough = require("@sotaproject/strikethrough");
const ColorPlugin = require("editorjs-text-color-plugin");
const TextVariantTune = require("@editorjs/text-variant-tune");
const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");
const Undo = require("editorjs-undo");
const CodeTool = require("@editorjs/code");
const LinkTool = require("@editorjs/link");

const API_URL = process.env.REACT_APP_GATEWAY_URL;

interface EditorComponentProps {
  handleChange: (data: OutputData) => void;
  data?: OutputData;
  tools?: { [p: string]: ToolConstructable | ToolSettings };
}

const EditorComponent: React.FC<EditorComponentProps> = ({
  handleChange,
  data,
  tools = null,
}) => {
  const ejInstance = useRef<{ editor: EditorJS } | null>();
  const isReady = useRef(false);
  const id = useId();

  const initEditor = () => {
    const editor = new EditorJS({
      holder: id,
      onReady: () => {
        ejInstance.current = new Undo({ editor });
      },
      autofocus: false,
      data: data,
      onChange: async () => {
        let content = await editor.saver.save();
        handleChange(content);
      },
      tools: tools
        ? tools
        : {
          header: {
            class: Header,
            inlineToolbar: true,
            tunes: ["anyTuneName", "textVariant"],
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ["anyTuneName", "textVariant"],
          },
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: API_URL + "/api/v1/admin/posts/editor/image/file/", // Your backend file uploader endpoint
                byUrl: API_URL + "/api/v1/admin/posts/editor/image/url/", // Your endpoint that provides uploading by Url
              },
            },
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          list: NestedList,
          quote: {
            class: Quote,
            inlineToolbar: true,
          },
          attaches: {
            class: AttachesTool,
            config: {
              endpoint: API_URL + "/api/v1/admin/posts/editor/file/",
            },
          },
          embed: {
            class: Embed,
            inlineToolbar: true,
          },
          linkTool: {
            class: LinkTool,
            config: {
              endpoint: API_URL + "/api/v1/admin/posts/editor/preview-link/",
            },
          },
          code: CodeTool,
          textVariant: TextVariantTune,
          AnyButton: {
            class: LinkButton,
            inlineToolbar: false,
            config: {
              css: {
                btnColor: "btn--gray",
              },
            },
          },
          warning: Warning,
          delimiter: Delimiter,
          alert: Alert,
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+M",
          },
          underline: Underline,
          tooltip: {
            class: Tooltip,
            config: {
              location: "left",
              highlightColor: "#FFEFD5",
              underline: true,
              backgroundColor: "#154360",
              textColor: "#FDFEFE",
              holder: "editorId",
            },
          },
          strikethrough: Strikethrough,
          Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              colorCollections: [
                "#EC7878",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#0070FF",
                "#03A9F4",
                "#00BCD4",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFF",
              ],
              defaultColor: "#FF1300",
              type: "text",
              customPicker: true, // add a button to allow selecting any colour
            },
          },
          Marker: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              defaultColor: "#FFBF00",
              type: "marker",
              icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
            },
          },
          anyTuneName: AlignmentTuneTool,
        },
      tunes: ["textVariant"],
      i18n: {
        messages: {
          tools: {
            AnyButton: {
              "Button Text": "ボタンに表示するテキスト",
              "Link Url": "ボタンの飛び先のURL",
              Set: "設定する",
              "Default Button": "デフォルト",
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (!isReady.current) {
      isReady.current = true;
      initEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id={id}></div>
    </>
  );
};

export default EditorComponent;
