import React, { ChangeEvent, useEffect, useRef } from "react";
import { classNames } from "../../utils";
import { Label } from "../ui/Label";

type BaseSelectProps<T extends object> = {
  label: string;
  name: string;
  error: string | string[];
  required?: boolean;
  options: T[];
  valKey: keyof T;
  labelKey: keyof T;
  className?: string;
  parentClassName?: string;
  labelClassName?: string;
};

type SelectProps<
  T extends object,
  K extends string | number | readonly string[] | undefined
> = {
  value: K;
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  attr?: object;
} & BaseSelectProps<T>;

export const Select = <
  T extends object,
  K extends string | number | readonly string[] | undefined
>({
  label,
  name,
  value,
  handleChange,
  error,
  options,
  valKey,
  labelKey,
  required = true,
  className = "",
  parentClassName = "",
  labelClassName = "",
  attr = {},
}: SelectProps<T, K>): React.ReactElement => (
  <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
    <Label
      htmlFor={`id_${name}`}
      className={labelClassName}
      label={label}
      error={error.length > 0}
    />
    <select
      className={classNames(
        className,
        "border p-1.5 rounded text-grey-text-main",
        error.length > 0 ? "border-red-500" : "border-grey-boarder"
      )}
      name={name}
      id={`id_${name}`}
      value={value}
      onChange={handleChange}
      required={required}
      {...attr}
    >
      <option value=""></option>
      {options.map((val, index) => (
        <option key={index} value={val[valKey] as string | number}>
          {val[labelKey] as string | number}
        </option>
      ))}
    </select>
    {Array.isArray(error) ? (
      error.map((text, index) => (
        <span className="text-sm text-red-500 block" key={index}>
          {text}
        </span>
      ))
    ) : (
      <span className="text-sm text-red-500">{error}</span>
    )}
  </div>
);

type CustomSelectProps<
  T extends object,
  K extends string | number | readonly string[] | undefined
> = {
  value: K;
  handleChange: (value: K) => void;
  actions?: (obj: T, index: number) => React.ReactElement;
  labelActions?: React.ReactElement;
} & BaseSelectProps<T>;
export const SelectWithActions: <
  T extends object,
  K extends string | number | readonly string[] | undefined
>(
  p: CustomSelectProps<T, K>
) => React.ReactElement<CustomSelectProps<T, K>> = ({
  label,
  name,
  value,
  handleChange,
  error,
  options,
  valKey,
  labelKey,
  actions,
  labelActions,
  className = "",
  parentClassName = "",
  labelClassName = "",
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const selectLabel = options.find((v) => v[valKey] === value);

  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (
        selectRef.current &&
        !selectRef.current
          .getElementsByTagName("ul")[0]
          ?.classList.contains("hidden") &&
        !selectRef.current.contains(target as Node)
      ) {
        hideMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const hideMenu = () => {
    const menu = selectRef.current?.getElementsByTagName("ul")[0];
    if (menu) {
      if (menu.classList.contains("flex")) {
        menu.classList.add("hidden");
        menu.classList.remove("flex");
      } else {
        menu.classList.add("flex");
        menu.classList.remove("hidden");
      }
    }
  };
  const handleOptionClick = (option: typeof value) => {
    hideMenu();
    handleChange(option);
  };
  return (
    <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
      <div className="flex justify-between">
        {label && (
          <Label
            htmlFor={`id_${name}`}
            className={labelClassName}
            label={label}
            error={error.length > 0}
          />
        )}
        {labelActions}
      </div>
      <div id={`id_${name}`} ref={selectRef} tabIndex={0}>
        <div
          onClick={hideMenu}
          className={classNames(
            className,
            "border cursor-default p-1 rounded text-grey-text-main",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
        >
          {selectLabel ? (
            (selectLabel[labelKey] as string | number)
          ) : (
            <span className="text-grey-text-light">カテゴリーを選択</span>
          )}
        </div>
        <ul className="cursor-default border hidden border-grey-boarder flex-col max-h-56 overflow-y-scroll">
          {options.map((val, index) => (
            <li
              className="hover:bg-accent-main hover:text-white px-1 flex justify-between items-center"
              key={index}
            >
              <span
                className="w-full"
                onClick={() => handleOptionClick(val[valKey] as typeof value)}
              >
                {val[labelKey] as string | number}
              </span>
              {actions && actions(val, index)}
            </li>
          ))}
        </ul>
        {Array.isArray(error) ? (
          error.map((text, index) => (
            <span className="text-sm text-red-500 block" key={index}>
              {text}
            </span>
          ))
        ) : (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

type CustomSelectMultipleProps<
  T extends object,
  K extends string | number | readonly string[] | undefined
> = BaseSelectProps<T> & {
  value: K[];
  handleChange: (value: K[]) => void;
  actions?: (obj: T, index: number) => React.ReactElement;
  labelActions?: React.ReactElement;
};

export const SelectMultipleWithActions: <
  T extends object,
  K extends string | number | readonly string[] | undefined
>(
  p: CustomSelectMultipleProps<T, K>
) => React.ReactElement<CustomSelectMultipleProps<T, K>> = ({
  label,
  name,
  value,
  handleChange,
  error,
  options,
  valKey,
  labelKey,
  actions,
  labelActions,
  className = "",
  parentClassName = "",
  labelClassName = "",
}) => {
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (
        selectRef.current &&
        !selectRef.current
          .getElementsByTagName("ul")[0]
          ?.classList.contains("hidden") &&
        !selectRef.current.contains(target as Node)
      ) {
        hideMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const hideMenu = () => {
    const menu = selectRef.current?.getElementsByTagName("ul")[0];
    if (menu) {
      if (menu.classList.contains("flex")) {
        menu.classList.add("hidden");
        menu.classList.remove("flex");
      } else {
        menu.classList.add("flex");
        menu.classList.remove("hidden");
      }
    }
  };
  const handleSelect = () => {
    const nodeList = selectRef.current?.querySelectorAll<HTMLInputElement>(
      `input[id^='multi_']:checked`
    );
    const checkedList = [];
    if (nodeList) {
      for (let i = 0; i < nodeList.length; i++) {
        checkedList.push(Number(nodeList.item(i).value));
      }
      handleChange(checkedList as typeof value);
    }
  };
  const isChecked = (id: string) => {
    const el = selectRef.current?.querySelector<HTMLInputElement>(
      `input[id=${id}]:checked`
    );
    return el && el.checked;
  };
  return (
    <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
      <div className="flex justify-between">
        {label && (
          <Label
            htmlFor={`id_${name}`}
            className={labelClassName}
            label={label}
            error={error.length > 0}
          />
        )}
        {labelActions}
      </div>
      <div id={`id_${name}`} ref={selectRef} tabIndex={1}>
        <div
          onClick={() => {
            selectRef.current
              ?.getElementsByTagName("ul")[0]
              ?.classList.contains("hidden") && hideMenu();
          }}
          className={classNames(
            className,
            "border cursor-default p-1 rounded text-grey-text-main h-auto max-h-20 overflow-y-scroll",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
        >
          {value.length === 0 && (
            <span className="text-grey-text-light">タグを選択</span>
          )}

          {options.map((v) => (
            <div key={v[valKey] as string | number}>
              <input
                type="checkbox"
                className={`hidden peer multiCheckbox`}
                id={`multi_${name}_${v[valKey] as string | number}`}
                value={v[valKey] as string}
                name={`${valKey as string}`}
                {...(value.includes(v[valKey] as (typeof value)[0])
                  ? { checked: true }
                  : { checked: false })}
                onChange={handleSelect}
              />
              <p>{v[labelKey] as string | number}</p>
            </div>
          ))}
        </div>
        <ul className="cursor-default border hidden border-grey-boarder flex-col max-h-56 overflow-y-scroll">
          {options.map((val, index) => (
            <li
              className={`hover:bg-accent-main hover:text-white px-1 flex justify-between items-center ${
                isChecked(`multi_${name}_${val[valKey]}`)
                  ? "bg-accent-light"
                  : ""
              }`}
              key={index}
            >
              <label
                className="w-full"
                htmlFor={`multi_${name}_${val[valKey] as string | number}`}
                aria-hidden="true"
              >
                {val[labelKey] as string | number}
              </label>
              {actions && actions(val, index)}
            </li>
          ))}
        </ul>
        {Array.isArray(error) ? (
          error.map((text, index) => (
            <span className="text-sm text-red-500 block" key={index}>
              {text}
            </span>
          ))
        ) : (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};
