import React from "react";
import { InputText } from "../../../../components/forms/InputText";
import { errorConverter } from "../../../../utils/fastapi";
import { FundStepProps } from "../../../../types/fund";

export const Distribution: React.FC<FundStepProps> = ({
  inputValues,
  handleChange,
  errors,
}) => (
  <div className="m-auto flex flex-col divide-y">
    {inputValues.distributions &&
      inputValues.distributions
        .filter((obj) => obj.order !== 404)
        .map((distribution, index) => {
          return (
            <div
              key={index}
              className="flex flex-col sm:flex-row gap-2 items-end py-2"
            >
              <span className="text-xl">
                {index + 1}
                <small className="text-xs">回目</small>
              </span>
              <InputText
                label="計算開始日"
                name="start"
                type="date"
                value={distribution.start}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
              <InputText
                label="計算終了日"
                name="end"
                type="date"
                value={distribution.end}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
              <InputText
                label="分配金振込日"
                name="receive_date"
                type="date"
                value={distribution.receive_date}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
            </div>
          );
        })}
  </div>
);
