import React from "react";
import {InputText} from "../../../../components/forms/InputText";
import {errorConverter} from "../../../../utils/fastapi";
import {Select} from "../../../../components/forms/Select";
import {
  durationTypeOptions,
  statusOptions,
} from "../../data";
import {FundStepProps, FundType, getLabelForTransactionType} from "../../../../types/fund";
import {Label} from "../../../../components/ui/Label";
import Editor from "../../../../components/ui/Editor";


export const EditSummary: React.FC<
  FundStepProps & {
  setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
}
> = ({inputValues, handleChange, errors, setInputValues}) => (
  <div className="grid grid-flow-row sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-3 gap-y-5">
    <InputText
      label="URLスラッグ"
      name="slug"
      value={inputValues.slug}
      handleChange={handleChange}
      error={errorConverter(errors?.detail, "slug")}
    />
    <Select<SelectOptionType, string>
      label="ファンドステータス"
      name="status"
      value={inputValues.status || ""}
      handleChange={handleChange}
      valKey="value"
      options={statusOptions}
      labelKey="label"
      error={errorConverter(errors?.detail, "status")}
    />

    <div className="flex flex-col gap-1">
      <Label label="ファンドタイプ" />
      <div>{getLabelForTransactionType(inputValues.transaction_type)}</div>
    </div>

    <div className="col-span-1 sm:col-span-2 md:col-span-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 my-4">

      <div className="col-span-1 sm:col-span-2 flex flex-wrap gap-2">
        <InputText
          label="期間"
          name="duration"
          value={inputValues.duration || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "duration")}
        />
        <Select
          label="期間の種類"
          name="duration_type"
          value={inputValues.duration_type || ""}
          handleChange={handleChange}
          valKey="value"
          labelKey="label"
          error={errorConverter(errors?.detail, "duration_type")}
          options={durationTypeOptions}
        />
      </div>
      <div className="col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
        <Label
          label="ファンド概要"
          error={errorConverter(errors?.detail, "fund_overview").length > 0}
        />
        <div
          className={`border ${
            errorConverter(errors?.detail, "fund_overview").length > 0
              ? "border-red-500"
              : ""
          }`}
        >
          <Editor
            data={inputValues.fund_overview || undefined}
            handleChange={(data) => {
              setInputValues((prevState) => ({
                ...prevState,
                fund_overview: data,
              }));
            }}
          />
        </div>
        {Array.isArray(errorConverter(errors?.detail, "fund_overview")) ? (
          errorConverter(errors?.detail, "fund_overview").map((text, index) => (
            <span className="text-sm text-red-500 block" key={index}>
              {text}
            </span>
          ))
        ) : (
          <span className="text-sm text-red-500">
            {errorConverter(errors?.detail, "fund_overview")}
          </span>
        )}
      </div>
    </div>
  </div>
);
