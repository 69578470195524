import axios from "axios";
import { OperatorType } from "../../../types/fund";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class OperatorService {
  getList(page: number, limit: number) {
    return axios
      .get<PaginatedDataType<OperatorType>>(
        API_URL + "/api/v1/admin/funds/operator/",
        {
          params: {
            page: page,
            limit: limit,
          },
        }
      )
      .then<PaginatedDataType<OperatorType>>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  getOperator(id: string) {
    return axios
      .get<OperatorType>(API_URL + `/api/v1/admin/funds/operator/${id}/`)
      .then<OperatorType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  createOperator(data: OperatorType) {
    return axios
      .post<OperatorType>(API_URL + "/api/v1/admin/funds/operator/", data)
      .then<OperatorType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  deleteOperator(id: number) {
    return axios
      .delete(API_URL + `/api/v1/admin/funds/operator/${id}/`)
      .catch((error) => {
        throw error.request.response;
      });
  }
  updateOperator(id: string, data: OperatorType) {
    return axios
      .put<OperatorType>(API_URL + `/api/v1/admin/funds/operator/${id}/`, data)
      .then<OperatorType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default OperatorService;
