import React from "react";
import { ButtonType } from "./types";

export const DeleteButton: React.FC<ButtonType> = ({ onClick, value }) => (
  <button
    type="button"
    onClick={onClick}
    className="flex-1 p-1 rounded cursor-pointer bg-red-400 hover:bg-red-200 text-white"
  >
    {value ? value : "Delete"}
  </button>
);
