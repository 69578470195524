import { GoogleMapType } from "../../types/fund";
import React, { ReactElement, useEffect, useRef } from "react";
import { Status } from "@googlemaps/react-wrapper";

export function MapComponent({
  center,
  zoom,
  address,
  handleGoogleMapChange,
  className,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  address: string;
  handleGoogleMapChange: (
    value: google.maps.LatLngLiteral | number | null,
    name: keyof GoogleMapType
  ) => void;
  className?: string;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const geocoder = useRef<google.maps.Geocoder | null>(null);
  const map = useRef<google.maps.Map | null>(null);
  const marker = useRef<google.maps.Marker | null>(null);
  const clickEvent = useRef<google.maps.MapsEventListener | null>(null);
  const zoomEvent = useRef<google.maps.MapsEventListener | null>(null);

  useEffect(() => {
    if (ref.current) {
      geocoder.current = new window.google.maps.Geocoder();
      map.current = new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
    }
    if (map.current) {
      clickEvent.current = map.current.addListener("click", onClickFunction);
      zoomEvent.current = map.current?.addListener(
        "zoom_changed",
        onZoomFunction
      );
    }
    return () => {
      clickEvent.current?.remove();
      zoomEvent.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFunction = (event: google.maps.MapMouseEvent) => {
    addMarker(event.latLng);
    handleGoogleMapChange(event.latLng?.toJSON() || null, "center");
  };

  const onZoomFunction = () => {
    handleGoogleMapChange(map.current?.getZoom() || null, "zoom");
  };

  const addMarker = (latLng: google.maps.LatLng | null) => {
    if (latLng) {
      map.current?.setCenter(latLng);
    }
    if (marker.current) {
      marker.current?.setMap(null);
    }
    marker.current = new window.google.maps.Marker({
      map: map.current,
      position: latLng,
    });
  };

  useEffect(() => {
    if (address) {
      geocoder.current?.geocode(
        { address: address },
        function (results, status) {
          if (status === "OK") {
            if (results) {
              addMarker(results[0].geometry.location);
              handleGoogleMapChange(
                results[0].geometry.location.toJSON(),
                "center"
              );
            }
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  return (
    <div ref={ref} id="map" className={className ? className : "h-80 w-full"} />
  );
}

export const renderMap = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <>Error</>;
  return <>Loading</>;
};
