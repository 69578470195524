import React, { useEffect, useState } from "react";
import { Modal } from "../../../../components/ui/Modal";
import { FundStepProps, FundType, PromotionType } from "../../../../types/fund";
import PromotionService from "../../../../infrastructure/api/funds/promotion";

interface SixStepProps extends FundStepProps {
  setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
}

export const Promotions: React.FC<SixStepProps> = (props) => {
  const { inputValues, setInputValues } = props;
  const [promotions, setPromotions] = useState<PromotionType[]>([]);

  useEffect(() => {
    new PromotionService().getList(0, 100).then((data) => {
      setPromotions(data.data);
    });
  }, []);

  const handleAdd = (data: PromotionType) => {
    const cpData = { ...inputValues };
    if (cpData.promotions) {
      cpData.promotions.push(data);
    } else {
      cpData.promotions = [data];
    }
    setInputValues(cpData);
  };

  const handleRemove = (data: PromotionType) => {
    const cpData = { ...inputValues };
    if (cpData.promotions) {
      const index = cpData.promotions.findIndex(
        (value) => value.id === data.id
      );
      if (index !== -1) {
        cpData.promotions.splice(index, 1);
        setInputValues(cpData);
      }
    }
  };
  return (
    <div>
      <Modal button="キャンペーンを選択">
        {(open) => (
          <div className="h-full flex justify-between flex-col overflow-scroll">
            <ul>
              {promotions.map((val) => (
                <li key={val.id}>
                  <div className="bg-white p-2 flex flex-col">
                    <span className="text-xl">{val.title}</span>
                    <small className="text-xs">
                      {val.start} ~ {val.end}
                    </small>
                  </div>
                  <div className="flex gap-2">
                    {(!inputValues.promotions ||
                      inputValues.promotions.findIndex(
                        (obj) => obj.id === val.id
                      ) === -1) && (
                      <button
                        type="button"
                        className="text-blue-500"
                        onClick={() => {
                          handleAdd(val);
                        }}
                      >
                        選択
                      </button>
                    )}
                    {inputValues.promotions &&
                      inputValues.promotions.findIndex(
                        (obj) => obj.id === val.id
                      ) !== -1 && (
                        <button
                          type="button"
                          className="text-red-500"
                          onClick={() => {
                            handleRemove(val);
                          }}
                        >
                          取り除く
                        </button>
                      )}
                  </div>
                </li>
              ))}
            </ul>
            <div className="sticky bottom-0 w-full">
              <button
                onClick={() => {
                  open.close();
                }}
                className="flex-1 rounded bg-grey-boarder p-2 text-center w-full"
                type="button"
              >
                戻る
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div>
        {inputValues.promotions?.map((val) => (
          <div key={val.id}>
            <div className="bg-white p-2 flex flex-col">
              <span className="text-xl">{val.title}</span>
              <small className="text-xs">
                {val.start} ~ {val.end}
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
