import React, { useState } from "react";
import { InputText } from "../../../../components/forms/InputText";
import { errorConverter } from "../../../../utils/fastapi";
import {
  FundStepProps,
  FundType,
  GoogleMapType,
  ObjectDataType,
  ObjectInformationType,
  ObjectImageType,
} from "../../../../types/fund";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import ExternalService from "../../../../infrastructure/api/external/external";
import { Wrapper } from "@googlemaps/react-wrapper";
import Editor from "../../../../components/ui/Editor";
import { Label } from "../../../../components/ui/Label";
import { FundImageContainer } from "../../components/FundImageContainer";
import { AppendTable } from "../../../../components/ui/AppendTable";
import AccordionLayout from "../../../../components/layouts/AccordionLayout";
import {
  MapComponent,
  renderMap,
} from "../../../../components/ui/MapComponent";

interface FourthStepProps extends FundStepProps {
  setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
}

export const ObjectsInformation: React.FC<FourthStepProps> = ({
  inputValues,
  handleChange,
  errors,
  setInputValues,
}) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const initialObjectInformation: ObjectInformationType = {
    object_name: "",
    order: 0,
    object_data: [
      { label: "物件名称", value: "" },
      { label: "物件種別", value: "" },
      { label: "所在地", value: "" },
    ],
    object_images: [],
    neighborhood_images: [],
    neighborhood_information: null,
    postal_code: "",
    address: "",
    fund_id: inputValues.id || null,
    google_map: { center: null, zoom: null },
  };

  const handleAdd = () => {
    setInputValues((prevState) => ({
      ...prevState,
      objects_of_information: [
        ...(prevState.objects_of_information || []),
        {
          ...initialObjectInformation,
          order: prevState.objects_of_information?.length || 0,
        },
      ],
    }));
  };

  const handleRemove = (index: number) => {
    const cpData = { ...inputValues };
    if (
      cpData.objects_of_information &&
      cpData.objects_of_information[index].id
    ) {
      cpData.objects_of_information[index].order = 404;
    } else {
      cpData.objects_of_information?.splice(index, 1);
    }
    setInputValues(cpData);
    const cpActive = [...activeIndex];
    const i = cpActive.findIndex((val) => val === index);
    cpActive.splice(i, 1);
    setActiveIndex(cpActive);
  };

  const handleChangeName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let cpData = inputValues;
    if (cpData.objects_of_information && cpData.objects_of_information[index]) {
      cpData.objects_of_information[index].object_data[0].value =
        event.target.value;
      setInputValues(cpData);
    }
    handleChange(event);
  };

  const handleChangeAddress = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let cpData = inputValues;
    if (cpData.objects_of_information && cpData.objects_of_information[index]) {
      cpData.objects_of_information[index].object_data[2].value =
        event.target.value;
      setInputValues(cpData);
    }
    handleChange(event);
  };

  const handlePostChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let cpData = inputValues;
    new ExternalService()
      .getAddress(event.target.value)
      .then((data) => {
        if (cpData.objects_of_information) {
          if (data.results) {
            cpData.objects_of_information[index].address =
              data.results[0].address1 +
              data.results[0].address2 +
              data.results[0].address3;
            setInputValues(cpData);
          }
        }
      })
      .catch(() => {
        if (cpData.objects_of_information) {
          cpData.objects_of_information[index].address = "";
          setInputValues(cpData);
        }
      })
      .finally(() => {
        if (
          cpData.objects_of_information &&
          cpData.objects_of_information[index]
        ) {
          cpData.objects_of_information[index].object_data[2].value =
            cpData.objects_of_information[index].address || "";
          setInputValues(cpData);
        }
      });
    handleChange(event);
  };

  const handleObjectDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    buildI: number,
    dataI: number
  ) => {
    const { name, value } = event.target;
    let cpData = { ...inputValues };
    if (cpData.objects_of_information) {
      cpData.objects_of_information[buildI].object_data[dataI][
        name as keyof ObjectDataType
      ] = value;
      setInputValues(cpData);
    }
  };

  const handleDeleteData = (buildI: number, dataI: number) => {
    let cpData = { ...inputValues };
    if (cpData.objects_of_information) {
      cpData.objects_of_information[buildI].object_data.splice(dataI, 1);
      setInputValues(cpData);
    }
  };
  const handleAddData = (buildI: number) => {
    let cpData = { ...inputValues };
    if (cpData.objects_of_information) {
      cpData.objects_of_information[buildI].object_data.push({
        label: "",
        value: "",
      });
      setInputValues(cpData);
    }
  };

  const handleGoogleMapChange = (
    value: google.maps.LatLngLiteral | number | null,
    name: keyof GoogleMapType,
    index: number
  ) => {
    const cpData = { ...inputValues };
    if (cpData.objects_of_information && cpData.objects_of_information[index]) {
      cpData.objects_of_information[index].google_map[name] = value as
        | (google.maps.LatLngLiteral & number)
        | null;
      setInputValues(cpData);
    }
  };
  const handleSetImage = (
    data: ObjectImageType[],
    index: number,
    key: "neighborhood_images" | "object_images"
  ) => {
    const cpData = { ...inputValues };
    if (cpData.objects_of_information) {
      cpData.objects_of_information[index][key] = data;
      setInputValues(cpData);
    }
  };
  return (
    <div>
      <div className="m-auto flex flex-col divide-y-2 divide-accent-main gap-8">
        {inputValues.objects_of_information &&
          inputValues.objects_of_information
            .sort((a, b) => a.order - b.order)
            .map((building, index) => {
              if (building.order === 404)
                return <React.Fragment key={index}></React.Fragment>;
              return (
                <AccordionLayout<number[]>
                  title={`${index + 1}. ` + (building.object_name || "")}
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  key={index}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 py-8">
                    <div className="col-span-1 sm:col-span-2 w-full flex justify-end">
                      <button
                        type="button"
                        className="text-red-500 font-bold"
                        onClick={() => {
                          handleRemove(index);
                        }}
                      >
                        取り除く
                      </button>
                    </div>
                    <div>
                      <FundImageContainer<ObjectImageType>
                        button="物件の外観または室内写真"
                        buttonClassName="bg-primary-main border p-2 rounded"
                        images={building.object_images || []}
                        deleteCallback={(data) => {
                          handleSetImage(data, index, "object_images");
                        }}
                        dropCallback={(data) => {
                          handleSetImage(data, index, "object_images");
                        }}
                        selectCallback={(data) => {
                          handleSetImage(data, index, "object_images");
                        }}
                        relKey="object_information_id"
                        relValue={building.id}
                      />
                    </div>
                    <div>
                      <FundImageContainer<ObjectImageType>
                        button="周辺写真"
                        buttonClassName="bg-primary-main border p-2 rounded"
                        images={building.neighborhood_images || []}
                        deleteCallback={(data) => {
                          handleSetImage(data, index, "neighborhood_images");
                        }}
                        dropCallback={(data) => {
                          handleSetImage(data, index, "neighborhood_images");
                        }}
                        selectCallback={(data) => {
                          handleSetImage(data, index, "neighborhood_images");
                        }}
                        relKey="object_information_id"
                        relValue={building.id}
                      />
                    </div>
                    <div>
                      <InputText
                        label="物件名称"
                        name="object_name"
                        value={building.object_name || ""}
                        handleChange={(event) => {
                          handleChangeName(event, index);
                        }}
                        error={errorConverter(
                          errors?.detail,
                          "object_name",
                          index
                        )}
                        attr={{
                          "data-list": "objects_of_information",
                          "data-index": index,
                        }}
                      />
                      <InputText
                        label="郵便番号"
                        name="postal_code"
                        value={building.postal_code || ""}
                        handleChange={(e) => {
                          handlePostChange(e, index);
                        }}
                        error={errorConverter(
                          errors?.detail,
                          "postal_code",
                          index
                        )}
                        attr={{
                          "data-list": "objects_of_information",
                          "data-index": index,
                        }}
                      />
                      <InputText
                        label="住所"
                        name="address"
                        value={building.address || ""}
                        handleChange={(event) => {
                          handleChangeAddress(event, index);
                        }}
                        error={errorConverter(errors?.detail, "address", index)}
                        attr={{
                          "data-list": "objects_of_information",
                          "data-index": index,
                        }}
                      />
                      <div className="my-2">
                        <Wrapper
                          apiKey={"AIzaSyD_twS9lHZSXoPvga-N1rHMO4qa_UkSN08"}
                          render={renderMap}
                        >
                          <MapComponent
                            address={building.address || ""}
                            center={
                              building.google_map?.center || {
                                lat: -34,
                                lng: 151,
                              }
                            }
                            zoom={building.google_map?.zoom || 10}
                            handleGoogleMapChange={(value, name) => {
                              handleGoogleMapChange(value, name, index);
                            }}
                          />
                        </Wrapper>
                      </div>
                    </div>
                    <div className="overflow-scroll">
                      <AppendTable<ObjectDataType>
                        data={building.object_data}
                        label="募集概要"
                        labelKey="label"
                        valueKey="value"
                        handleChange={(event, i) => {
                          handleObjectDataChange(event, index, i);
                        }}
                        handleDelete={(i) => {
                          handleDeleteData(index, i);
                        }}
                        handleAdd={() => {
                          handleAddData(index);
                        }}
                        deleteStartIndex={2}
                      />
                    </div>
                    <div className="col-span-1 sm:col-span-2">
                      <Label
                        label="周辺情報"
                        error={
                          errorConverter(
                            errors?.detail,
                            "neighborhood_information",
                            index
                          ).length > 0
                        }
                      />
                      <div
                        className={`border ${
                          errorConverter(
                            errors?.detail,
                            "neighborhood_information",
                            index
                          ).length > 0
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <Editor
                          data={building.neighborhood_information || undefined}
                          handleChange={(data) => {
                            const cpData = { ...inputValues };
                            if (cpData.objects_of_information) {
                              cpData.objects_of_information[
                                index
                              ].neighborhood_information = data;
                              setInputValues(cpData);
                            }
                          }}
                        />
                      </div>
                      {Array.isArray(
                        errorConverter(
                          errors?.detail,
                          "neighborhood_information",
                          index
                        )
                      ) ? (
                        errorConverter(
                          errors?.detail,
                          "neighborhood_information",
                          index
                        ).map((text, index) => (
                          <span
                            className="text-sm text-red-500 block"
                            key={index}
                          >
                            {text}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-red-500">
                          {errorConverter(
                            errors?.detail,
                            "neighborhood_information",
                            index
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </AccordionLayout>
              );
            })}
      </div>
      <div className="w-full my-5 flex justify-center">
        <PlusCircleIcon
          onClick={handleAdd}
          className="h-12 w-12 text-secondary-main cursor-pointer"
        />
      </div>
    </div>
  );
};
