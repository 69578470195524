import React from "react";
import { FundType, StepsObjType } from "../../../../types/fund";

interface CompletedButtonProps {
  next: () => void;
  back?: () => void;
  id: keyof StepsObjType;
  setState: React.Dispatch<React.SetStateAction<FundType>>;
}

export const CompletedButton: React.FC<CompletedButtonProps> = ({
  next,
  back,
  id,
  setState,
}) => {
  return (
    <div
      className="flex w-full"
      style={
        back ? { justifyContent: "space-between" } : { justifyContent: "end" }
      }
    >
      {back && (
        <button
          type="button"
          className="p-2 bg-grey-bg rounded border"
          onClick={back}
        >
          戻る
        </button>
      )}
      <div className="flex">
        <button
          type="button"
          className="p-2 text-primary-dark rounded"
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              steps: { ...prevState.steps, [id]: false },
            }));
          }}
        >
          編集
        </button>
        <button
          type="button"
          className="p-2 text-white bg-primary-dark rounded"
          onClick={next}
        >
          次へ
        </button>
      </div>
    </div>
  );
};
