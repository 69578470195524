import { AxiosInstance } from "axios";
import {
  UserCreateType,
  UserDetailType,
  UserEditType,
  UserPasswordType,
} from "../../../types/companyUser";

class CompanyUserService {
  private axios;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  getList() {
    return this.axios
      .get<UserDetailType[]>("/api/v1/admin/company/user/")
      .then<UserDetailType[]>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
  create(data: UserCreateType) {
    return this.axios
      .post<UserDetailType>("/api/v1/admin/company/user/", data)
      .then<UserDetailType>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  get(pk: string) {
    return this.axios
      .get<UserEditType>(`/api/v1/admin/company/user/${pk}/`)
      .then<UserEditType>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
  update(pk: string, data: UserEditType) {
    return this.axios
      .post<UserDetailType>(`/api/v1/admin/company/user/${pk}/`, data)
      .then<UserDetailType>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
  delete(pk: string) {
    return this.axios
      .delete(`/api/v1/admin/company/user/${pk}/`)
      .then(() => "success")
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  updatePassword(pk: string, data: UserPasswordType) {
    return this.axios
      .put(`/api/v1/admin/company/user/${pk}/password/`, data)
      .then(() => "success")
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
}

export default CompanyUserService;
