import React from "react";
import { useStepper } from "../../../hooks/useStepper";
import { StepperHeader } from "./StepperHeader";
import { StepperStep, StepperSteps } from "./StepperSteps";
import { defaultStepperContextState } from "../../../store/stepper";

export const StepperContext = React.createContext(defaultStepperContextState);

interface StepperType {
  children: React.ReactNode;
  completed: (string | number)[];
  top?: string | number;
  startFrom?: number;
}

interface StepperComponent extends React.FC<StepperType> {
  Step: typeof StepperStep;
  Steps: typeof StepperSteps;
}

const Stepper: StepperComponent = ({
  children,
  completed,
  top,
  startFrom = 0,
}) => {
  const {
    currentStep,
    steps,
    setSteps,
    incrementCurrentStep,
    decrementCurrentStep,
    setCurrentStep,
  } = useStepper(startFrom);
  return (
    <StepperContext.Provider
      value={{
        currentStep,
        steps,
        setSteps,
        incrementCurrentStep,
        decrementCurrentStep,
        setCurrentStep,
        startFrom,
      }}
    >
      <div className="flex flex-col relative items-stretch p-4 sm:p-8 h-full">
        <StepperHeader
          steps={steps}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          completed={completed}
          top={top}
        />
        <section className="my-8 flex h-full">{children}</section>
      </div>
    </StepperContext.Provider>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
