import React from "react";
import { ImageContainer } from "../../../components/ui/media/ImageContainer";
import FundMediaService from "../../../infrastructure/api/funds/media";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import { FundImageBase } from "../../../types/fund";
import { ImageType } from "../../../types/media";

interface FundImageContainerType extends FundImageBase {
  [key: string]: any;
}

type FundImageContainerProps<T extends FundImageContainerType> = {
  images: T[];
  deleteCallback: (images: T[]) => void;
  dropCallback: (images: T[]) => void;
  selectCallback: (images: T[]) => void;
  relKey: "fund_id" | "object_information_id";
  relValue: number | string | null | undefined;
  buttonClassName?: string;
  button?: React.ReactElement | string;
};

export const FundImageContainer = <T extends FundImageContainerType>({
  images,
  deleteCallback,
  dropCallback,
  selectCallback,
  relKey,
  relValue,
  button,
  buttonClassName,
}: FundImageContainerProps<T>) => {
  let draggedImageIndex: number | null = null;

  const handleDelete = (index: number) => {
    let cpImages = images ? [...images] : [];
    if (cpImages[index].id) {
      cpImages[index].order = 404;
      for (let i = index + 1; i < cpImages.length; i++) {
        cpImages[i].order -= 1;
      }
    } else {
      for (let i = index + 1; i < cpImages.length; i++) {
        cpImages[i].order -= 1;
      }
      cpImages.splice(index, 1);
    }
    deleteCallback(cpImages);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const imageIndex = parseInt(
      e.currentTarget.getAttribute("data-index") || ""
    );
    let cpImages = images ? [...images] : [];
    if (cpImages && draggedImageIndex !== null && imageIndex !== null) {
      const orderA = cpImages[imageIndex].order;
      cpImages[imageIndex].order = cpImages[draggedImageIndex].order;
      cpImages[draggedImageIndex].order = orderA;
      dropCallback(cpImages);
    }
    draggedImageIndex = null;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    draggedImageIndex = parseInt(
      e.currentTarget.getAttribute("data-index") || ""
    );
  };

  const handleSelect = (imageList: ImageType | ImageType[] | null) => {
    if (Array.isArray(imageList)) {
      const l = images?.length || 0;
      const cpImages: FundImageContainerType[] = images ? [...images] : [];

      imageList.forEach((image, i) => {
        cpImages.push({
          [relKey]: relValue,
          order: l + i,
          media_id: image.id,
          media_url: image.url,
        });
      });
      selectCallback(cpImages as typeof images);
    }
  };
  return (
    <div>
      <ImageContainer
        onSelect={handleSelect}
        service={FundMediaService}
        selected={[]}
        multiple={true}
        button={button}
        buttonClassName={buttonClassName}
      />
      <div className="flex flex-wrap gap-2 my-8">
        {images
          ?.sort((a, b) => a.order - b.order)
          .map((image, index) => (
            <div
              key={index}
              className="h-64 relative border border-dashed border-secondary-main"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              draggable={true}
              onDrag={handleDragStart}
              {...{ "data-index": index }}
              hidden={image.order === 404}
            >
              <div className="w-full flex justify-between p-1">
                <span>{image.order + 1}</span>
                <BackspaceIcon
                  onClick={() => {
                    handleDelete(index);
                  }}
                  className="h-6 w-6 text-red-500 cursor-pointer"
                />
              </div>
              <div className="h-4/5 p-2">
                <img
                  className="h-full object-contain"
                  src={image.media_url}
                  alt={`ファンド画像${image.order}回目`}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
