import React, { ChangeEvent } from "react";
import { classNames } from "../../utils";
import { Label } from "../ui/Label";

export interface InputTextProps {
  label: string;
  name: string;
  value: string;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error: string | string[];
  required?: boolean;
  type?: "text" | "textarea" | "date" | "email" | "datetime-local";
  classname?: string;
  parentClassName?: string;
  labelClassName?: string;
  rows?: number;
  attr?: object;
  placeholder?: string;
}

export const InputText: React.FC<InputTextProps> = ({
  label,
  name,
  value,
  handleChange,
  error,
  required = true,
  type = "text",
  classname = "",
  parentClassName = "",
  labelClassName = "",
  rows = 4,
  attr = {},
  placeholder = "",
}) => {
  return (
    <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
      {label && (
        <Label
          htmlFor={`id_${name}`}
          className={labelClassName}
          label={label}
          error={error.length > 0}
        />
      )}
      {type !== "textarea" ? (
        <input
          className={classNames(
            classname,
            "border p-1 rounded text-grey-text-main",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
          type={type}
          name={name}
          id={`id_${name}`}
          value={value}
          onChange={handleChange}
          required={required}
          placeholder={placeholder}
          {...attr}
        />
      ) : (
        <textarea
          className={classNames(
            classname,
            "border p-1 rounded text-grey-text-main",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
          name={name}
          id={`id_${name}`}
          value={value}
          rows={rows}
          onChange={handleChange}
          required={required}
          placeholder={placeholder}
          {...attr}
        />
      )}

      {Array.isArray(error) ? (
        error.map((text, index) => (
          <span className="text-sm text-red-500 block" key={index}>
          {text}
        </span>
        ))
      ) : (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </div>
  );
}
