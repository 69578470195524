import React from "react";
import { InputText } from "../../../components/forms/InputText";
import { errorConverter, FastapiErrorT } from "../../../utils/fastapi";
import { Label } from "../../../components/ui/Label";
import { Switch } from "@headlessui/react";
import { Form } from "../../../components/ui/Form";
import { FormButtons } from "../../../components/ui/FormButtons";

type CompanyUserEditFormProp<T extends TableData> = {
  user: T;
  setUser: React.Dispatch<React.SetStateAction<T>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors?: FastapiErrorT;
  children?: React.ReactNode;
};

export const CompanyUserEditForm = <T extends TableData>(
  props: CompanyUserEditFormProp<T>
) => {
  const { errors, setUser, handleSubmit, user, handleChange, children } = props;
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const { name } = e.target;
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64 = reader.result;
        setUser((prevState) => ({
          ...prevState,
          [name]: base64,
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  return (
    <Form errors={errors} handleSubmit={handleSubmit}>
      <InputText
        label="氏名"
        name="full_name"
        value={user.full_name || ""}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "full_name")}
      />
      <InputText
        label="メール"
        type="email"
        name="email"
        value={user.email || ""}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "email")}
      />
      <span className="my-2 inline-block">
        <label htmlFor="id_thumbnail" className="cursor-pointer border p-2">
          画像
        </label>
        <input
          required={false}
          type="file"
          className="opacity-0 w-1"
          id="id_thumbnail"
          name="thumbnail"
          onChange={handleImage}
        />
        {user.thumbnail && (
          <img className="my-2 h-24 w-24" src={user.thumbnail} alt="profile" />
        )}
      </span>
      <div className="flex gap-5">
        <div className="flex flex-col">
          <Label label="有効" />
          <Switch
            checked={user.is_active}
            onChange={(val) => {
              setUser((prev) => ({ ...prev, is_active: val }));
            }}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${
              user.is_active ? "bg-primary-dark" : "bg-gray-200"
            }`}
          >
            <span className="sr-only">有効</span>
            <span
              className={`${
                user.is_active ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        <div className="flex flex-col">
          <Label label="スーパーユーザー権限" />
          <Switch
            checked={user.is_superuser}
            onChange={(val) => {
              setUser((prev) => ({ ...prev, is_superuser: val }));
            }}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${
              user.is_superuser ? "bg-primary-dark" : "bg-gray-200"
            }`}
          >
            <span className="sr-only">スーパーユーザー権限</span>
            <span
              className={`${
                user.is_superuser ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      {children}
      <FormButtons />
    </Form>
  );
};
