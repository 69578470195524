import React, { useContext, useEffect } from "react";
import { StepperStepsProps } from "../../../store/stepper";
import { StepperContext } from "./Stepper";

export const StepperSteps = function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const { currentStep, steps, setSteps } = useContext(StepperContext);

  useEffect(() => {
    const stepperSteps = React.Children.toArray(children)
      .filter((step) => {
        return (
          React.isValidElement(step) &&
          (step.type as React.JSXElementConstructor<StepperStepsProps>).name ===
            "StepperStep"
        );
      })
      .map((step) => (step as React.ReactElement<StepperStepsProps>).props);
    setSteps(stepperSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSteps]);

  return (
    <div className="w-full flex flex-col h-full justify-between">
      {children &&
        React.Children.map(children, (child) => {
          if (steps.length) {
            return React.isValidElement(child) &&
              child?.props.id === steps[currentStep].id
              ? child
              : null;
          } else {
            return null;
          }
        })}
    </div>
  );
};

export const StepperStep = function ({
  children,
  completedButton,
  completed,
  button,
}: StepperStepsProps) {
  const { incrementCurrentStep, decrementCurrentStep } =
    useContext(StepperContext);
  return (
    <React.Fragment>
      <div
        className={`p-2 relative ${
          completed
            ? "after:content-[''] after:top-0 after:bottom-0 after:left-0 after:right-0 after:absolute after:bg-gray-400/10 pointer-events-none"
            : ""
        }`}
      >
        {children}
      </div>
      <div className="flex justify-end gap-2 border-t p-2 sticky bottom-0 bg-white z-10">
        {completed
          ? completedButton &&
            completedButton(incrementCurrentStep, decrementCurrentStep)
          : button && button(incrementCurrentStep, decrementCurrentStep)}
      </div>
    </React.Fragment>
  );
};
