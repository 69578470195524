import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PromotionService from "../../../infrastructure/api/funds/promotion";
import { PromotionType } from "../../../types/fund";
import { FastapiErrorT } from "../../../utils/fastapi";
import { promotionInitial } from "../data";
import { PromotionEditor } from "../components/PromotionEditor";
import { ImageType } from "../../../types/media";

const PromotionEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [promotion, setPromotion] = useState<PromotionType>(promotionInitial);
  const [errors, setErrors] = useState<FastapiErrorT>();

  useEffect(() => {
    if (id) {
      new PromotionService().getPromotion(id).then((data) => {
        setPromotion(data);
      });
    }
  }, [id]);
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setPromotion((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCreate = (data: PromotionType) => {
    if (id) {
      new PromotionService()
        .updatePromotion(id, data)
        .then(() => {
          navigate(-1);
        })
        .catch((err) => {
          setErrors(JSON.parse(err));
        });
    }
  };

  const handleDraft = () => {
    const cpData = { ...promotion };
    cpData.status = "draft";
    handleCreate(cpData);
  };

  const handlePublish = () => {
    const cpData = { ...promotion };
    cpData.status = "published";
    handleCreate(cpData);
  };

  const selectThumbnail = (image: ImageType | ImageType[] | null) => {
    if (!Array.isArray(image)) {
      const data = image
        ? { media_url: image.url, media_id: image.id }
        : { media_id: null, media_url: null };
      setPromotion((prev) => ({
        ...prev,
        ...data,
      }));
    }
  };
  return (
    <PromotionEditor
      promotion={promotion}
      setPromotion={setPromotion}
      selectThumbnail={selectThumbnail}
      handleChange={handleChange}
      handleDraft={handleDraft}
      handlePublish={handlePublish}
      errors={errors}
    />
  );
};

export default PromotionEdit;
