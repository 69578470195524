import React, {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import FundService from "../../../infrastructure/api/funds/fund";
import {DocumentsModelingType, FundType, NDAType} from "../../../types/fund";
import NDAModelingService from "../../../infrastructure/api/funds/documentsModeling";
import {MainLayout} from "../../../components/layouts/MainLayout";
import {Select} from "../../../components/forms/Select";
import {replacePlaceholdersWithInputs} from "../../../utils/funds";
import {ParsedDocHTML} from "./ParsedDocHTML";
import {Form} from "../../../components/ui/Form";
import {errorConverter, FastapiErrorT} from "../../../utils/fastapi";
import {PencilSquareIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import {convertDateToJapanese} from "../../../utils/date";
import {useDocuments} from "../../../hooks/useDocuments";
import {InputText} from "../../../components/forms/InputText";
import {DocumentBaseFieldsType} from "../../../types/fund";
import DocumentService from "../../../infrastructure/api/funds/documents";
import {useAuth} from "../../../hooks/useAuth";
import {PropertyType} from "../../../store/documents";


interface DocumentComponentProps<T extends DocumentBaseFieldsType> {
  fieldName: PropertyType;
  defaultValue: T;
  title: string;
}

const DocumentCreateComponent = <
  T extends DocumentBaseFieldsType,
  D extends TableData
>({
    fieldName,
    defaultValue,
    title,
  }: DocumentComponentProps<T>): React.ReactElement => {
  const {user} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {setDocNDA} = useDocuments();
  const [cachedData, setCachedData] = useState<string>();
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [fund, setFund] = useState<FundType>();
  const [origin, setOrigin] = useState<D>();
  const [ndaModelings, setNDAModelings] = useState<DocumentsModelingType[]>();
  const [errors, setErrors] = useState<FastapiErrorT>();
  const [doc, setDoc] = useState<T>(defaultValue);
  const [selectedNDAModeling, setSelectedModeling] =
    useState<DocumentsModelingType>();
  const fetchService = new DocumentService<T>(fieldName);
  const [ndaList, setNdaList] = useState<NDAType[]>([]);

  useEffect(() => {
    new NDAModelingService(fieldName).getList().then((data) => {
      setNDAModelings(data)
    });
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = ""; // This message is ignored in most modern browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compareData = () => {
    // Compare cachedData with the current state
    return JSON.stringify(doc) === cachedData;
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    selectNDAModeling(value);
  };

  const getFundFetch = (id: string | number, setFundID: boolean = false) => {
    new FundService().getFund(id).then((x) => {
      setFund(x);
      if (setFundID) {
        setDoc((prevState) => ({...prevState, fund_id: x.id}));
      }
    });
  }

  const getNDAFetch = (id: string | number, setNdaId: boolean = false) => {
    new DocumentService<D>("nda").getDoc(id).then((data) => {
      setOrigin(data);
      getFundFetch(data["fund_id" as keyof D] as string);
      if (setNdaId) {
        setDoc((prevState) => ({
          ...prevState,
          nda_uuid: data["uuid" as keyof D],
        }));
      }
    });
  };

  const selectNDAModeling = (value: number) => {
    const found = ndaModelings?.find((val) => val.id === value);
    if (found) {
      setSelectedModeling(found);
      setDoc((prevState) => ({
        ...prevState,
        documents_modeling_id: found.id,
      }));
    }
  };

  useEffect(() => {
    if (ndaModelings && ndaModelings.length > 0) {
      const paramID = paramsObject[fieldName];
      if (paramID) {
        fetchService.getDoc(paramID).then((x) => {
          setDoc(x);
          setCachedData(JSON.stringify(x));
          if (x.documents_modeling_id) {
            selectNDAModeling(x.documents_modeling_id);
          }
          if (x.fund_id) {
            getFundFetch(x.fund_id);
          } else if (x.nda_uuid) {
            getNDAFetch(x.nda_uuid);
          }
        });
      } else if (paramsObject.fund_id) {
        getFundFetch(paramsObject.fund_id, true);
      } else if (paramsObject.nda_uuid) {
        getNDAFetch(paramsObject.nda_uuid, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ndaModelings, paramsObject[fieldName], paramsObject.fund_id, paramsObject.nda_uuid]);

  const [replacedHTML, autoData] = useMemo(
    () =>
      replacePlaceholdersWithInputs(
        selectedNDAModeling?.content || "",
        fund,
        doc.data,
        doc.published
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedNDAModeling?.content, doc.published, fund]
  );

  const handleDraft = () => {
    if (user?.id) {
      const cpDoc = {...doc};
      if (!cpDoc.created_by) cpDoc.created_by = user.id;
      else cpDoc.updated_by = user.id;
      fetchService
        .updateOrCreate(cpDoc, true)
        .then((data) => {
          setDoc(data);
          setCachedData(JSON.stringify(data));
          if (data.fund_id) setDocNDA(data, data.fund_id, fieldName);
          else setDocNDA(data, origin?.fund_id, fieldName);
          if (
            data.uuid &&
            (!paramsObject.uuid || paramsObject.uuid !== data.uuid)
          ) {
            setSearchParams({[fieldName]: data.uuid});
          }
        })
        .catch((error) => {
          setErrors(JSON.parse(error));
        });
    }
  };
  const clickSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!doc.draft && compareData()) {
      e.preventDefault();
    } else if (!window.confirm("Are sure you wanna to submit")) {
      e.preventDefault();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user?.id) {
      const cpDoc = {...doc};
      cpDoc.data = {...doc.data, ...autoData};
      if (!cpDoc.created_by) cpDoc.created_by = user.id;
      else cpDoc.updated_by = user.id;
      fetchService
        .updateOrCreate(cpDoc)
        .then((data) => {
          setDoc(data);
          setCachedData(JSON.stringify(data));
          if (data.fund_id) setDocNDA(data, data.fund_id, fieldName);
          else setDocNDA(data, origin?.fund_id, fieldName);
          if (
            data.uuid &&
            (!paramsObject.uuid || paramsObject.uuid !== data.uuid)
          ) {
            setSearchParams({[fieldName]: data.uuid});
          }
        })
        .catch((err) => {
          setErrors(JSON.parse(err));
        });
    }
  };

  return (
    <MainLayout title={title}>
      <Form errors={errors} handleSubmit={handleSubmit}>
        <div className="w-full flex justify-end gap-2 text-xs">
          {doc.created_at && (
            <div className="flex items-center gap-2">
              <PlusCircleIcon className="h-4 w-4"/>
              <span className="text-grey-text-light">
                {convertDateToJapanese(doc.created_at)}
              </span>
            </div>
          )}
          {doc.updated_at && (
            <div className="flex items-center gap-2">
              <PencilSquareIcon className="h-4 w-4"/>
              <span className="text-grey-text-light">
                {convertDateToJapanese(doc.updated_at)}
              </span>
            </div>
          )}
        </div>
        <Select
          value={selectedNDAModeling?.id}
          handleChange={handleSelect}
          label="契約成立前書面のベースのバージョン"
          name="nda_modeling_list"
          error={""}
          options={ndaModelings || []}
          valKey="id"
          labelKey="name"
        />
        <InputText
          parentClassName="w-full my-2"
          label="文書名"
          name="name"
          value={doc?.name || ""}
          handleChange={(e) => {
            setDoc((prev) => ({...prev, name: e.target.value}));
          }}
          error={errorConverter(errors?.detail, "name")}
        />
        <div className="w-full nda-documents border-y-2">
          <ParsedDocHTML<T, D>
            origin={origin}
            html={replacedHTML}
            setState={setDoc}
            state={doc}
          />
        </div>
        <div className="flex justify-between w-full py-2 sticky bottom-0 bg-white">
          <button className="border rounded p-2" onClick={() => {
          }}>
            戻る
          </button>
          <div className="flex justify-end">
            {doc.draft !== false && (
              <button
                type="button"
                className="p-2 text-primary-dark"
                onClick={handleDraft}
              >
                下書き
              </button>
            )}
            <button
              type="submit"
              onClick={clickSubmit}
              className="border rounded p-2 bg-primary-dark"
            >
              保存
            </button>
          </div>
        </div>
      </Form>
    </MainLayout>
  );
};

export default DocumentCreateComponent;
