import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import OperatorService from "../../../infrastructure/api/funds/operator";
import { OperatorBodyType, OperatorType } from "../../../types/fund";
import { operatorInitial } from "../data";
import { errorConverter, FastapiErrorT } from "../../../utils/fastapi";
import { InputText } from "../../../components/forms/InputText";
import { AppendTable } from "../../../components/ui/AppendTable";
import { MainLayout } from "../../../components/layouts/MainLayout";

const OperatorCreate: React.FC = () => {
  const navigate = useNavigate();
  const [operator, setOperator] = useState<OperatorType>(operatorInitial);
  const [errors, setErrors] = useState<FastapiErrorT>();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setOperator((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const cpData = { ...operator };
    if (cpData.body) {
      cpData.body[index][name as keyof OperatorBodyType] = value;
    }
    setOperator(cpData);
  };

  const handleAdd = () => {
    const cpData = { ...operator };
    cpData.body?.push({ label: "", value: "" });
    setOperator(cpData);
  };

  const handleDelete = (index: number) => {
    const cpData = { ...operator };
    cpData.body?.splice(index, 1);
    setOperator(cpData);
  };

  const handleCreate = () => {
    new OperatorService()
      .createOperator(operator)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        setErrors(JSON.parse(err));
      });
  };

  return (
    <MainLayout title="運用者を新規追加">
      <div className="flex flex-col gap-2 w-full">
        <InputText
          label="運用者名"
          name="name"
          value={operator.name || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "name")}
        />
        <InputText
          label="URL"
          name="url"
          value={operator.url || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "url")}
        />
        <AppendTable
          data={operator.body || []}
          label=""
          labelKey="label"
          valueKey="value"
          handleChange={handleDataChange}
          handleDelete={handleDelete}
          handleAdd={handleAdd}
          deleteStartIndex={0}
        />
      </div>
      <div className="flex justify-between gap-2 w-full p-2">
        <Link
          className="flex-1 rounded bg-grey-boarder p-2 text-center"
          to="/funds/operator"
        >
          戻る
        </Link>
        <button
          type="button"
          className="flex-1 rounded bg-primary-dark text-white"
          onClick={handleCreate}
        >
          追加
        </button>
      </div>
    </MainLayout>
  );
};

export default OperatorCreate;
