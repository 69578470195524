import React from "react";
import { StepperState } from "../../../store/stepper";
import { CheckIcon } from "@heroicons/react/20/solid";

type StepperHeaderItemType = {
  index: number;
  name: string;
  passed: boolean;
  id: string | number;
  completed: (string | number)[];
  length: number;
  setCurrentStep: (step: number) => void;
};

const StepperHeaderItem: React.FC<StepperHeaderItemType> = (props) => {
  const { index, name, completed, passed, id, setCurrentStep } = props;
  return (
    <li className="relative flex flex-col items-center justify-center flex-1 min-w-[120px]">
      <span className="whitespace-nowrap">{name}</span>
      <span
        className="flex items-center whitespace-nowrap"
        style={completed.includes(id) ? { cursor: "pointer" } : {}}
        onClick={() => {
          if (completed.includes(id)) {
            setCurrentStep(index);
          }
        }}
      >
        <span
          className={`h-5 w-5 rounded-full z-10 relative ${
            index === 0 || passed
              ? "bg-blue-500"
              : completed.includes(id)
              ? "bg-blue-300"
              : "bg-gray-300"
          }`}
        >
          {completed.includes(id) && (
            <span className="h-5 w-5 rounded bg-transparent flex items-center justify-center">
              <CheckIcon className="h-4 w-4 text-white" />
            </span>
          )}
        </span>
        <span
          className={`absolute w-full h-1 ${index > 0 && "left-[-50%]"} ${
            passed
              ? "bg-blue-500"
              : completed.includes(id)
              ? "bg-blue-300"
              : "bg-gray-300"
          }`}
        />
      </span>
    </li>
  );
};

export const StepperHeader: React.FC<
  StepperState & {
    completed: (string | number)[];
    top?: string | number;
    setCurrentStep: (step: number) => void;
  }
> = ({ steps, currentStep, completed, top, setCurrentStep }) => {
  const length = steps.length;
  return (
    <nav className="sticky bg-white z-10 p-2" style={{ top: top || 0 }}>
      <ol className="flex w-full m-auto relative items-center overflow-x-scroll scrollbar-hide">
        {steps.map((step, index) => (
          <StepperHeaderItem
            setCurrentStep={setCurrentStep}
            key={step.id}
            id={step.id}
            index={index}
            name={step.name}
            completed={completed}
            passed={currentStep >= index}
            length={length}
          />
        ))}
      </ol>
    </nav>
  );
};
