import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../components/layouts/MainLayout";
import useForm from "../../../hooks/useForm";
import { CompanyReadType, CompanyType } from "../../../types/company";
import { FastapiErrorT } from "../../../utils/fastapi";
import useAuthAxios from "../../../hooks/useAuthAxios";
import CompanyService from "../../../infrastructure/api/company/company";
import ExternalService from "../../../infrastructure/api/external/external";
import { MapComponent, renderMap } from "../../../components/ui/MapComponent";
import { Wrapper } from "@googlemaps/react-wrapper";
import { CompanyEditForm } from "./CompanyEditForm";

interface TableRowProps {
  label: string;
  value: string | React.ReactNode;
}

const TableRow: React.FC<TableRowProps> = ({ label, value }) => {
  return (
    <tr className="border-b">
      <th className="p-2 bg-gray-100">{label}</th>
      <td className="p-2">{value}</td>
    </tr>
  );
};

const Company: React.FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyReadType>({
    address: "",
    business_activities: "",
    company_name: "",
    company_url: "",
    capital_stock: "",
    postal_code: "",
    licenses: "",
    representative: "",
    history: [{ label: "", value: "" }],
    google_map: { zoom: null, center: null },
    establishment: "",
    created_by_name: "",
    updated_by_name: "",
    email: "",
    tel: "",
  });
  const axios = useAuthAxios();
  const service = new CompanyService(axios);

  const { inputValues, setInputValues, errors, handleChange, handleSubmit } =
    useForm<CompanyType, FastapiErrorT>(company, (values, setErrors) => {
      service.update(values).then((data) => {
        setCompany(data);
        setEditMode(false);
      });
    });

  useEffect(() => {
    service.get().then((data) => {
      setCompany(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMode) {
      setInputValues(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (inputValues.postal_code) {
      new ExternalService().getAddress(inputValues.postal_code).then((data) => {
        if (data.results) {
          const address =
            data.results[0].address1 +
            data.results[0].address2 +
            data.results[0].address3;
          setInputValues((prev) => ({
            ...prev,
            address: address,
          }));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues.postal_code]);

  return (
    <MainLayout title="会社情報">
      {editMode ? (
        <CompanyEditForm
          errors={errors}
          inputValues={inputValues}
          handleChange={handleChange}
          setInputValues={setInputValues}
          setEditMode={setEditMode}
          handleSubmit={handleSubmit}
        />
      ) : (
        <div className="w-full">
          <div className="w-full flex justify-end mb-6">

            <button
              onClick={() => {
                setEditMode(true);
              }}
              type="button"
              className="py-1 px-4 bg-primary-dark text-white"
            >
              編集
            </button>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <table className="border-collapse border">
              <tbody>
                <TableRow label="会社名" value={company.company_name} />
                <TableRow label="代表者" value={company.representative} />
                <TableRow label="資本金" value={company.representative} />
                <TableRow label="設立" value={company.capital_stock} />
                <TableRow
                  label="所在地"
                  value={
                    <>
                      <span className="block text-xs text-grey-text-light">
                        〒{company.postal_code}
                      </span>
                      {company.address}
                    </>
                  }
                />
                <TableRow
                  label="免許"
                  value={
                    <span className="whitespace-pre">{company.licenses}</span>
                  }
                />
                <TableRow
                  label="事業内容"
                  value={company.business_activities}
                />
                <TableRow label="電話番号" value={company.tel} />
                <TableRow label="メール" value={company.email} />
              </tbody>
            </table>
            <Wrapper
              apiKey={"AIzaSyD_twS9lHZSXoPvga-N1rHMO4qa_UkSN08"}
              render={renderMap}
            >
              <MapComponent
                className="h-80 md:h-auto w-full pointer-events-none"
                address={company.address || ""}
                center={
                  company.google_map?.center || {
                    lat: -34,
                    lng: 151,
                  }
                }
                zoom={company.google_map?.zoom || 10}
                handleGoogleMapChange={() => {}}
              />
            </Wrapper>
          </div>
          <div className="mt-10">
            <table>
              <caption className="text-left text-lg font-semibold">
                沿革
              </caption>
              <tbody>
                {company.history?.map((val, index) => (
                  <tr key={index}>
                    <th className="border-r py-2 px-4 w-28">{val.label}</th>
                    <td className="w-80 py-2 px-4 whitespace-pre-wrap">
                      {val.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Company;
