import React, { useState, useCallback, FormEvent } from "react";

type FormValues = {
  [key: string]: any;
};

type SubmitProps = { [key: string]: string } | string | undefined;

type FormErrors = {
  [key: string]: any;
};

export type FormCallback<
  T extends FormValues,
  ErrorT extends FormErrors,
  SubmitPropsT extends SubmitProps = undefined
> = (
  values: T,
  setErrors: (errors: ErrorT) => void,
  props?: SubmitPropsT
) => void;

export type FormHook<
  T extends FormValues,
  ErrorT extends FormErrors,
  SubmitPropsT extends SubmitProps = undefined
> = {
  inputValues: T;
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  errors: ErrorT | undefined;
  handleSubmit: (
    event: FormEvent<HTMLFormElement>,
    props?: SubmitPropsT
  ) => void;
  handleClick?: () => void;
};

type MainFormHook<
  T extends FormValues,
  ErrorT extends FormErrors,
  SubmitPropsT extends SubmitProps = undefined
> = {
  handleInitial: (data: T) => void;
  setInputValues: React.Dispatch<React.SetStateAction<T>>;
} & FormHook<T, ErrorT, SubmitPropsT>;

function useForm<
  T extends FormValues,
  ErrorT extends FormErrors,
  SubmitPropsT extends SubmitProps = undefined
>(
  initialValues: T,
  onSubmit: FormCallback<T, ErrorT, SubmitPropsT>,
  onClick?: FormCallback<T, ErrorT, SubmitPropsT>
): MainFormHook<T, ErrorT, SubmitPropsT> {
  const [inputValues, setInputValues] = useState<T>(initialValues);
  const [errors, setErrors] = useState<ErrorT>();

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      num: number | null = null
    ) => {
      const { name, value } = event.target;
      const dataList = event.target.getAttribute("data-list");
      const dataIndex = parseInt(event.target.getAttribute("data-index") || "");
      if (dataList) {
        if (!isNaN(dataIndex)) {
          let cpData = { ...inputValues };
          cpData[dataList][dataIndex][name] = value;
          setInputValues(cpData);
        }
      } else {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: num ? num : value,
        }));
      }
    },
    [inputValues]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>, props?: SubmitPropsT) => {
      event.preventDefault();
      onSubmit(inputValues, setErrors, props);
    },
    [onSubmit, inputValues]
  );

  const handleClick = useCallback(
    (props?: SubmitPropsT) => {
      if (onClick !== undefined) {
        onClick(inputValues, setErrors, props);
      }
    },
    [onClick, inputValues]
  );

  const handleInitial = useCallback(
    (data: T) => {
      setInputValues(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValues]
  );

  return {
    inputValues,
    handleChange,
    errors,
    handleSubmit,
    handleInitial,
    handleClick,
    setInputValues,
  };
}

export default useForm;
