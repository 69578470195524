import React from "react";

export const SET_STEPS = "SET_STEPS";
export const INCREMENT_CURRENT_STEP = "INCREMENT_CURRENT_STEP";
export const DECREMENT_CURRENT_STEP = "DECREMENT_CURRENT_STEP";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";

export interface StepperStepsProps {
  id: string | number;
  name: string;
  completedButton?: (
    incrementCurrentStep: () => void,
    decrementCurrentStep: () => void
  ) => React.ReactNode;
  button?: (
    incrementCurrentStep: () => void,
    decrementCurrentStep: () => void
  ) => React.ReactNode;
  completed?: boolean;
  children: React.ReactNode;
}

export type StepperState = {
  steps: StepperStepsProps[];
  currentStep: number;
};

export type StepperContext = StepperState & {
  setSteps: (steps: StepperStepsProps[]) => void;
  setCurrentStep: (step: number) => void;
  incrementCurrentStep: () => void;
  decrementCurrentStep: () => void;
  startFrom: number;
};

export type ActionType =
  | { type: typeof SET_STEPS; payload: { steps: StepperStepsProps[] } }
  | { type: typeof SET_CURRENT_STEP; payload: { step: number } }
  | { type: typeof INCREMENT_CURRENT_STEP }
  | { type: typeof DECREMENT_CURRENT_STEP };

export const defaultStepperState: StepperState = {
  steps: [],
  currentStep: 0,
};

export const defaultStepperContextState: StepperContext = {
  steps: [],
  currentStep: 0,
  setSteps: () => {},
  decrementCurrentStep: () => {},
  incrementCurrentStep: () => {},
  setCurrentStep: () => {},
  startFrom: 0,
};

export const reducer = (
  state: StepperState = defaultStepperState,
  action: ActionType
) => {
  const { currentStep, steps } = state;
  switch (action.type) {
    case SET_STEPS:
      return {
        ...state,
        steps: action.payload.steps,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.step,
      };
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep:
          currentStep < steps.length - 1 ? currentStep + 1 : currentStep,
      };
    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };
    default:
      return state;
  }
};
