import React, { useEffect, useState } from "react";
import { FundType } from "../../../types/fund";
import { MainLayout } from "../../../components/layouts/MainLayout";
import FundService from "../../../infrastructure/api/funds/fund";
import { FastapiErrorT } from "../../../utils/fastapi";
import { AddLink } from "../../../components/ui/AddLink";
import img from "../../../assets/images/profile.png";
import {Link} from "react-router-dom";

const FundList: React.FC = () => {
  const [funds, setFunds] = useState<FundType[]>([]);

  useEffect(() => {
    new FundService()
      .getList()
      .then((data) => {
        setFunds(data);
      })
      .catch((error: FastapiErrorT) => {
        alert(error.detail);
      });
  }, []);

  return (
    <MainLayout
      title="ファンド一覧"
      label={"新規追加"}
      to="create"
    >
        <ul className="w-full mt-5">
          {funds.map((fund) => (
          <li key={fund.id} className="w-full mb-5 ">
            <div className="grid grid-cols-5 gap-2 border">
              <figure className="h-28 w-44 overflow-hidden">
                <img className="col-span-1 h-full w-full object-cover object-center" src={fund.thumbnail} alt="test" />
              </figure>
              <div className="col-span-4 flex flex-col">
                <h2>{fund.name}</h2>
                <Link to={`edit/${fund.id}`}>edit</Link>
              </div>
            </div>
          </li>
          ))}
        </ul>

    </MainLayout>
  );
};

export default FundList;
