import { SlateElement } from "@wangeditor/editor";
import { ImageElement } from "./custom-types";

// 生成 html 的函数
function imageToHtml(elem: SlateElement, childrenHtml: string): string {
  const { src, alt = "", href = "", style = {} } = elem as ImageElement;
  const { width = "", height = "", float = "", position } = style;
  let styleStr = "";
  if (width) styleStr += `width: ${width};`;
  if (height) styleStr += `height: ${height};`;
  if (float) styleStr += `float: ${float};`;
  if (position) styleStr += `position: ${position};bottom:0;left:0;`;
  return `<span style="position: relative"><span style="${styleStr}"><img src="${src}" alt="${alt}" data-href="${href}" style="${styleStr}"></span></span>`;
}

// 配置
const conf = {
  type: "image", // 节点 type ，重要！！！
  elemToHtml: imageToHtml,
};

export default conf;
