import React from "react";
import { ButtonType, ButtonVariantType } from "./types";
import { CancelButton } from "./CancelButton";
import { SaveButton } from "./SaveButton";
import { ButtonVariant } from "./constants";
import { DeleteButton } from "./DeleteButton";

export const Button: React.FC<ButtonType & ButtonVariantType> = ({
  variant,
  onClick,
  value,
}) => {
  switch (variant) {
    case ButtonVariant.Cancel:
      return <CancelButton onClick={onClick} value={value} />;
    case ButtonVariant.Delete:
      return <DeleteButton onClick={onClick} value={value} />;
    case ButtonVariant.Save:
      return <SaveButton onClick={onClick} value={value} />;
  }
};

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

export const TextButton: React.FC<ButtonProps> = ({ text, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className="p-2 border border-transparent hover:border-secondary-main rounded text-sm text-secondary-main"
  >
    {text}
  </button>
);

export const ContainedButton: React.FC<ButtonProps> = ({ text, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="bg-secondary-main p-2 border-secondary-main border rounded text-white text-sm"
  >
    {text}
  </button>
);
