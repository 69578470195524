import React from "react";
import { ButtonType } from "./types";

export const SaveButton: React.FC<ButtonType> = ({ onClick, value }) => (
  <button
    type="button"
    onClick={onClick}
    className="flex-1 p-1 rounded cursor-pointer bg-blue-400 hover:bg-blue-200 text-white"
  >
    {value ? value : "保存"}
  </button>
);
