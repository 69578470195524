import React, { useMemo, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Column } from "react-table";
import OperatorService from "../../../infrastructure/api/funds/operator";
import { OperatorType } from "../../../types/fund";
import { urlDecodeObj, urlEncodeObj } from "../../../utils/query";
import { ReactTable } from "../../../components/ui/Table";
import { MainLayout } from "../../../components/layouts/MainLayout";
import AccordionLayout from "../../../components/layouts/AccordionLayout";
import { AddLink } from "../../../components/ui/AddLink";

export const OperatorList: React.FC = () => {
  const [operators, setOperators] = useState<PaginatedDataType<OperatorType>>({
    count: 0,
    data: [],
  });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(
    location.search || "page=0&limit=20"
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const fetchData = (page: number, l: number) => {
    const stringURL = urlEncodeObj({
      page: page,
      limit: l,
    });
    if (searchParams.toString() !== stringURL) {
      setSearchParams(stringURL);
    }
    new OperatorService()
      .getList(page, l)
      .then((data) => {
        setOperators(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const memoData = useMemo(() => operators.data, [operators.data]);
  const columns: Column<OperatorType>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        Cell: (row) => (
          <div className="relative group">
            <AccordionLayout<number>
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              title={row.value || ""}
              index={row.row.original.id || 0}
            >
              <div className="mb-2">
                URL: <small>{row.row.original.url || "ー"}</small>
              </div>
              <table className="border-collapse border border-grey-boarder overflow-x-scroll w-full">
                <tbody>
                  {row.row.original.body?.map((val, index) => (
                    <tr key={index}>
                      <th className="border p-2 border-grey-boarder">
                        {val.label}
                      </th>
                      <td className="border p-2 border-grey-boarder">
                        {val.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionLayout>
            <div className="z-10 hidden group-hover:flex text-sm divide-x-2">
              <Link
                className="text-blue-600 hover:text-blue-400 px-1"
                to={`edit/${row.row.original.id}`}
              >
                Edit
              </Link>
              <button className="text-red-600 hover:text-red-400 px-1">
                Trash
              </button>
            </div>
          </div>
        ),
      },
    ],
    [activeIndex]
  );

  const searchObj = urlDecodeObj(searchParams.toString());

  const memorizedTable = React.useMemo(
    () => (
      <ReactTable<OperatorType>
        columns={columns}
        data={memoData}
        dataCount={operators.count}
        totalPage={Math.ceil(operators.count / Number(searchObj["limit"] || 1))}
        callback={fetchData}
        pageSizeOptions={[20, 50, 100, 300]}
        initialPageSize={Number(searchObj["limit"] || 20)}
        initialSort={searchObj["order_by"] || []}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, memoData]
  );
  return (
    <MainLayout title="運営者">
      <div className="w-full flex justify-end">
        <AddLink to="create" label="新規追加" />
      </div>
      {memorizedTable}
    </MainLayout>
  );
};
