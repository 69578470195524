export function urlEncodeArray(arr: any[], arrKey: string) {
  return arr
    .map((obj: Object, index) =>
      Object.entries(obj)
        .map(
          ([key, value]) =>
            `${arrKey}[${index}][${encodeURIComponent(
              key
            )}]=${encodeURIComponent(value)}`
        )
        .join("&")
    )
    .join("&");
}

export function urlEncodeObj(obj: TableData): string {
  let urlArray: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (/\S/.test(value)) {
      urlArray.push(
        Array.isArray(value)
          ? urlEncodeArray(value, key)
          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  });
  return urlArray.join("&");
}

export function urlDecodeObj(search: string) {
  return decodeURIComponent(search)
    .split("&")
    .reduce((acc: any, pair) => {
      const [key, value] = pair.split("=");
      if (/\[\d+\]/.test(key)) {
        // @ts-ignore
        const [, k, index, subKey] = key.match(/(\w+)\[(\d+)\]\[(\w+)\]/);
        if (!acc[k]) {
          acc[k] = [];
        }
        if (!acc[k][index]) {
          acc[k][index] = {};
        }
        acc[k][index][subKey] =
          decodeURIComponent(value) === "false"
            ? false
            : decodeURIComponent(value) === "true"
            ? true
            : decodeURIComponent(value);
      } else {
        acc[key] = decodeURIComponent(value);
      }
      return acc;
    }, {});
}
