import React, {useEffect, useState} from "react";
import {MainLayout} from "../../../components/layouts/MainLayout";
import UserService from "../../../infrastructure/api/customers/user";
import {Customer} from "../../../store/customers";


const UserList: React.FC = () => {
	const [users, setUsers] = useState<Customer[]>([]);

	useEffect(() => {
		new UserService()
			.getUsers()
			.then((data) => {
				setUsers(data);
			});
	}, []);

	return (
		<MainLayout
			title="ユーザー一覧"
			label={"新規追加"}
			to="create"
		>
			<ul className="w-full mt-5">
				{users.map((user) => (
					<li key={user.id} className="w-full mb-5 ">
						<div>{user.id}</div>
						<div>{user.email}</div>
						<div>{user.account_type}</div>
					</li>
				))}
			</ul>

		</MainLayout>
	)
}

export default UserList;