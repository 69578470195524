import {
  DurationType,
  FundType,
  getLabelForDurationType, getLabelForRecruitmentType,
  getLabelForStatus,
  getLabelForTransactionType,
  OperatorType,
  PromotionType, RecruitmentType,
  Status,
  TransactionType,
} from "../../types/fund";

export const statusOptions: SelectOptionType[] = Object.values(Status).map(
  (status) => ({
    value: status,
    label: getLabelForStatus(status),
  })
);

export const transactionTypeOptions: SelectOptionType[] = Object.values(
  TransactionType
).map((transaction) => ({
  value: transaction,
  label: getLabelForTransactionType(transaction),
}));

export const durationTypeOptions: SelectOptionType[] = Object.values(
  DurationType
).map((duration) => ({
  value: duration,
  label: getLabelForDurationType(duration),
}));

export const recruitmentTypeOptions: SelectOptionType[] = Object.values(
  RecruitmentType
).map((recruitment) => ({
  value: recruitment,
  label: getLabelForRecruitmentType(recruitment)
}));

export const fundInitial: FundType = {
  name: "",
  slug: "",
  status: Status.Draft,
  amount_raised: null,
  subordinated_investment: null,
  total_distributions: null,
  fund_overview: null,
  transaction_type: TransactionType.Online,
  total_investment: null,
  investment_amount: null,
  min_num_units: null,
  max_num_units: null,
  capital_interest: null,
  income_interest: null,
  operation_start: null,
  operation_end: null,
  duration: null,
  duration_type: DurationType.Yearly,
  steps: {
    fundFirst: false,
    fundSecond: false,
    fundThird: false,
    fundFourth: false,
    fundFifth: false,
    fundSix: false,
    fundSeven: false,
  },
};

export const operatorInitial: OperatorType = {
  url: "",
  name: "",
  body: [
    {
      label: "会社名",
      value: "",
    },
    {
      label: "代表者",
      value: "",
    },
    {
      label: "資本金",
      value: "",
    },
    {
      label: "設立",
      value: "",
    },
    {
      label: "所在地",
      value: "",
    },
    {
      label: "免許",
      value: "",
    },
  ],
};

export const promotionInitial: PromotionType = {
  title: "",
  status: "draft",
  rules: [],
};
