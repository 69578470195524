import axios from "axios";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class UserService {

	getUsers() {
		return axios.get(API_URL + "/api/v1/admin/users/").then((res) => res.data).catch(() => []);
	}
}

export default UserService;