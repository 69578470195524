import { useCallback, useReducer } from "react";
import {
  DECREMENT_CURRENT_STEP,
  defaultStepperState,
  INCREMENT_CURRENT_STEP,
  SET_CURRENT_STEP,
  reducer,
  SET_STEPS,
  StepperStepsProps,
} from "../store/stepper";

export const useStepper = (startFrom: number) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultStepperState,
    currentStep: startFrom,
  });
  const { currentStep, steps } = state;

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const setSteps = useCallback(
    (steps: StepperStepsProps[]) =>
      dispatch({ type: SET_STEPS, payload: { steps } }),
    [dispatch]
  );
  const setCurrentStep = useCallback(
    (step: number) => dispatch({ type: SET_CURRENT_STEP, payload: { step } }),
    [dispatch]
  );

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    setSteps,
    currentStep,
    steps,
    setCurrentStep,
  };
};
