import React from "react";
import { InputText } from "../../../../components/forms/InputText";
import { errorConverter } from "../../../../utils/fastapi";
import { Select } from "../../../../components/forms/Select";
import {
  durationTypeOptions,
  statusOptions,
  transactionTypeOptions,
} from "../../data";
import { InputCurrency } from "../../../../components/forms/InputCurrency";
import { InputFloat } from "../../../../components/forms/InputFloat";
import { FundStepProps, FundType } from "../../../../types/fund";
import { Label } from "../../../../components/ui/Label";
import { numberWithCommas } from "../../../../utils/numbers";
import Editor from "../../../../components/ui/Editor";

export interface ListProps {
  value: number | string | null | undefined;
  label: string;
  lastText: string;
}

export const List: React.FC<ListProps> = ({ value, label, lastText }) => (
  <li className="flex flex-col justify-between">
    <Label label={label} />
    <p className="border-b border-accent-main text-secondary-main">
      {value ? (
        <>
          {typeof value === "number" ? numberWithCommas(value) : value}
          <small className="text-grey-text-light">{lastText}</small>
        </>
      ) : (
        "ー"
      )}
    </p>
  </li>
);

export const CreateSummary: React.FC<
  FundStepProps & {
    setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
  }
> = ({ inputValues, handleChange, errors, setInputValues }) => (
  <div className="grid grid-flow-row sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-3 gap-y-5">
    <InputText
      label="ファンド名"
      name="name"
      parentClassName="col-span-1 lg:col-span-2"
      value={inputValues.name}
      handleChange={handleChange}
      error={errorConverter(errors?.detail, "name")}
    />
    <InputText
      label="URLスラッグ"
      name="slug"
      value={inputValues.slug}
      handleChange={handleChange}
      error={errorConverter(errors?.detail, "slug")}
    />
    <Select<SelectOptionType, string>
      label="ファンドタイプ"
      name="transaction_type"
      value={inputValues.transaction_type || ""}
      handleChange={handleChange}
      valKey="value"
      options={transactionTypeOptions}
      labelKey="label"
      error={errorConverter(errors?.detail, "transaction_type")}
    />
    <Select<SelectOptionType, string>
      label="ファンドステータス"
      name="status"
      value={inputValues.status || ""}
      handleChange={handleChange}
      valKey="value"
      options={statusOptions}
      labelKey="label"
      error={errorConverter(errors?.detail, "status")}
    />
    <div className="col-span-1 sm:col-span-2 grid grid-cols-2 gap-2 border-dotted border border-primary-dark p-2 my-4">
      <InputCurrency
        label="出資総額"
        name="total_investment"
        value={inputValues.total_investment}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "total_investment")}
      />
      <InputCurrency
        label="出資金"
        name="investment_amount"
        value={inputValues.investment_amount}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "investment_amount")}
        rightLabel="円/1口"
      />
      <div className="p-2 col-span-2">
        <ul className="grid grid-cols-1 sm:grid-cols-3 gap-1">
          <List
            label="出資総額"
            value={inputValues.amount_raised}
            lastText="万円"
          />
          <List
            label="劣後出資"
            value={inputValues.subordinated_investment}
            lastText="万円"
          />
          <List
            label="募集口数"
            value={inputValues.number_of_units}
            lastText="口"
          />
        </ul>
      </div>
    </div>
    <div className="col-span-1 sm:col-span-2 grid sm:grid-cols-2 gap-2 my-4">
      <InputCurrency
        label="最低出資口数"
        name="min_num_units"
        value={inputValues.min_num_units}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "min_num_units")}
        rightLabel="口"
      />
      <InputCurrency
        label="最高出資口数"
        name="max_num_units"
        value={inputValues.max_num_units}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "max_num_units")}
        rightLabel="口"
      />
      <InputFloat
        label="キャピタル利回り"
        name="capital_interest"
        value={inputValues.capital_interest}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "capital_interest")}
        rightLabel="%"
      />
      <InputFloat
        label="インカム利回り"
        name="income_interest"
        value={inputValues.income_interest}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "income_interest")}
        rightLabel="%"
      />
      <InputCurrency
        label="総分配回数"
        name="total_distributions"
        value={inputValues.total_distributions}
        handleChange={handleChange}
        error={errorConverter(errors?.detail, "total_distributions")}
        rightLabel="回"
      />
    </div>
    <div className="col-span-1 sm:col-span-2 md:col-span-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 my-4">
      <div className="flex items-end gap-1">
        <InputText
          label="運用開始日"
          type="date"
          name="operation_start"
          parentClassName="flex-1"
          value={inputValues.operation_start || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "operation_start")}
        />
        <span className="text-xs">から</span>
      </div>
      <div className="flex items-end gap-1">
        <InputText
          label="運用終了日"
          name="operation_end"
          type="date"
          parentClassName="flex-1"
          value={inputValues.operation_end || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "operation_end")}
        />
        <span className="text-xs">まで</span>
      </div>
      <div className="col-span-1 sm:col-span-2 flex flex-wrap gap-2">
        <InputText
          label="期間"
          name="duration"
          value={inputValues.duration || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "duration")}
        />
        <Select
          label="期間の種類"
          name="duration_type"
          value={inputValues.duration_type || ""}
          handleChange={handleChange}
          valKey="value"
          labelKey="label"
          error={errorConverter(errors?.detail, "duration_type")}
          options={durationTypeOptions}
        />
      </div>
      <div className="col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
        <Label
          label="ファンド概要"
          error={errorConverter(errors?.detail, "fund_overview").length > 0}
        />
        <div
          className={`border ${
            errorConverter(errors?.detail, "fund_overview").length > 0
              ? "border-red-500"
              : ""
          }`}
        >
          <Editor
            data={inputValues.fund_overview || undefined}
            handleChange={(data) => {
              setInputValues((prevState) => ({
                ...prevState,
                fund_overview: data,
              }));
            }}
          />
        </div>
        {Array.isArray(errorConverter(errors?.detail, "fund_overview")) ? (
          errorConverter(errors?.detail, "fund_overview").map((text, index) => (
            <span className="text-sm text-red-500 block" key={index}>
              {text}
            </span>
          ))
        ) : (
          <span className="text-sm text-red-500">
            {errorConverter(errors?.detail, "fund_overview")}
          </span>
        )}
      </div>
    </div>
  </div>
);
