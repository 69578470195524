import React, { useState } from "react";
import { DocumentsContextFieldsType } from "../store/documents";

const initial: DocumentsContextFieldsType = {
  fund_id: "",
  nda: [],
  contract_agree_form: [],
  anonymous_partnership_contract: [],
  ecommerce_details: [],
  changed: 0,
};

export const DocumentsContext = React.createContext<
  [DocumentsContextFieldsType, React.Dispatch<DocumentsContextFieldsType>]
>([initial, () => {}]);

export const DocumentsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [documents, setDocuments] =
    useState<DocumentsContextFieldsType>(initial);

  return (
    <DocumentsContext.Provider value={[documents, setDocuments]}>
      {children}
    </DocumentsContext.Provider>
  );
};
