import React from "react";
import {
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/20/solid";
import { errorConverter } from "../../../utils/fastapi";
import FundMediaService from "../../../infrastructure/api/funds/media";
import { ImageContainer } from "../../../components/ui/media/ImageContainer";
import { PromotionEditorProps } from "./PromotionEditor";
import { InputText } from "../../../components/forms/InputText";
import { InputFloat } from "../../../components/forms/InputFloat";
import { InputCurrency } from "../../../components/forms/InputCurrency";

interface MenuBarProps extends PromotionEditorProps {
  shrinkMenuRef: React.RefObject<HTMLDivElement>;
  handleShrink: () => void;
  handleUpShrink: () => void;
}

export const MenuBar: React.FC<MenuBarProps> = ({
  shrinkMenuRef,
  handleShrink,
  handleUpShrink,
  promotion,
  selectThumbnail,
  setPromotion,
  handleChange,
  errors,
}) => {
  const handleDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    v?: number | null
  ) => {
    const { name, value } = event.target;
    const cpData = { ...promotion };
    if (cpData.rules) {
      if (name === "other") {
        cpData.rules[index][name] = value;
      } else if (
        "start_from" === name ||
        "point" === name ||
        "point_percentage" === name
      ) {
        cpData.rules[index][name] = v;
      }
    }
    setPromotion(cpData);
  };

  const handleAdd = () => {
    const cpData = { ...promotion };
    cpData.rules?.push({
      start_from: 0,
      point: 0,
      point_percentage: 0,
      other: "",
    });
    setPromotion(cpData);
  };

  const handleDelete = (index: number) => {
    const cpData = { ...promotion };
    cpData.rules?.splice(index, 1);
    setPromotion(cpData);
  };
  return (
    <div
      ref={shrinkMenuRef}
      className="z-[5] absolute top-0 sm:top-auto h-1/3 sm:h-full bg-white right-0 w-full sm:w-1/4 transition-all duration-300"
    >
      <div className="absolute left-0 top-1 -ml-8 hidden sm:block">
        <label htmlFor="post_menu_shrink">
          <ArrowsRightLeftIcon className="h-6 w-6 cursor-pointer hover:text-grey-text-light" />
        </label>
        <input
          id="post_menu_shrink"
          type="checkbox"
          className="hidden"
          onChange={handleShrink}
        />
      </div>
      <div className="absolute left-0 -bottom-1 -mb-8 sm:hidden">
        <label htmlFor="post_menu_up_shrink">
          <ArrowsUpDownIcon className="h-6 w-6 cursor-pointer hover:text-grey-text-light" />
        </label>
        <input
          id="post_menu_up_shrink"
          type="checkbox"
          className="hidden"
          onChange={handleUpShrink}
        />
      </div>
      <div className="p-4 overflow-scroll h-full">
        <div className="flex flex-col gap-4">
          <div>
            <ImageContainer
              onSelect={selectThumbnail}
              multiple={false}
              service={FundMediaService}
              selected={promotion.media_id ? [promotion.media_id] : []}
            />
            {promotion.media_url && (
              <img
                src={promotion.media_url}
                className="w-full h-48 rounded-lg object-fill object-center mb-2"
                alt="Selected thumbnail"
              />
            )}
          </div>
          <InputText
            label="開始日"
            name="start"
            type="date"
            value={promotion.start || ""}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "start")}
          />
          <InputText
            label="終了日"
            type="date"
            name="end"
            value={promotion.end || ""}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "end")}
          />
          <div className="flex flex-col gap-2 divide-grey-boarder divide-y-2">
            {promotion.rules?.map((val, index) => (
              <div className="py-2" key={index}>
                <InputCurrency
                  label="Start from"
                  name="start_from"
                  value={val.start_from}
                  rightLabel="口から"
                  handleChange={(e, v) => {
                    handleDataChange(e, index, v);
                  }}
                  error=""
                />
                <InputCurrency
                  label="Point"
                  name="point"
                  rightLabel="pt"
                  value={val.point}
                  handleChange={(e, v) => {
                    handleDataChange(e, index, v);
                  }}
                  error=""
                />
                <InputFloat
                  label="Point percentage"
                  name="point_percentage"
                  rightLabel="%"
                  value={val.point_percentage}
                  handleChange={(e, v) => {
                    handleDataChange(e, index, v);
                  }}
                  error=""
                />
                <InputText
                  label="Other"
                  name="other"
                  value={val.other || ""}
                  handleChange={(e) => {
                    handleDataChange(e, index);
                  }}
                  error=""
                />
                <button
                  type="button"
                  onClick={() => {
                    handleDelete(index);
                  }}
                  className="m-2 w-full p-1 text-red-500"
                >
                  取り除く
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={handleAdd}
            className="m-2 w-full p-1 text-primary-dark"
          >
            ルールを追加
          </button>
        </div>
      </div>
    </div>
  );
};
