import React, { useState } from "react";
import { InputLayout } from "../layouts/InputLayout";
import { InputTextProps } from "./InputText";
import { classNames } from "../../utils";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

export const PasswordField: React.FC<
  Omit<Omit<InputTextProps, "type">, "rows">
> = ({
  label,
  name,
  value,
  handleChange,
  error,
  required = true,
  classname = "",
  parentClassName = "",
  labelClassName = "",
  attr = {},
  placeholder = "",
}) => {
  const [show, setShow] = useState(false);

  return (
    <InputLayout
      label={label}
      error={error}
      name={name}
      parentClassName={parentClassName}
      labelClassName={labelClassName}
    >
      <div className="relative">
        <input
          className={classNames(
            classname,
            "border p-1 rounded text-grey-text-main w-full",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
          type={show ? "text" : "password"}
          name={name}
          id={`id_${name}`}
          value={value}
          onChange={handleChange}
          required={required}
          placeholder={placeholder}
          {...attr}
        />
        <span
          onClick={() => setShow((prev) => !prev)}
          className="cursor-pointer absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
        >
          {show ? (
            <EyeSlashIcon className="h-6 w-6" />
          ) : (
            <EyeIcon className="h-6 w-6" />
          )}
        </span>
      </div>
    </InputLayout>
  );
};
