import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Column } from "react-table";
import { PromotionType } from "../../../types/fund";
import { urlDecodeObj, urlEncodeObj } from "../../../utils/query";
import { ReactTable } from "../../../components/ui/Table";
import { MainLayout } from "../../../components/layouts/MainLayout";
import { AddLink } from "../../../components/ui/AddLink";
import PromotionService from "../../../infrastructure/api/funds/promotion";
import { DeleteConfirmation } from "../../../components/ui/DeleteConfirmation";

export const PromotionList: React.FC = () => {
  const [promotions, setPromotions] = useState<
    PaginatedDataType<PromotionType>
  >({
    count: 0,
    data: [],
  });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(
    location.search || "page=0&limit=20"
  );

  const fetchData = useCallback(
    (page: number, l: number) => {
      const stringURL = urlEncodeObj({
        page: page,
        limit: l,
      });
      if (searchParams.toString() !== stringURL) {
        setSearchParams(stringURL);
      }
      new PromotionService()
        .getList(page, l)
        .then((data) => {
          setPromotions(data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [searchParams, setSearchParams]
  );

  const handleDelete = useCallback(
    (index: number, id?: number) => {
      if (id) {
        new PromotionService()
          .deletePromotion(id)
          .then(() => {
            const cpData = [...promotions.data];
            cpData.splice(index, 1);
            setPromotions((prevState) => ({
              count: prevState.count - 1,
              data: cpData,
            }));
          })
          .catch((err) => {
            alert(JSON.parse(err));
          });
      }
    },
    [promotions.data]
  );

  const memoData = useMemo(() => promotions.data, [promotions.data]);
  const columns: Column<PromotionType>[] = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        disableSortBy: true,
        Cell: (row) => (
          <div className="relative group">
            <span>{row.value}</span>
            <div className="z-10 hidden group-hover:flex text-sm divide-x-2">
              <Link
                className="text-blue-600 hover:text-blue-400 px-1"
                to={`${row.row.original.id}`}
              >
                Edit
              </Link>
              <DeleteConfirmation
                buttonClassName="text-red-600 hover:text-red-400 px-1"
                onDelete={() => {
                  handleDelete(row.row.index, row.row.original.id);
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [handleDelete]
  );

  const searchObj = urlDecodeObj(searchParams.toString());

  const memorizedTable = React.useMemo(
    () => (
      <ReactTable<PromotionType>
        columns={columns}
        data={memoData}
        dataCount={promotions.count}
        totalPage={Math.ceil(
          promotions.count / Number(searchObj["limit"] || 1)
        )}
        callback={fetchData}
        pageSizeOptions={[20, 50, 100, 300]}
        initialPageSize={Number(searchObj["limit"] || 20)}
        initialSort={searchObj["order_by"] || []}
      />
    ),
    [columns, fetchData, memoData, promotions.count, searchObj]
  );
  return (
    <MainLayout title="キャンペん一覧">
      <div className="w-full flex justify-end">
        <AddLink to="create" label="新規追加" />
      </div>
      {memorizedTable}
    </MainLayout>
  );
};
