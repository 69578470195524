import React from "react";
import { Modal } from "./Modal";
import { ModalActionLayout } from "../layouts/ModalActionLayout";
import { Button } from "./button/Button";
import { ButtonVariant } from "./button/constants";

type EditModalType = {
  button?: string | React.ReactElement;
  handleSave: () => Promise<void>;
  children: React.ReactNode;
  openCallback?: () => void;
  buttonClassName?: string;
  popoverClassName?: string;
  ref?: React.Ref<HTMLButtonElement>;
};

export const EditModal: React.FC<EditModalType> = ({
  button,
  handleSave,
  children,
  openCallback,
  buttonClassName,
  popoverClassName,
  ref,
}) => {
  return (
    <Modal
      buttonClassName={
        buttonClassName ? buttonClassName : "rounded bg-blue-500 p-1 text-white"
      }
      popoverClassName={
        popoverClassName
          ? popoverClassName
          : "fixed h-screen w-screen left-0 top-0 bg-gray-900/80 z-20 flex items-center justify-center"
      }
      openCallback={openCallback}
      button={button ? button : "編集"}
      ref={ref}
    >
      {(open) => (
        <ModalActionLayout>
          {children}
          <div className="w-full flex gap-1 my-2">
            <Button
              variant={ButtonVariant.Cancel}
              onClick={() => {
                open.close();
              }}
            />
            <Button
              variant={ButtonVariant.Save}
              onClick={() => {
                handleSave().then(() => {
                  open.close();
                });
              }}
            />
          </div>
        </ModalActionLayout>
      )}
    </Modal>
  );
};
