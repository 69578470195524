import React, {useEffect, useState} from "react";
import {MainLayout} from "../../../../components/layouts/MainLayout";
import {FastapiErrorT} from "../../../../utils/fastapi";
import {DocumentsModelingType} from "../../../../types/fund";
import DocumentsModelingService from "../../../../infrastructure/api/funds/documentsModeling";
import {TabLayout} from "../../../../components/layouts/TabLayout";
import {AddLink} from "../../../../components/ui/AddLink";
import {Link} from "react-router-dom";
import {PencilSquareIcon} from "@heroicons/react/20/solid";

const tabSlugs = [
  "nda",
  "anonymous_partnership_contract",
  "contract_agree_form",
  "ecommerce_details"
]

const DocumentsModelingList: React.FC = () => {
  const [baseDocuments, setDocuments] = useState<DocumentsModelingType[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("nda");

  useEffect(() => {
    new DocumentsModelingService(currentTab)
      .getList()
      .then((data) => {
        setDocuments(data);
      })
      .catch((error: FastapiErrorT) => {
        alert(error.detail);
      });
  }, [currentTab]);

  const handleTableClick = (index: number) => {
    if (tabSlugs[index]) setCurrentTab(tabSlugs[index]);
  }

  return (
    <MainLayout title="Document">
      <div className="flex justify-end w-full mb-3">
        <AddLink to={`${currentTab}/create/`} label={"Add"}/>
      </div>
      <TabLayout
        tabs={['NDA', 'Anonymous Partnership', 'contract Agree', 'ecommerce Details']}
        onClick={handleTableClick}
      >
        <div className="w-full">
          {baseDocuments.map((obj, index) => (
            <div>
              <div key={index} className="border-b text-base py-2 px-2 flex justify-between w-full">
                {obj.name}
                <Link to={`${currentTab}/create?documents_modeling_id=${obj.id}`}><PencilSquareIcon
                  className='h-6 w-6 fill-green-400'/></Link>
              </div>
            </div>
          ))
          }
        </div>
      </TabLayout>

    </MainLayout>
  )
}
export default DocumentsModelingList;