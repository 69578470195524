import React from "react";
import { DocumentBaseFieldsType } from "../../../../types/fund";
import { PlusIcon } from "@heroicons/react/20/solid";
import { DocumentsFieldBase } from "../../../../store/documents";

interface ContractItemProps {
  name: string;
  draft: boolean;
  url: string;
}

const ContractItem: React.FC<ContractItemProps> = ({ name, draft, url }) => {
  const textColor = draft ? "text-red-800" : "text-green-700";

  return (
    <li className={textColor}>
      <a href={url} target="_blank" rel="noreferrer" className="cursor-pointer">
        {name}
      </a>
    </li>
  );
};

interface ContractSectionProps {
  title: string;
  contracts: DocumentBaseFieldsType[];
  field: string;
  fundID: string;
  ndaUUID?: string;
}

const ContractSection: React.FC<ContractSectionProps> = ({
  title,
  contracts,
  field,
  fundID,
  ndaUUID
}) => {
  const hasNoContracts = contracts.length === 0;

  return (
    <li className="ml-5">
      <span className="flex gap-2 font-semibold">
        {title}
        {hasNoContracts && (
          <a
            href={`/funds/documents/${field}/create?${ndaUUID ? `nda_uuid=${ndaUUID}` : `fund_id=${fundID}`}`}
            target="_blank"
            rel="noreferrer"
          >
            <PlusIcon className="h-6 w-6 text-green-500" />
          </a>
        )}
      </span>
      <ol className="ml-5 list-decimal">
        {contracts.map((contract) => (
          <ContractItem
            key={contract.uuid}
            url={`/funds/documents/${field}/create?${field}=${contract.uuid}`}
            draft={contract.draft === undefined ? true : contract.draft}
            name={contract.name || ""}
          />
        ))}
      </ol>
    </li>
  );
};

interface ContractComponentProps {
  doc: DocumentsFieldBase;
  fundID: string;
}

const ContractComponent: React.FC<ContractComponentProps> = ({
  doc,
  fundID,
}) => {
  return (
    <div>
      <ul className="list-decimal">
        <ContractSection
          title="契約成立前書面"
          contracts={doc.nda}
          field="nda"
          fundID={fundID}
        />
        {
          doc.nda.length > 0 &&
          <>
              <ContractSection
                title="契約成立時書面"
                contracts={doc.contract_agree_form}
                field="contract_agree_form"
                fundID={fundID}
                ndaUUID={doc.nda[0].uuid}
              />

              <ContractSection
                title="匿名組合契約書"
                contracts={doc.anonymous_partnership_contract}
                field="anonymous_partnership_contract"
                fundID={fundID}
                ndaUUID={doc.nda[0].uuid}
              />

              <ContractSection
                title="電子取引業務に係る重要事項"
                contracts={doc.ecommerce_details}
                field="ecommerce_details"
                fundID={fundID}
                ndaUUID={doc.nda[0].uuid}
              />
          </>
        }
      </ul>
    </div>
  );
};

export default ContractComponent;
