import React, { useEffect, useState } from "react";
import {
  DocumentBaseFieldsType,
  FundStepProps,
  FundType,
} from "../../../../types/fund";
import { useDocuments } from "../../../../hooks/useDocuments";
import StepService from "../../../../infrastructure/api/funds/step";
import { DocumentsFieldBase } from "../../../../store/documents";
import ContractComponent from "./ContractComponent";


export const CreateDocuments: React.FC<FundStepProps> = (props) => {
  const { inputValues } = props;
  const { documents } = useDocuments();
  const [doc, setDocs] = useState<DocumentsFieldBase>({
    nda: [],
    anonymous_partnership_contract: [],
    ecommerce_details: [],
    contract_agree_form: [],
  });

  useEffect(() => {
    if (inputValues.id) {
      new StepService().seventhStepGet(inputValues.id).then((data) => {
        setDocs(data);
      });
    }
  }, [inputValues.id]);

  function mergeArrays(
    arr1: DocumentBaseFieldsType[],
    arr2: DocumentBaseFieldsType[]
  ): DocumentBaseFieldsType[] {
    const mergedArray: DocumentBaseFieldsType[] = [...arr2];

    for (const item1 of arr1) {
      const index = mergedArray.findIndex((item2) => item2.uuid === item1.uuid);

      if (index !== -1) {
        // Replace item in mergedArray with item from arr1
        mergedArray[index] = item1;
      } else {
        // Push item from arr1 into mergedArray
        mergedArray.push(item1);
      }
    }
    return mergedArray;
  }

  useEffect(() => {
    if (documents.fund_id === inputValues.id) {
      const cpData = { ...doc };
      cpData.nda = mergeArrays(documents.nda, doc?.nda || []);
      cpData.contract_agree_form = mergeArrays(
        documents.contract_agree_form,
        doc?.contract_agree_form || []
      );
      cpData.anonymous_partnership_contract = mergeArrays(
        documents.anonymous_partnership_contract,
        doc?.anonymous_partnership_contract || []
      );
      cpData.ecommerce_details = mergeArrays(
        documents.ecommerce_details,
        doc?.ecommerce_details || []
      );
      setDocs(cpData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.changed]);

  return <ContractComponent doc={doc} fundID={inputValues.id || ""} />;
};
