import React from "react";
import {errorConverter} from "../../../../utils/fastapi";
import {FundStepProps, FundType} from "../../../../types/fund";
import {InputCurrency} from "../../../../components/forms/InputCurrency";
import {InputText} from "../../../../components/forms/InputText";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import {Select} from "../../../../components/forms/Select";
import {recruitmentTypeOptions} from "../../data";
import FundService from "../../../../infrastructure/api/funds/fund";
import {convertUtcToLocal} from "../../../../utils/date";


export const Recruitment: React.FC<
  FundStepProps & { setInputValues: React.Dispatch<React.SetStateAction<FundType>>; }
> = ({inputValues, handleChange, errors, setInputValues}) => {

  const handleAdd = () => {
    setInputValues((prev) => ({
      ...prev,
      recruitments: [
        ...(prev.recruitments || []),
        {
          units: 0,
          booked_units: 0,
          fund_id: inputValues.id || null,
          start: null,
          end: null,
          result_date: null,
          recruitment_type: 'first_come_basis',
        }
      ]
    }))
  }

  const handleRemove = (index: number) => {
    const cpData = [...(inputValues.recruitments || [])];
    if (cpData[index].id) {
      cpData[index].deleted = true;
    } else {
      cpData.splice(index, 1);
    }
    setInputValues((prev) => ({...prev, recruitments: cpData}));
  }

  const handleClick = () => {
    new FundService().updateRecruitment(inputValues.recruitments || [])
      .then((data) => {
        console.log(data);
      })
  }

  return (
    <div className="grid grid-flow-row sm:grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-5">
      <div className="col-span-3">
        <InputCurrency
          label="最低出資口数"
          name="min_num_units"
          value={inputValues.min_num_units}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "min_num_units")}
          rightLabel="口"
        />
        <InputCurrency
          label="最高出資口数"
          name="max_num_units"
          value={inputValues.max_num_units}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "max_num_units")}
          rightLabel="口"
        />
      </div>

      <div className="flex flex-col divide-y col-span-3 justify-center items-center">
        {inputValues.recruitments &&
          inputValues.recruitments
            .map((recruitment, index) => {
              if (recruitment.deleted) return <React.Fragment key={index}/>
              return (
                <div
                  key={index}
                  className="flex w-full flex-col sm:flex-row gap-2 items-end py-2"
                >
                  <Select
                    value={recruitment.recruitment_type}
                    handleChange={handleChange}
                    label="recruitment type"
                    name="recruitment_type"
                    error={errorConverter(errors?.detail, "recruitment_type",  index)}
                    options={recruitmentTypeOptions}
                    valKey="value"
                    labelKey="label"
                    attr ={{
                      "data-list": "recruitments",
                      "data-index": index,
                    }}
                  />
                  <InputCurrency
                    label="units"
                    name="units"
                    value={recruitment.units}
                    handleChange={handleChange}
                    error={errorConverter(errors?.detail, "units", index)}
                    rightLabel="口"
                    attr={{
                      "data-list": "recruitments",
                      "data-index": index,
                    }}
                  />
                  <InputCurrency
                    label="booked units"
                    name="booked_units"
                    value={recruitment.booked_units}
                    handleChange={handleChange}
                    error={errorConverter(errors?.detail, "booked_units", index)}
                    rightLabel="口"
                    attr={{
                      "data-list": "recruitments",
                      "data-index": index,
                    }}
                  />
                  <InputText
                    label="計算開始日"
                    name="start"
                    type="datetime-local"
                    value={convertUtcToLocal(recruitment.start)}
                    handleChange={handleChange}
                    error={errorConverter(errors?.detail, "start", index)}
                    attr={{
                      "data-list": "recruitments",
                      "data-index": index,
                    }}
                  />
                  <InputText
                    label="計算終了日"
                    name="end"
                    type="datetime-local"
                    value={convertUtcToLocal(recruitment.end)}
                    handleChange={handleChange}
                    error={errorConverter(errors?.detail, "end", index)}
                    attr={{
                      "data-list": "recruitments",
                      "data-index": index,
                    }}
                  />
                  {
                    recruitment.recruitment_type === "drawing" &&
                    <InputText
                      label="分配金振込日"
                      name="result_date"
                      type="datetime-local"
                      value={convertUtcToLocal(recruitment.result_date)}
                      handleChange={handleChange}
                      error={errorConverter(errors?.detail, "result_date", index)}
                      attr={{
                        "data-list": "recruitments",
                        "data-index": index,
                      }}
                    />
                  }
                  <MinusIcon className="h-6 w-8 text-red-500 cursor-pointer" onClick={()=>{ handleRemove(index)}}/>
                </div>
              );

            })}
        <div className='flex flex-col items-center cursor-pointer hover:text-gray-500' onClick={handleAdd}>
          <PlusIcon className="h-8 w-8"/>
          <small>募集期間追加</small>
        </div>
      </div>
      <button onClick={handleClick} type="button">button</button>
    </div>
  );
}
