import axios from "axios";

interface ZIPCloudResultType {
  address1: string;
  address2: string;
  address3: string;
  kana1: string;
  kana2: string;
  kana3: string;
  prefcode: string;
  zipcode: string;
}

interface ZIPCloudType {
  message: string | null;
  status: number;
  results: ZIPCloudResultType[] | null;
}

class ExternalService {
  getAddress(zipcode: string) {
    return axios
      .get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipcode}`)
      .then<ZIPCloudType>((res) => {
        if (res.data.status === 200) {
          return res.data;
        }
        throw res.data;
      });
  }
}

export default ExternalService;
