import React, { useRef } from "react";
import Editor from "../../../components/ui/Editor";
import logo from "../../../assets/images/logo.png";
import { MenuBar } from "./MenuBar";
import { PromotionType } from "../../../types/fund";
import { ImageType } from "../../../types/media";
import { FastapiErrorT } from "../../../utils/fastapi";

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

export interface PromotionEditorProps {
  promotion: PromotionType;
  setPromotion: React.Dispatch<React.SetStateAction<PromotionType>>;
  selectThumbnail: (image: ImageType | null | ImageType[]) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDraft: () => void;
  handlePublish: () => void;
  errors?: FastapiErrorT;
}

const TextButton: React.FC<ButtonProps> = ({ text, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className="p-2 border border-transparent hover:border-secondary-main rounded text-sm text-secondary-main"
  >
    {text}
  </button>
);

const ContainedButton: React.FC<ButtonProps> = ({ text, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="bg-secondary-main p-2 border-secondary-main border rounded text-white text-sm"
  >
    {text}
  </button>
);

export const PromotionEditor = React.memo((props: PromotionEditorProps) => {
  const shrinkMenuRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);

  const handleShrink = () => {
    shrinkMenuRef.current?.classList.toggle("w-full");
    shrinkMenuRef.current?.classList.toggle("sm:w-1/4");
    shrinkMenuRef.current?.classList.toggle("p-4");
    shrinkMenuRef.current?.classList.toggle("w-0");
    editorRef.current?.classList.toggle("sm:mr-[25%]");
    editorRef.current?.classList.toggle("mr-0");
  };
  const handleUpShrink = () => {
    shrinkMenuRef.current?.classList.toggle("h-1/3");
    shrinkMenuRef.current?.classList.toggle("p-4");
    shrinkMenuRef.current?.classList.toggle("h-0");
    editorRef.current?.classList.toggle("mt-[40%]");
    editorRef.current?.classList.toggle("mt-0");
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full sticky top-0 border border-grey-boarder p-2 bg-white z-10 flex justify-between items-center">
        <button>
          <img src={logo} className="h-10 w-18" alt="selected thumnail" />
        </button>
        <div className="flex justify-end items-center gap-2">
          <TextButton
            text={
              props.promotion.status === "draft"
                ? "下書き保存"
                : "下書きへ切り替え"
            }
            onClick={props.handleDraft}
          />
          <TextButton text="プレビュー" />
          <ContainedButton
            text={props.promotion.status === "draft" ? "公開" : "更新"}
            onClick={props.handlePublish}
          />
        </div>
      </div>
      <div className="z-20 flex-1 flex flex-col overflow-hidden sm:flex-row justify-between divide-grey-boarder w-full relative divide-y sm:divide-y-0 divide-y-reverse divide-x-0 sm:divide-x">
        <div
          className="w-full relative p-8 overflow-scroll mt-[40%] mr-0 sm:mt-0 sm:mr-[25%] transition-all duration-300"
          ref={editorRef}
        >
          <div className="ce-block__content">
            <input
              type="text"
              name="title"
              placeholder="タイトル"
              value={props.promotion.title}
              className="text-4xl w-full font-bold outline-0 my-6"
              onChange={props.handleChange}
            />
          </div>
          <Editor
            handleChange={(data) => {
              props.setPromotion((prev) => ({ ...prev, content: data }));
            }}
            data={props.promotion.content}
          />
        </div>
        <MenuBar
          {...props}
          handleShrink={handleShrink}
          handleUpShrink={handleUpShrink}
          shrinkMenuRef={shrinkMenuRef}
        />
      </div>
    </div>
  );
});
