import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  DistributionType,
  FundType,
} from "../../../../types/fund";
import {FastapiErrorT} from "../../../../utils/fastapi";
import {fundInitial} from "../../data";

import {FundImages} from "../components/FundImages";
import {ObjectsInformation} from "../components/ObjectsInformation";
import {Operators} from "../components/Operators";
import {Promotions} from "../components/Promotions";
import {useNavigate, useParams} from "react-router-dom";
import FundService from "../../../../infrastructure/api/funds/fund";
import {CreateDocuments} from "../create/CreateDocuments";
import {MainLayout} from "../../../../components/layouts/MainLayout";
import {TabsLayout} from "../../../../components/layouts/TabsLayout";
import {MajorFields} from "./MajorFields";
import {EditSummary} from "./EditSummary";
import {Recruitment} from "./Recruitment";
import {convertLocalToUtc} from "../../../../utils/date";

const FundEdit: React.FC = () => {
  const [inputValues, setInputValues] = useState<FundType>(fundInitial);
  const [errors, setErrors] = useState<FastapiErrorT>();
  const currentRef = useRef<number>(0);
  const [loading, setLoading] = useState(true);
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      new FundService()
        .getFund(id)
        .then((data) => {
          const steps = data.steps ? data.steps : inputValues.steps;
          const stepsArr = Object.entries(steps);
          for (let i = 0; i < stepsArr.length; i++) {
            if (!stepsArr[i][1]) {
              currentRef.current = i;
              break;
            }
          }
          setInputValues((prev) => ({...prev, ...data, steps: steps}));
        })
        .catch((err) => {
          alert(err.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValues.total_investment || inputValues.investment_amount) {
      const subordinatedInvestment = inputValues.total_investment
        ? inputValues.total_investment * 0.1
        : null;
      const amountRaised = subordinatedInvestment
        ? (inputValues.total_investment as number) - subordinatedInvestment
        : null;
      const investmentAmount = inputValues.investment_amount;

      setInputValues((prevState) => ({
        ...prevState,
        amount_raised: amountRaised,
        subordinated_investment: subordinatedInvestment,
        number_of_units:
          investmentAmount && amountRaised
            ? (amountRaised * 10000) / investmentAmount
            : null,
      }));
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        amount_raised: null,
        subordinated_investment: null,
        number_of_units: null,
      }));
    }
  }, [
    inputValues.total_investment,
    inputValues.investment_amount,
    setInputValues,
  ]);

  useEffect(() => {
    if (inputValues.total_distributions) {
      const distributions: DistributionType[] = [];
      for (let i = 0; i < inputValues.total_distributions; i++) {
        if (inputValues.distributions && inputValues.distributions.length > i) {
          distributions.push({...inputValues.distributions[i], order: i});
        } else {
          distributions.push({
            order: i,
            start: "",
            end: "",
            receive_date: "",
          });
        }
      }
      if (
        inputValues.distributions &&
        inputValues.distributions.length > distributions.length
      ) {
        for (
          let i = distributions.length;
          i < inputValues.distributions.length;
          i++
        ) {
          if (inputValues.distributions[i].id) {
            let cpDistribution = inputValues.distributions[i];
            cpDistribution.order = 404;
            distributions.push(cpDistribution);
          }
        }
      }
      setInputValues((prevState) => ({
        ...prevState,
        steps: {...prevState.steps, fundSecond: false},
        distributions: distributions,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues.total_distributions]);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      num: number | null = null
    ) => {
      let {name, value, type} = event.target;

      if (type === "datetime-local") {
        value = convertLocalToUtc(value);
      }
      
      const dataList = event.target.getAttribute("data-list");
      const dataIndex = parseInt(event.target.getAttribute("data-index") || "");
      if (dataList) {
        if (!isNaN(dataIndex)) {
          const cpData = {...inputValues};
          // @ts-ignore
          cpData[dataList][dataIndex][name] = value;
          setInputValues(cpData);
        }
      } else {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: num ? num : value,
        }));
      }
    },
    [inputValues]
  );

  if (loading) {
    return <div className="h-full"></div>;
  }

  const Tabs = [
    '概要',
    '募集設定',
    'ファンド写真',
    '物件情報',
    '運営者',
    'キャンペーン',
    '関連書面',
    '重要な項目',
  ]
  return (
    <MainLayout>
      <TabsLayout tabs={Tabs}>
        <EditSummary
          setInputValues={setInputValues}
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <Recruitment
          setInputValues={setInputValues}
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <FundImages
          setInputValues={setInputValues}
          inputValues={inputValues}
        />
        <ObjectsInformation
          setInputValues={setInputValues}
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <Operators
          inputValues={inputValues}
          setInputValues={setInputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <Promotions
          setInputValues={setInputValues}
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <CreateDocuments
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
        <MajorFields
          inputValues={inputValues}
          errors={errors}
          handleChange={handleChange}
        />
      </TabsLayout>
    </MainLayout>
  );
};

export default FundEdit;
