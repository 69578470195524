import "./local";

import { IModuleConf } from "@wangeditor/editor";
import { renderImageConf } from "./render-elem";
import elemToHtmlConf from "./elem-to-html";
import { parseHtmlConf } from "./parse-elem-html";
import {
  imageFloatLeftMenuConf,
  imageFloatRightMenuConf,
  imageFloatNoneMenuConf, imageAbsoluteMenuConf,
} from "./menu";

const module: Partial<IModuleConf> = {
  renderElems: [renderImageConf],
  elemsToHtml: [elemToHtmlConf],
  parseElemsHtml: [parseHtmlConf],
  menus: [
    imageFloatLeftMenuConf,
    imageFloatRightMenuConf,
    imageFloatNoneMenuConf,
    imageAbsoluteMenuConf
  ],
};

export default module;
