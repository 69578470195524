import React from "react";
import DocumentComponent from "../components/DocumentComponent";
import { useParams } from "react-router-dom";
import {
  NDAType,
  FundType,
  AnonymousPartnershipContractType,
  ContractAgreeFormType,
  ECommerceDetailsType,
} from "../../../types/fund";

const getElement = (
  key:
    | "nda"
    | "anonymous_partnership_contract"
    | "contract_agree_form"
    | "ecommerce_details"
) => {
  switch (key) {
    case "nda":
      return (
        <DocumentComponent<NDAType, FundType>
          fieldName={key}
          defaultValue={{
            data: {
              property_fields: [
                { category: "", data: [{ label: "", value: "" }] },
              ],
            },
          }}
          title="契約成立前書面を作成"
        />
      );
    case "anonymous_partnership_contract":
      return (
        <DocumentComponent<AnonymousPartnershipContractType, NDAType>
          fieldName={key}
          defaultValue={{}}
          title="匿名組合契約書を作成"
        />
      );
    case "contract_agree_form":
      return (
        <DocumentComponent<ContractAgreeFormType, NDAType>
          fieldName={key}
          defaultValue={{}}
          title="契約成立時書面を作成"
        />
      );
    case "ecommerce_details":
      return (
        <DocumentComponent<ECommerceDetailsType, NDAType>
          fieldName={key}
          defaultValue={{}}
          title="電子取引業務に係る重要事項を作成"
        />
      );
  }
};

const DocumentCreate: React.FC = () => {
  const { slug } = useParams();
  if (
    slug === "nda" ||
    slug === "anonymous_partnership_contract" ||
    slug === "contract_agree_form" ||
    slug === "ecommerce_details"
  ) {
    return getElement(slug);
  }
  return <></>;
};

export default DocumentCreate;
