import React from "react";

interface FormButtonsProps {
  handleCancel?: () => void;
}

export const FormButtons: React.FC<FormButtonsProps> = ({ handleCancel }) => (
  <div className="flex w-full my-2 gap-2">
    <button
      type="button"
      className="flex-1 p-2 bg-grey-boarder text-white rounded"
      onClick={handleCancel}
    >
      戻る
    </button>
    <button
      type="submit"
      className="flex-1 p-2 bg-primary-dark text-white rounded"
    >
      保存
    </button>
  </div>
);
