import React, {useEffect} from 'react';
import AuthService from "../infrastructure/api/company/auth";

const GmoVerification: React.FC = () => {
	// get code and state query params
	const code = new URLSearchParams(window.location.search).get('code');
	const state = new URLSearchParams(window.location.search).get('state');
	const authService = new AuthService();

	// async and await call to get access token on mount
	useEffect(() => {
		const verifyGmo = async () => {
			if (code && state) {
				const response = await authService.verifyGmo(code, state);
			}
		}

		(async () => {
			await verifyGmo();
		})();

	}, [code, state]);

	return (
		<div>
			<h1>GMO Verification</h1>
		</div>
	)
}

export default GmoVerification;