import React, { ChangeEvent, useEffect, useState } from "react";
import PostService from "../../infrastructure/api/post/post";
import { Subtract } from "utility-types";
import { useParams } from "react-router-dom";
import { FastapiErrorT } from "../../utils/fastapi";
import CategoryService from "../../infrastructure/api/post/category";
import TagService from "../../infrastructure/api/post/tag";
import { CategoryCreateType, CategoryType } from "../../types/category";
import { PostType } from "../../types/post";
import { ImageType } from "../../types/media";
import { TagCreateType, TagType } from "../../types/tag";

type WithEditProps = {
  postType: "news" | "blog";
};

const initialPost: PostType = {
  author_id: 11,
  content: undefined,
  title: "",
  slug: "",
  status: "draft",
  type: "blog",
  tag_ids: [],
  category_id: null,
  media_id: null,
  media_url: null,
  excerpt: "",
};

export interface InjectedPostProps extends WithEditProps {
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  handlePublish: () => void;
  handleDraft: () => void;
  post: PostType;
  setPost: React.Dispatch<React.SetStateAction<PostType>>;
  selectThumbnail: (image: ImageType | null | ImageType[]) => void;
  errors: FastapiErrorT;
  categories: CategoryType[];
  updateCategory: (obj: CategoryType, index: number) => Promise<void>;
  editingCategory: CategoryType;
  setEditingCategory: React.Dispatch<
    React.SetStateAction<
      CategoryType | CategoryCreateType | TagType | TagCreateType
    >
  >;
  optionalErrors: FastapiErrorT;
  deleteCategory: (id: number, index: number) => void;
  setOptionalErrors: React.Dispatch<React.SetStateAction<FastapiErrorT | null>>;
  createCategory: () => Promise<void>;
  tags: TagType[];
  updateTag: (obj: TagType, index: number) => Promise<void>;
  deleteTag: (id: number, index: number) => void;
  createTag: () => Promise<void>;
}

export const makePost =
  <P extends InjectedPostProps>(
    Component: React.ComponentType<P>
  ): React.FC<Subtract<P, InjectedPostProps> & WithEditProps> =>
  ({ postType, ...props }: WithEditProps) => {
    const { id } = useParams<{ id: string }>();
    const [post, setPost] = useState<PostType>({
      ...initialPost,
      type: postType,
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<FastapiErrorT | null>();
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [tags, setTags] = useState<TagType[]>([]);
    const [editingCategory, setEditingCategory] = useState<
      CategoryType | CategoryCreateType | TagType | TagCreateType
    >(null!);
    const [optionalErrors, setOptionalErrors] =
      useState<FastapiErrorT | null>();

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setPost((prev) => ({ ...prev, [name]: value }));
    };

    const getCategories = () => {
      new CategoryService()
        .getList(postType)
        .then((data) => {
          setCategories(data);
        })
        .catch((err) => {
          const error = JSON.parse(err);
          alert(error?.detail);
        });
    };

    const getTags = () => {
      new TagService()
        .getList(postType)
        .then((data) => {
          setTags(data);
        })
        .catch((err) => {
          const error = JSON.parse(err);
          alert(error?.detail);
        });
    };

    useEffect(() => {
      if (id) {
        new PostService()
          .getPost(id)
          .then((data) => {
            setPost(data);
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
      getCategories();
      getTags();
    }, [id]);

    const fetchCreate = (data: PostType) => {
      new PostService()
        .createPost(data)
        .then((data) => {
          setPost(data);
          errors && setErrors(null);
        })
        .catch((err) => {
          setErrors(JSON.parse(err));
        });
    };

    const fetchUpdate = (data: PostType) => {
      const ID = id ? id : data.id;
      if (ID) {
        new PostService()
          .updatePost(ID, data)
          .then((data) => {
            setPost(data);
            errors && setErrors(null);
          })
          .catch((err) => {
            setErrors(JSON.parse(err));
          });
      }
    };
    const handlePublish = () => {
      const cpPost = { ...post };
      cpPost.status = "published";
      if (post.id) {
        fetchUpdate(cpPost);
      } else {
        if (!cpPost.slug) {
          cpPost.slug = cpPost.title;
        }
        fetchCreate(cpPost);
      }
    };

    const handleDraft = () => {
      const cpPost = { ...post };
      cpPost.status = "draft";
      if (post.id) {
        fetchUpdate(cpPost);
      } else {
        fetchCreate(cpPost);
      }
    };
    const selectThumbnail = (image: ImageType | null) => {
      const data = image
        ? { media_url: image.url, media_id: image.id }
        : { media_id: null, media_url: null };
      setPost((prev) => ({
        ...prev,
        ...data,
      }));
    };

    const updateCategory = (obj: CategoryType, index: number) => {
      return new CategoryService()
        .updateCategory(obj.id, editingCategory)
        .then((data) => {
          const cpCategories = [...categories];
          cpCategories[index] = data;
          setCategories(cpCategories);
          return data;
        })
        .catch((error) => {
          setOptionalErrors(JSON.parse(error));
          throw error;
        });
    };

    const createCategory = () => {
      return new CategoryService()
        .createCategory(editingCategory)
        .then((data) => {
          const cpCategories = [data, ...categories];
          setCategories(cpCategories);
          return data;
        })
        .catch((error) => {
          setOptionalErrors(JSON.parse(error));
          throw error;
        });
    };

    const deleteCategory = (id: number, index: number) => {
      return new CategoryService()
        .deleteCategory(id)
        .then(() => {
          const cpCategories = [...categories];
          cpCategories.splice(index, 1);
          setCategories(cpCategories);
        })
        .catch((error) => {
          alert(JSON.parse(error));
        });
    };

    const updateTag = (obj: CategoryType, index: number) => {
      return new TagService()
        .updateTag(obj.id, editingCategory)
        .then((data) => {
          const cpTags = [...tags];
          cpTags[index] = data;
          setTags(cpTags);
          return data;
        })
        .catch((error) => {
          setOptionalErrors(JSON.parse(error));
          throw error;
        });
    };

    const createTag = () => {
      return new TagService()
        .createTag(editingCategory)
        .then((data) => {
          const cpTags = [data, ...tags];
          setTags(cpTags);
          return data;
        })
        .catch((error) => {
          setOptionalErrors(JSON.parse(error));
          throw error;
        });
    };

    const deleteTag = (id: number, index: number) => {
      return new TagService()
        .deleteTag(id)
        .then(() => {
          const cpTags = [...tags];
          cpTags.splice(index, 1);
          setTags(cpTags);
        })
        .catch((error) => {
          alert(JSON.parse(error));
        });
    };

    return (
      <>
        {loading ? (
          <></>
        ) : (
          <Component
            {...(props as P)}
            handlePublish={handlePublish}
            handleChange={handleChange}
            post={post}
            postType={postType}
            selectThumbnail={selectThumbnail}
            setPost={setPost}
            handleDraft={handleDraft}
            errors={errors}
            categories={categories}
            updateCategory={updateCategory}
            editingCategory={editingCategory}
            setEditingCategory={setEditingCategory}
            optionalErrors={optionalErrors}
            deleteCategory={deleteCategory}
            setOptionalErrors={setOptionalErrors}
            createCategory={createCategory}
            tags={tags}
            createTag={createTag}
            updateTag={updateTag}
            deleteTag={deleteTag}
          />
        )}
      </>
    );
  };
