import { AxiosInstance } from "axios";
import { CompanyReadType, CompanyType } from "../../../types/company";

class CompanyService {
  private axios;
  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  get() {
    return this.axios
      .get<CompanyReadType>("/api/v1/admin/company/")
      .then<CompanyReadType>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
  update(data: CompanyType) {
    return this.axios
      .put<CompanyReadType>(`/api/v1/admin/company/`, data)
      .then<CompanyReadType>((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
}

export default CompanyService;
