import React, { useRef } from "react";
import Editor from "../Editor";
import { InjectedPostProps } from "../../../hoc/post/makePost";
import logo from "../../../assets/images/logo.png";
import { MenuBar } from "./MenuBar";
import { ContainedButton, TextButton } from "../button/Button";

export const PostEditor = React.memo((props: InjectedPostProps) => {
  const shrinkMenuRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const handleShrink = () => {
    shrinkMenuRef.current?.classList.toggle("w-full");
    shrinkMenuRef.current?.classList.toggle("sm:w-1/4");
    shrinkMenuRef.current?.classList.toggle("p-4");
    shrinkMenuRef.current?.classList.toggle("w-0");
    editorRef.current?.classList.toggle("sm:mr-[25%]");
    editorRef.current?.classList.toggle("mr-0");
  };
  const handleUpShrink = () => {
    shrinkMenuRef.current?.classList.toggle("h-1/3");
    shrinkMenuRef.current?.classList.toggle("p-4");
    shrinkMenuRef.current?.classList.toggle("h-0");
    editorRef.current?.classList.toggle("mt-[40%]");
    editorRef.current?.classList.toggle("mt-0");
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full sticky top-0 border border-grey-boarder p-2 bg-white z-10 flex justify-between items-center">
        <button>
          <img src={logo} className="h-10 w-18" alt="selected thumnail" />
        </button>
        <div className="flex justify-end items-center gap-2">
          <TextButton
            text={
              props.post.status === "draft" ? "下書き保存" : "下書きへ切り替え"
            }
            onClick={props.handleDraft}
          />
          <TextButton text="プレビュー" />
          <ContainedButton
            text={props.post.status === "draft" ? "公開" : "更新"}
            onClick={props.handlePublish}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col z-20 overflow-hidden sm:flex-row justify-between divide-grey-boarder w-full relative divide-y sm:divide-y-0 divide-y-reverse divide-x-0 sm:divide-x">
        <div
          className="w-full relative p-8 overflow-scroll mt-[40%] mr-0 sm:mt-0 sm:mr-[25%] transition-all duration-300"
          ref={editorRef}
        >
          <div className="ce-block__content">
            <input
              type="text"
              name="title"
              placeholder="タイトル"
              value={props.post.title}
              className="text-4xl w-full font-bold outline-0 my-6"
              onChange={props.handleChange}
            />
          </div>
          <Editor
            handleChange={(data) => {
              props.setPost((prev) => ({ ...prev, content: data }));
            }}
            data={props.post.content}
          />
        </div>
        <MenuBar
          {...props}
          handleShrink={handleShrink}
          handleUpShrink={handleUpShrink}
          shrinkMenuRef={shrinkMenuRef}
        />
      </div>
    </div>
  );
});
