import {
  Accessor,
  Column,
  ColumnGroup,
  HeaderProps,
  Renderer,
} from "react-table";

function getWidth(text: string | number | boolean) {
  const fontSize = "16px";
  const newNode = document.createElement("div");
  newNode.id = "check-width";
  newNode.style.fontSize = fontSize;
  newNode.style.whiteSpace = "nowrap";
  newNode.style.width = "auto";
  newNode.style.height = "auto";
  newNode.style.position = "fixed";
  newNode.style.visibility = "hidden";
  if (typeof text === "string") {
    newNode.innerHTML = text;
  } else {
    newNode.innerHTML = text.toString();
  }
  document.getElementById("root")?.appendChild(newNode);
  return newNode.clientWidth + 1;
}

function getColumnWidth<T extends TableData>(
  rows: T[],
  accessor:
    | string
    | number
    | symbol
    | Accessor<T>
    | (string & Accessor<T>)
    | undefined,
  headerText:
    | (Renderer<HeaderProps<T>> & string)
    | Renderer<HeaderProps<T>>
    | undefined
): [number, number] | undefined {
  if (typeof accessor === "string" && typeof headerText === "string") {
    const maxWidth = 150;
    const cellMaxWidth = Math.max(
      ...rows.map((row) =>
        getWidth((row[accessor as keyof T] as string | number | boolean) || "")
      ),
      getWidth(headerText)
    );
    const cellMinWidth = getWidth(headerText);
    return [Math.max(maxWidth, cellMaxWidth), Math.min(maxWidth, cellMinWidth)];
  }
  return undefined;
}

export function prepareColumnsForTable<T extends TableData>(
  columns: Column<T>[],
  rows: T[]
) {
  if (rows) {
    columns.forEach((obj, index) => {
      if ("columns" in obj) {
        (columns[index] as ColumnGroup<T>).columns = prepareColumnsForTable(
          obj.columns,
          rows
        );
      } else if ("accessor" in obj) {
        const maxMin = getColumnWidth<T>(rows, obj.accessor, obj.Header);
        if (maxMin) {
          columns[index].maxWidth = maxMin[0];
          columns[index].minWidth = maxMin[1];
        }
      }
    });
    return columns;
  }
  return [];
}
