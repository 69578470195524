import React from "react";
import {errorConverter} from "../../../../utils/fastapi";
import {InputText} from "../../../../components/forms/InputText";
import {FundStepProps, FundType} from "../../../../types/fund";
import {InputCurrency} from "../../../../components/forms/InputCurrency";
import {List} from "../create/CreateSummary";
import {InputFloat} from "../../../../components/forms/InputFloat";

export const MajorFields: React.FC<FundStepProps> = ({inputValues, handleChange, errors}) => {

  return (
    <div className="grid grid-flow-row sm:grid-cols-3 gap-x-3 gap-y-5">
      <div className="col-span-3 grid grid-cols-3 gap-5">
        <InputText
          label="ファンド名"
          name="name"
          parentClassName="col-span-1 lg:col-span-2"
          value={inputValues.name}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "name")}
        />
      </div>

      <div
        className="col-span-2 sm:col-span-2 grid grid-cols-2 gap-2 border-dotted border border-primary-dark p-2 my-4">
        <InputCurrency
          label="出資総額"
          name="total_investment"
          value={inputValues.total_investment}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "total_investment")}
        />
        <InputCurrency
          label="出資金"
          name="investment_amount"
          value={inputValues.investment_amount}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "investment_amount")}
          rightLabel="円/1口"
        />
        <div className="p-2 col-span-2">
          <ul className="grid grid-cols-1 sm:grid-cols-3 gap-1">
            <List
              label="出資総額"
              value={inputValues.amount_raised}
              lastText="万円"
            />
            <List
              label="劣後出資"
              value={inputValues.subordinated_investment}
              lastText="万円"
            />
            <List
              label="募集口数"
              value={inputValues.number_of_units}
              lastText="口"
            />
          </ul>
        </div>
      </div>
      <div className="col-span-3 grid grid-cols-3 gap-5 ">
        <InputFloat
          label="キャピタル利回り"
          name="capital_interest"
          value={inputValues.capital_interest}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "capital_interest")}
          rightLabel="%"
        />
        <InputFloat
          label="インカム利回り"
          name="income_interest"
          value={inputValues.income_interest}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "income_interest")}
          rightLabel="%"
        />
      </div>
      <div className="col-span-3 grid grid-cols-3 gap-5 ">
        <div className="flex items-end gap-1">
          <InputText
            label="運用開始日"
            type="date"
            name="operation_start"
            parentClassName="flex-1"
            value={inputValues.operation_start || ""}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "operation_start")}
          />
          <span className="text-xs">から</span>
        </div>
        <div className="flex items-end gap-1">
          <InputText
            label="運用終了日"
            name="operation_end"
            type="date"
            parentClassName="flex-1"
            value={inputValues.operation_end || ""}
            handleChange={handleChange}
            error={errorConverter(errors?.detail, "operation_end")}
          />
          <span className="text-xs">まで</span>
        </div>
      </div>
      <div className="col-span-2">
        <InputCurrency
          label="総分配回数"
          name="total_distributions"
          value={inputValues.total_distributions}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "total_distributions")}
          rightLabel="回"
        />
      </div>
      <div className="flex flex-col divide-y col-span-2">
    {inputValues.distributions &&
      inputValues.distributions
        .filter((obj) => obj.order !== 404)
        .map((distribution, index) => {
          return (
            <div
              key={index}
              className="flex w-full flex-col sm:flex-row gap-2 items-end py-2"
            >
              <span className="text-xl">
                {index + 1}
                <small className="text-xs">回目</small>
              </span>
              <InputText
                label="計算開始日"
                name="start"
                type="date"
                value={distribution.start}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
              <InputText
                label="計算終了日"
                name="end"
                type="date"
                value={distribution.end}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
              <InputText
                label="分配金振込日"
                name="receive_date"
                type="date"
                value={distribution.receive_date}
                handleChange={handleChange}
                error={errorConverter(errors?.detail, "distributions")}
                attr={{
                  "data-list": "distributions",
                  "data-index": index,
                }}
              />
            </div>
          );
        })}
  </div>

    </div>
  )
}