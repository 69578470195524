import axios from "axios";
import {FundType, RecruitmentsType} from "../../../types/fund";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class FundService {
  getList() {
    return axios
      .get<FundType[]>(API_URL + "/api/v1/admin/funds/fund/")
      .then<FundType[]>((res) => {
        return res.data;
      })
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }
  getFund(id: string | number) {
    return axios
      .get<FundType>(API_URL + `/api/v1/admin/funds/fund/${id}/`)
      .then<FundType>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

   updateRecruitment(data: RecruitmentsType[]) {
    return axios
      .put<RecruitmentsType[]>(API_URL + `/api/v1/admin/funds/recruitment/`, data,)
      .then<RecruitmentsType[]>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

}

export default FundService;
