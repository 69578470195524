import axios from "axios";
import { ImageType, NewImageType } from "../../../types/media";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class MediaService {
  getList() {
    return axios
      .get<ImageType[]>(API_URL + "/api/v1/admin/posts/media/")
      .then<ImageType[]>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  createImages(data: NewImageType[]) {
    const cpData = [...data];
    for (let i = 0; i < data.length; i++) {
      if (cpData[i].image) {
        let base64String = cpData[i].image as string;
        cpData[i].image = base64String.replace("data:", "").replace(/^.+,/, "");
      }
    }
    return axios
      .post<ImageType[]>(API_URL + "/api/v1/admin/posts/media/", { images: cpData })
      .then<ImageType[]>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  deleteImage(id: number) {
    return axios.delete(API_URL + `/api/v1/admin/posts/media/${id}/`).catch((error) => {
      throw error.request.response;
    });
  }
  updateImage(id: number, data: { name: string }) {
    return axios
      .patch<ImageType>(API_URL + `/api/v1/admin/posts/media/${id}/`, data)
      .then<ImageType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default MediaService;
