import {
  AnonymousPartnershipContractType,
  ContractAgreeFormType,
  ECommerceDetailsType,
  NDAType,
} from "../types/fund";
import exp from "constants";

interface NDAFieldsValueType {
  type: string;
  key: string;
  valueType?: "number" | "money";
  title?: string;
  label?: string;
}
export interface NDAFieldsType {
  [key: string]: NDAFieldsValueType;
}

export const DocumentFields: NDAFieldsType = {
  fund_name: {
    type: "auto",
    key: "name",
  },
  operation_start: {
    type: "auto",
    key: "operation_start",
  },
  operation_end: {
    type: "auto",
    key: "operation_end",
  },
  total_investment: {
    type: "auto",
    valueType: "money",
    key: "total_investment",
  },
  amount_raised: {
    type: "auto",
    valueType: "money",
    key: "amount_raised",
  },
  number_of_units: {
    type: "auto",
    valueType: "number",
    key: "number_of_units",
  },
  min_num_units: {
    type: "auto",
    valueType: "number",
    key: "min_num_units",
  },
  max_num_units: {
    type: "auto",
    valueType: "number",
    key: "max_num_units",
  },
  capital_interest: {
    type: "auto",
    key: "capital_interest",
  },
  income_interest: {
    type: "auto",
    key: "income_interest",
  },
  investment_amount: {
    type: "auto",
    valueType: "money",
    key: "investment_amount",
  },
  master_lease: {
    type: "input-currency",
    key: "master_lease",
  },
  schedule_sub_invest: {
    type: "input-text",
    key: "schedule_sub_invest",
  },
  price_of_target_estate: {
    type: "input-currency",
    key: "price_of_target_estate",
  },
  calc_desc_of_price: {
    type: "input-textarea",
    key: "calc_desc_of_price",
  },
  result_method_appraisal: {
    type: "input-textarea",
    key: "result_method_appraisal",
  },
  total_tenants: {
    type: "input-text",
    key: "total_tenants",
  },
  total_leased_area: {
    type: "input-text",
    key: "total_leased_area",
  },

  leasable_area: {
    type: "input-text",
    key: "leasable_area",
  },
  rental_occupancy_rate: {
    type: "input-textarea",
    key: "rental_occupancy_rate",
  },
  type_of_contract: {
    type: "input-textarea",
    key: "type_of_contract",
  },
  tenant_name: {
    type: "input-text",
    key: "tenant_name",
  },
  master_lease_contract_amount: {
    type: "input-currency",
    key: "master_lease_contract_amount",
  },
  rental_area: {
    type: "input-text",
    key: "rental_area",
  },
  contract_period: {
    type: "input-text",
    key: "contract_period",
  },
  contract_renew_methods: {
    type: "input-text",
    key: "contract_renew_methods",
  },
  deposit: {
    type: "input-currency",
    key: "deposit",
  },
  important_matters: {
    type: "input-textarea",
    key: "important_matters",
  },
  payment_status: {
    type: "input-textarea",
    key: "payment_status",
  },
  total_rental_business_cost: {
    type: "input-textarea",
    key: "total_rental_business_cost",
  },
  application_period_method: {
    type: "input-textarea",
    key: "application_period_method",
  },
  recruitment_invest_acquisition: {
    type: "input-textarea",
    key: "recruitment_invest_acquisition",
  },
  calc_period_start: {
    type: "input-date",
    key: "calc_period_start",
  },
  calc_period_finish: {
    type: "input-date",
    key: "calc_period_finish",
  },
  calc_period_month: {
    type: "input-text",
    key: "calc_period_month",
  },
  transfer_deadline: {
    type: "input-text",
    key: "transfer_deadline",
  },
  cpa_audit_status: {
    type: "input-textarea",
    key: "cpa_audit_status",
  },
  audit_scope: {
    type: "input-textarea",
    key: "audit_scope",
  },
  attached_doc: {
    type: "input-file",
    key: "attached_doc",
    title: "対象不動産に関する事項",
  },
  others: {
    type: "input-textarea",
    key: "others",
  },
  property_fields: {
    type: "category-list",
    key: "property_fields",
  },
};

export type DocumentsFieldBase = {
  nda: NDAType[];
  anonymous_partnership_contract: AnonymousPartnershipContractType[];
  contract_agree_form: ContractAgreeFormType[];
  ecommerce_details: ECommerceDetailsType[];
};

export type DocumentsContextFieldsType = {
  fund_id: string;
  changed: number;
} & DocumentsFieldBase;

export type PropertyType =
      | "nda"
      | "anonymous_partnership_contract"
      | "contract_agree_form"
      | "ecommerce_details"