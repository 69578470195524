import { useContext, useEffect } from "react";
import { DocumentsContext } from "../contexts/documents";
import {
  AnonymousPartnershipContractType,
  ContractAgreeFormType,
  ECommerceDetailsType,
  NDAType,
} from "../types/fund";
import {PropertyType} from "../store/documents";

export const useDocuments = () => {
  const [documents, setDocuments] = useContext(DocumentsContext);

  if (!DocumentsContext) {
    throw new Error("useDocuments should be used inside DocumentsProvider");
  }

  useEffect(() => {
    const handleStorageChange = async (event: StorageEvent) => {
      if (event.storageArea === localStorage) {
        if (
          event.key === "documents" &&
          event.newValue !== JSON.stringify(documents)
        ) {
          await setDocuments(JSON.parse(event.newValue || ""));
          localStorage.removeItem("documents");
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [documents, setDocuments]);

  const updateDocumentArray = (
    data:
      | ECommerceDetailsType
      | NDAType
      | ContractAgreeFormType
      | AnonymousPartnershipContractType,
    fund_id: string,
    property: PropertyType

  ) => {
    const cpData = { ...documents };
    cpData.changed = 1 + cpData.changed;
    if (fund_id === cpData.fund_id) {
      const i = cpData[property].findIndex((val) => val.uuid === data.uuid);
      if (i !== -1) {
        cpData[property][i] = data;
      } else {
        cpData[property].push(data);
      }
    } else {
      cpData.fund_id = fund_id;
      cpData[property].push(data);
    }
    setDocuments(cpData);
    localStorage.setItem("documents", JSON.stringify(cpData));
  };
  const setDocNDA = (data: NDAType, fund_id: string, property: PropertyType) => {
    updateDocumentArray(data, fund_id, property);
  };

  const cleanData = () => {
    localStorage.removeItem("documents");
  };

  const setDocAnonymous = (
    data: AnonymousPartnershipContractType,
    fund_id: string
  ) => {
    updateDocumentArray(data, fund_id, "anonymous_partnership_contract");
  };

  const setDocContract = (data: ContractAgreeFormType, fund_id: string) => {
    updateDocumentArray(data, fund_id, "contract_agree_form");
  };

  const setDocEcommerce = (data: ECommerceDetailsType, fund_id: string) => {
    updateDocumentArray(data, fund_id, "ecommerce_details");
  };

  return {
    documents,
    setDocNDA,
    setDocAnonymous,
    setDocContract,
    setDocEcommerce,
    cleanData,
  };
};
