import React from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";

interface AccordionLayoutProps<T> {
  title: string;
  children: React.ReactNode;
  index: number;
  activeIndex: T;
  setActiveIndex: React.Dispatch<React.SetStateAction<T>>;
  bg?: string;
}

const AccordionLayout = <T extends number | number[]>({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
  bg,
}: AccordionLayoutProps<T>) => {
  const handleSetIndex = (index: number) => {
    if (Array.isArray(activeIndex)) {
      const cpData = [...activeIndex];
      const i = cpData.findIndex((val) => val === index);
      if (i === -1) {
        cpData.push(index);
      } else {
        cpData.splice(i, 1);
      }
      setActiveIndex(cpData as T);
    } else {
      activeIndex !== index
        ? setActiveIndex(index as T)
        : setActiveIndex(-1 as T);
    }
  };
  return (
    <>
      <div
        onClick={() => handleSetIndex(index)}
        className={`flex justify-between p-2 mt-2 rounded items-center ${
          bg ? bg : "bg-white"
        }`}
      >
        <div className="flex">
          <div className="text-black font-bold">{title}</div>
        </div>
        <div className="flex items-center justify-center">
          {Array.isArray(activeIndex) ? (
            activeIndex.includes(index) ? (
              <MinusIcon className="w-8 h-8" />
            ) : (
              <PlusIcon className="w-8 h-8" />
            )
          ) : activeIndex === index ? (
            <MinusIcon className="w-8 h-8" />
          ) : (
            <PlusIcon className="w-8 h-8" />
          )}
        </div>
      </div>

      {((Array.isArray(activeIndex) && activeIndex.includes(index)) ||
        activeIndex === index) && (
        <div className="shadow-3xl rounded-xl shadow-cyan-500/50 p-4 my-2 bg-white">
          {children}
        </div>
      )}
    </>
  );
};

export default AccordionLayout;
