import axios from "axios";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class DocumentService<T> {
  private key: string;
  constructor(key: string) {
    this.key = key;
  }

  getList() {
    return axios
      .get<T>(API_URL + `/api/v1/admin/funds/${this.key}/`)
      .then<T>((res) => {
        return res.data;
      })
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }
  getDoc(id: string | number) {
    return axios
      .get<T>(API_URL + `/api/v1/admin/funds/${this.key}/${id}/`)
      .then<T>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

  updateOrCreate(data: T, draft: boolean = false) {
    return axios
      .put<T>(
        API_URL +
          `/api/v1/admin/funds${
            draft ? `/${this.key}/draft/` : `/${this.key}/`
          }`,
        data
      )
      .then<T>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

  getFundNDAList(fundID: string) {
    return axios
      .get<T[]>(API_URL + `/api/v1/admin/funds/nda/fund/${fundID}/`)
      .then<T[]>((res) => {
        return res.data;
      })
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }
}

export default DocumentService;
