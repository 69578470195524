import React from "react";
import { StepsObjType } from "../../../../types/fund";

interface CompletedButtonProps {
  next: () => void;
  back?: () => void;
  id: keyof StepsObjType;
  onSubmit: (step: keyof StepsObjType) => boolean;
}

export const CompleteButton: React.FC<CompletedButtonProps> = ({
  next,
  back,
  id,
  onSubmit,
}) => {
  return (
    <div
      className="flex w-full"
      style={
        back ? { justifyContent: "space-between" } : { justifyContent: "end" }
      }
    >
      {back && (
        <button
          type="button"
          className="p-2 bg-grey-bg rounded border"
          onClick={back}
        >
          戻る
        </button>
      )}
      <button
        type="button"
        className="p-2 bg-primary-dark rounded border text-white"
        onClick={() => {
          const res = onSubmit(id);
          if (res) {
            next();
          }
        }}
      >
        保存
      </button>
    </div>
  );
};
