export const isValidDateString = (dateTimeString: any) => {
  if (typeof dateTimeString === "string") {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}$/;

    return dateTimeRegex.test(dateTimeString);
  }
  return false;
};

export const isValidDateTimeString = (datetimeString: any) => {
  const regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,6}){0,2}\+\d{2}:\d{2}$/;
  return regex.test(datetimeString);
};


export const convertDateToJapanese = (dateStr: any) => {
  if (isValidDateString(dateStr)) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}年${month}月${day}日`;
  } else if (isValidDateTimeString(dateStr)) {
    const utcDatetime = new Date(dateStr);
    const tokyoDatetime = new Date(
      utcDatetime.toLocaleString("en-US", { timeZone: "Asia/Tokyo" })
    );

    const year = tokyoDatetime.getFullYear();
    const month = String(tokyoDatetime.getMonth() + 1).padStart(2, "0");
    const day = String(tokyoDatetime.getDate()).padStart(2, "0");
    const hour = String(tokyoDatetime.getHours()).padStart(2, "0");
    const minute = String(tokyoDatetime.getMinutes()).padStart(2, "0");
    const second = String(tokyoDatetime.getSeconds()).padStart(2, "0");

    return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`;
  }
  return dateStr;
};

export function convertUtcToLocal(utcDate: string | null) {
  if (!utcDate) return "";

  const localDate = new Date(utcDate); // UTC datetime
  return formatDateTimeWithOptionalSeconds(localDate);
}

export function convertLocalToUtc(localDate: string | null) {
  if (!localDate) return "";

  const utcDate = new Date(localDate); // Local datetime (without timezone)
  return utcDate.toISOString(); // Convert to UTC datetime string
}

function formatDateTimeWithOptionalSeconds(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  if (seconds !== '00') {
    formattedDate += `:${seconds}`;
    if (milliseconds !== '000') {
      formattedDate += `.${milliseconds}`;
    }
  }

  return formattedDate;
}