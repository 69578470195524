type FastapiErrorType = {
  loc: (string | number)[];
  msg: string;
  type: string;
};

export type FastapiErrorT = {
  detail: FastapiErrorType[] | string | undefined;
};

export const errorConverter = (
  errors: FastapiErrorType[] | string | undefined,
  field: string,
  index?: number
) => {
  /*
	This function will convert fastapi errors of field to string array.
	from:
		[
			"loc": ["body", "email"],
			"msg": "Field required",
			"type": "value_error.missing"
		]
	to:
		["Field required"]
	*/
  let errorList: string[] = [];
  if (!Array.isArray(errors)) {
    return [];
  }
  try {
    errors.forEach((value) => {
      if (index) {
        if (value.loc.includes(field) && value.loc.includes(index)) {
          errorList.push(value.msg);
        }
      } else if (value.loc.includes(field)) {
        errorList.push(value.msg);
      }
    });
  } catch {
    return [];
  }
  return errorList;
};
