import { h, VNode } from "snabbdom";
import {
  DomEditor,
  IDomEditor,
  SlateElement,
  SlateTransforms,
} from "@wangeditor/editor";
import { ImageElement } from "./custom-types";
import throttle from "lodash.throttle";
import $$, { Dom7Array } from "../utils/dom";
import $ from "dom7";

interface IImageSize {
  width?: string;
  height?: string;
  float?: string;
  position?: string;
}

function genContainerId(editor: IDomEditor, elemNode: SlateElement) {
  const { id } = DomEditor.findKey(editor, elemNode); // node 唯一 id
  return `w-e-image-container-${id}`;
}

function getContainerElem(containerId: string): Dom7Array {
    const $container = $$(`#${containerId}`);
    if ($container.length === 0)
      throw new Error("Cannot find image container elem");
    return $container;
  }

/**
 * 未选中时，渲染 image container
 */
function renderContainer(
  editor: IDomEditor,
  elemNode: SlateElement,
  imageVNode: VNode,
  imageInfo: IImageSize
): VNode {
  const { width, height, float, position } = imageInfo;

  const style: any = {};
  if (width) style.width = width;
  if (height) style.height = height;
  if (float !== "none") {
    style.float = float;
    style.position = "relative";
  }
  if (position === "absolute") {
    style.float = "none";
    style.position = position;
    style.left = "0";
    style.bottom = "0";
  }
  const containerId = genContainerId(editor, elemNode);
  if (position === "absolute" && !width && !height) {
    let $container: Dom7Array | null = null;
    $container = getContainerElem(containerId);
    const $img = $container.find("img");
    style.width = `${$img.width()}px`;
    style.height = `${$img.height()}px`;
  }
  return h(
    "div",
    {
      className: "w-e-image-container",
      id: containerId,
      style: style,
    },
    [imageVNode]
  );
}

/**
 * 选中状态下，渲染 image container（渲染拖拽容器，修改图片尺寸）
 */
function renderResizeContainer(
  editor: IDomEditor,
  elemNode: SlateElement,
  imageVNode: VNode,
  imageInfo: IImageSize
) {
  const $body = $$("body");
  const containerId = genContainerId(editor, elemNode);
  const { width, height, float, position } = imageInfo;

  let originalX = 0;
  let originalWith = 0;
  let originalHeight = 0;
  let revers = false; // 是否反转。如向右拖拽 right-top 需增加宽度（非反转），但向右拖拽 left-top 则需要减少宽度（反转）
  let $container: Dom7Array | null = null;

  /**
   * 初始化。监听事件，记录原始数据
   */
  function init(clientX: number) {
    $container = getContainerElem(containerId);
    // 记录当前 x 坐标值
    originalX = clientX;
    // 记录 img 原始宽高
    const $img = $container.find("img");
    if ($img.length === 0) throw new Error("Cannot find image elem");
    originalWith = $img.width();
    originalHeight = $img.height();

    // 监听 mousemove
    $body.on("mousemove", onMousemove);

    // 监听 mouseup
    $body.on("mouseup", onMouseup);

    const hoverBar = DomEditor.getHoverbar(editor);
    if (hoverBar) hoverBar.hideAndClean();
  }

  // mouseover callback （节流）
  const onMousemove = throttle((e: Event) => {
    e.preventDefault();
    const { clientX } = e as MouseEvent;
    const gap = revers ? originalX - clientX : clientX - originalX; // 考虑是否反转
    const newWidth = originalWith + gap;
    const newHeight = originalHeight * (newWidth / originalWith); // 根据 width ，按比例计算 height

    // 实时修改 img 宽高 -【注意】这里只修改 DOM ，mouseup 时再统一不修改 node
    if ($container == null) return;
    if (newWidth <= 15 || newHeight <= 15) return; // 最小就是 15px

    $container.css("width", `${newWidth}px`);
    $container.css("height", `${newHeight}px`);
  }, 100);

  function onMouseup(e: Event) {
    // 取消监听 mousemove
    $body.off("mousemove", onMousemove);

    if ($container == null) return;
    const newWidth = $container.width().toFixed(2);
    const newHeight = $container.height().toFixed(2);

    // 修改 node
    const props: Partial<ImageElement> = {
      style: {
        ...(elemNode as ImageElement).style,
        width: `${newWidth}px`,
        height: `${newHeight}px`,
      },
    };
    SlateTransforms.setNodes(editor, props, {
      at: DomEditor.findPath(editor, elemNode),
    });

    // 取消监听 mouseup
    $body.off("mouseup", onMouseup);
  }

  const style: any = {};
  if (width) style.width = width;
  if (height) style.height = height;
  if (float !== "none") {
    style.float = float;
    style.position = "relative";
  }
  if (position === "absolute") {
    style.float = "none";
    style.position = position;
    style.left = "0";
    style.bottom = "0";
  }
  // style.boxShadow = '0 0 0 1px #B4D5FF' // 自定义 selected 样式，因为有拖拽触手
  if (position === "absolute" && !width && !height) {
    let $container: Dom7Array | null = null;
    $container = getContainerElem(containerId);
    const $img = $container.find("img");
    style.width = `${$img.width()}px`;
    style.height = `${$img.height()}px`;
  }
  return h(
    "div",
    {
      id: containerId,
      style: style,
      className: "w-e-image-container w-e-selected-image-container",
      on: {
        mousedown: (e: MouseEvent) => {
          const $target = $$(e.target as Element);
          if (!$target.hasClass("w-e-image-dragger")) {
            // target 不是 .w-e-image-dragger 拖拽触手，则忽略
            return;
          }
          e.preventDefault();

          if (
            $target.hasClass("left-top") ||
            $target.hasClass("left-bottom")
          ) {
            revers = true; // 反转。向右拖拽，减少宽度
          }
          init(e.clientX); // 初始化
        },
      },
    },
    [
      imageVNode,
      h("div", { props: { className: "w-e-image-dragger left-top" } }),
      h("div", { props: { className: "w-e-image-dragger right-top" } }),
      h("div", { props: { className: "w-e-image-dragger left-bottom" } }),
      h("div", { props: { className: "w-e-image-dragger right-bottom" } }),
    ]
  );
}

function renderImage(
  elemNode: SlateElement,
  children: VNode[] | null,
  editor: IDomEditor
): VNode {
  const { src, alt = "", href = "", style = {} } = elemNode as ImageElement;
  const { width = "", height = "", float, position } = style;
  const selected = DomEditor.isNodeSelected(editor, elemNode); // 图片是否选中

  const imgStyle: any = {};
  if (width) imgStyle.width = "100%";
  if (height) imgStyle.height = "100%";
  if (float !== "none") {
    imgStyle.float = float;
    imgStyle.position = "relative";
  }
  if (position === "absolute") {
    imgStyle.float = "none";
    imgStyle.position = position;
    imgStyle.bottom = "0";
    imgStyle.left = "0";
  }

  // 【注意】void node 中，renderElem 不用处理 children 。core 会统一处理。
  const vNode = h("img", {
      style: imgStyle,
      src: src,
      alt: alt,
      "data-href": href,
  });

  const isDisabled = editor.isDisabled();
  if (selected && !isDisabled) {
    // 选中，未禁用 - 渲染 resize container
    return renderResizeContainer(editor, elemNode, vNode, {
      width,
      height,
      float,
      position
    });
  }

  // 其他，渲染普通 image container
  return renderContainer(editor, elemNode, vNode, {
    width,
    height,
    float,
    position
  });
}

const renderImageConf = {
  type: "image", // 和 elemNode.type 一致
  renderElem: renderImage,
};

export { renderImageConf };
