import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../components/layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import { errorConverter, FastapiErrorT } from "../../../utils/fastapi";
import { UserCreateType } from "../../../types/companyUser";
import { PasswordField } from "../../../components/forms/InputPassword";
import CompanyUserService from "../../../infrastructure/api/company/user";
import useAuthAxios from "../../../hooks/useAuthAxios";
import { CompanyUserEditForm } from "./EditForm";

const CompanyUserCreate: React.FC = () => {
  const [errors, setErrors] = useState<FastapiErrorT>();
  const [user, setUser] = useState<UserCreateType>({
    full_name: "",
    email: "",
    password: "",
    password2: "",
    is_superuser: false,
    thumbnail: "",
    is_active: false,
  });
  const service = new CompanyUserService(useAuthAxios());
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    service
      .create(user)
      .then(() => {
        navigate("/companies/user/");
      })
      .catch((err) => {
        setErrors(err);
      });
  };

  return (
    <MainLayout title="アカウント新規追加">
      <CompanyUserEditForm<UserCreateType>
        user={user}
        setUser={setUser}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={errors}
      >
        <PasswordField
          label="パスワード"
          name="password"
          value={user.password || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "password")}
        />
        <PasswordField
          label="パスワード確認"
          name="password2"
          value={user.password2 || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "password2")}
        />
      </CompanyUserEditForm>
    </MainLayout>
  );
};

export default CompanyUserCreate;
