import { OutputData } from "@editorjs/editorjs";
import { FastapiErrorT } from "../utils/fastapi";
import React from "react";

export interface OperatorBodyType {
  label: string;
  value: string;
}

export interface OperatorType {
  id?: number | null;
  name?: string | null;
  body?: OperatorBodyType[];
  url?: string | null;
}

export interface FundImageBase {
  id?: number | null;
  order: number;
  media_id: number;
  media_url?: string;
}

export interface DistributionType {
  id?: number | null;
  order: number;
  start: string;
  end: string;
  receive_date: string;
  dail_money?: number;
  completed?: boolean;
  fund_id?: string;
}

export interface RecruitmentsType {
  id?: number;
  units: number;
  booked_units: number;
  start: string | null;
  end: string | null;
  fund_id: string | null;
  result_date: string | null;
  recruitment_type: "first_come_basis" | "drawing";
  deleted?: boolean;
}

export interface ImageType extends FundImageBase {
  fund_id: string | null;
}

export interface ObjectImageType extends FundImageBase {
  object_information_id?: number | null;
}

export interface ObjectDataType {
  label: string;
  value: string;
}

export interface GoogleMapType {
  center: google.maps.LatLngLiteral | null;
  zoom: number | null;
}

export interface ObjectInformationType {
  id?: number | null;
  order: number;
  fund_id: string | null;
  object_name?: string | null;
  object_data: ObjectDataType[];
  postal_code?: string | null;
  address?: string | null;
  neighborhood_information?: OutputData | null;
  google_map: GoogleMapType;
  neighborhood_images?: ObjectImageType[] | null;
  object_images?: ObjectImageType[] | null;
}

export interface PromotionRuleType {
  start_from?: number | null;
  point?: number | null;
  point_percentage?: number | null;
  other: string;
}

export interface PromotionType {
  id?: number;
  title: string;
  content?: OutputData;
  start?: string | null;
  end?: string | null;
  completed?: boolean;
  rules?: PromotionRuleType[] | null;
  media_id?: number | null;
  media_url?: string | null;
  status: "draft" | "published";
}

export interface StepsObjType {
  fundFirst: boolean;
  fundSecond: boolean;
  fundThird: boolean;
  fundFourth: boolean;
  fundFifth: boolean;
  fundSix: boolean;
  fundSeven: boolean;
}

interface FundBase {
  id?: string;
  name: string;
  slug: string;
  status: Status;
  published?: boolean;
  capital_interest?: number | null;
  income_interest?: number | null;
  investment_amount?: number | null;
  subordinated_investment?: number | null;
  total_investment?: number | null;
  amount_raised?: number | null;
  number_of_units?: number | null;
  max_num_units?: number | null;
  min_num_units?: number | null;
  total_distributions?: number | null;
  operation_start?: string | null;
  operation_end?: string | null;
  transaction_type?: TransactionType | null;
  duration?: string | null;
  duration_type?: DurationType | null;
  fund_overview?: OutputData | null;
  steps: StepsObjType;
  distributions?: DistributionType[] | null;
  recruitments?: RecruitmentsType[];
  images?: ImageType[] | null;
  objects_of_information?: ObjectInformationType[] | null;
}
export interface FundType extends FundBase {
  thumbnail?: string;
  operators?: OperatorType[] | null;
  promotions?: PromotionType[] | null;
}

export interface FundDraftType extends FundBase {
  operators?: number[] | null;
  promotions?: number[] | null;
}

export interface OperatorsAddType {
  fund_id?: string | null;
  operators: number[];
}

export interface PromotionsAddType {
  fund_id?: string | null;
  promotions: number[];
}

export interface DocumentsModelingType {
  id?: number;
  content: string;
  published?: boolean;
  name?: string;
  belongs_to?: string;
}

export interface PropertyFieldType {
  category: string;
  data: ObjectDataType[];
}
export interface NDADataType {
  fund_name?: string;
  operation_start?: string;
  operation_end?: string;
  total_investment?: string;
  amount_raised?: string;
  number_of_units?: string;
  min_num_units?: string;
  max_num_units?: string;
  capital_interest?: string;
  income_interest?: string;
  investment_amount?: string;
  master_lease?: string;
  schedule_sub_invest?: string;
  price_of_target_estate?: string;
  calc_desc_of_price?: string;
  result_method_appraisal?: string;
  total_tenants?: string;
  total_leased_area?: string;
  leasable_area?: string;
  rental_occupancy_rate?: string;
  type_of_contract?: string;
  tenant_name?: string;
  master_lease_contract_amount?: string;
  rental_area?: string;
  contract_period?: string;
  contract_renew_methods?: string;
  deposit?: string;
  important_matters?: string;
  payment_status?: string;
  total_rental_business_cost?: string;
  application_period_method?: string;
  recruitment_invest_acquisition?: string;
  calc_period_start?: string;
  calc_period_finish?: string;
  calc_period_month?: string;
  transfer_deadline?: string;
  cpa_audit_status?: string;
  audit_scope?: string;
  attached_doc?: string;
  property_fields: PropertyFieldType[];
}

export interface DocumentBaseFieldsType extends ModelBaseType {
  created_by?: string;
  updated_by?: string;
  uuid?: string;
  documents_modeling_id?: number;
  draft?: boolean;
  published?: boolean;
  name?: string;
  data?: any;
  nda_uuid?: string;
  fund_id?: string;
}

export interface NDAType extends Omit<DocumentBaseFieldsType, "nda_uuid"> {
  data?: NDADataType;
}
export interface ContractAgreeFormType
  extends Omit<DocumentBaseFieldsType, "fund_id"> {}

export interface AnonymousPartnershipContractType
  extends ContractAgreeFormType {}

export interface ECommerceDetailsType extends ContractAgreeFormType {}

export enum Status {
  Draft = "draft",
  Scheduled = "scheduled",
  Operation = "operation",
  OperationSuspended = "operation_suspended",
}

export enum RecruitmentType {
  FirstComeBasis = "first_come_basis",
  Drawing = "drawing"
}

export enum TransactionType {
  Online = "online",
  Paper = "paper",
}

export enum DurationType {
  Yearly = "yearly",
  Monthly = "monthly",
}

export interface FundStepProps {
  inputValues: FundType;
  errors: FastapiErrorT | undefined;
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
}

export function getLabelForTransactionType(status: TransactionType | null | undefined) {
  switch (status) {
    case TransactionType.Online:
      return "クラウドファンディング";
    case TransactionType.Paper:
      return "面前取引";
    default:
      return "";
  }
}

export function getLabelForDurationType(status: DurationType) {
  switch (status) {
    case DurationType.Yearly:
      return "年間";
    case DurationType.Monthly:
      return "ヶ月";
    default:
      return "";
  }
}

export function getLabelForStatus(status: Status) {
  switch (status) {
    case Status.Draft:
      return "下書き";
    case Status.Scheduled:
      return "予定";
    case Status.Operation:
      return "募集前~運用完了";
    case Status.OperationSuspended:
      return "運用中止";
    default:
      return "";
  }
}

export function getLabelForRecruitmentType(type: RecruitmentType) {
  switch (type) {
    case RecruitmentType.Drawing:
      return "抽選";
    case RecruitmentType.FirstComeBasis:
      return "先着";
  }
}
