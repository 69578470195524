import React from "react";
import { MainLayout } from "../../../components/layouts/MainLayout";
import { AddLink } from "../../../components/ui/AddLink";
import { InjectedListPostProps, listPost } from "../../../hoc/post/listPost";

const NewsComponent: React.FC<InjectedListPostProps> = ({ memorizedTable }) => {
  return (
    <MainLayout title="お知らせ">
      <div className="w-full flex justify-end">
        <AddLink to="create" label="新規追加" />
      </div>
      {memorizedTable}
    </MainLayout>
  );
};

export default listPost(NewsComponent);
