import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Blog from "./routes/posts/blog/list";
import BlogCreate from "./routes/posts/blog/create";
import BlogEdit from "./routes/posts/blog/edit";
import News from "./routes/posts/news/list";
import NewsCreate from "./routes/posts/news/create";
import NewsEdit from "./routes/posts/news/edit";
import FundList from "./routes/funds/fund/list";
import FundCreate from "./routes/funds/fund/create/create";
import { OperatorList } from "./routes/funds/operator/list";
import OperatorEdit from "./routes/funds/operator/edit";
import OperatorCreate from "./routes/funds/operator/create";
import { PromotionList } from "./routes/funds/promotion/list";
import PromotionEdit from "./routes/funds/promotion/edit";
import PromotionCreate from "./routes/funds/promotion/create";
import DocumentsModelingCreate from "./routes/funds/documents/documentsModeling/create";
import DocumentCreate from "./routes/funds/documents/create";
import { DocumentsProvider } from "./contexts/documents";
import Login from "./routes/login";
import { AuthProvider } from "./contexts/auth";
import ProtectedRoute from "./components/layouts/ProtectedRoute";
import CompanyRoute from "./routes/companies";
import DocumentsModelingList from "./routes/funds/documents/documentsModeling/list";
import FundEdit from "./routes/funds/fund/edit/edit";
import GmoVerification from "./routes/gmoVerification";
import UserList from "./routes/customers/users/list";

const route = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <ProtectedRoute />,
    children: [
      {
        path: "*",
        element: <Root />,
        children: [
          {
            path: "funds",
            children: [
              {
                path: "fund",
                children: [
                  {
                    index: true,
                    element: <FundList />,
                  },
                  {
                    path: "create",
                    element: <FundCreate />,
                  },
                  {
                    path: "edit/:id",
                    element: <FundEdit />
                  }
                ],
              },
              {
                path: "documents",
                children: [
                  {
                    path: "bases",
                    children: [
                      {
                        index: true,
                        element: <DocumentsModelingList />
                      },
                      {
                        path: ":slug",
                        children: [
                          {
                            path: "create",
                            element: <DocumentsModelingCreate />,
                          },
                        ],
                      }
                    ],
                  },
                  {
                    path: ":slug",
                    children: [
                      {
                        path: "create",
                        element: <DocumentCreate />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "operator",
                children: [
                  {
                    index: true,
                    element: <OperatorList />,
                  },
                  {
                    path: "edit/:id",
                    element: <OperatorEdit />,
                  },
                  {
                    path: "create",
                    element: <OperatorCreate />,
                  },
                ],
              },
              {
                path: "promotion",
                children: [
                  {
                    index: true,
                    element: <PromotionList />,
                  },
                  {
                    path: ":id",
                    element: <PromotionEdit />,
                  },
                  {
                    path: "create",
                    element: <PromotionCreate />,
                  },
                ],
              },
            ],
          },
          {
            path: "posts",
            children: [
              {
                path: "blog",
                children: [
                  {
                    index: true,
                    element: <Blog postType="blog" />,
                  },
                  {
                    path: ":id",
                    element: <BlogEdit postType="blog" />,
                  },
                  {
                    path: "create",
                    element: <BlogCreate postType="blog" />,
                  },
                ],
              },
              {
                path: "news",
                children: [
                  {
                    index: true,
                    element: <News postType="news" />,
                  },
                  {
                    path: ":id",
                    element: <NewsEdit postType="news" />,
                  },
                  {
                    path: "create",
                    element: <NewsCreate postType="news" />,
                  },
                ],
              },
            ],
          },
          {
            path: "companies/*",
            element: <CompanyRoute />,
          },
          {
            path: "gmo/auth",
            element: <GmoVerification />
          },
          {
            path: "customers",
            element: <UserList />
          }
        ],
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <DocumentsProvider>
        <RouterProvider router={route} />
      </DocumentsProvider>
    </AuthProvider>
  );
}

export default App;
