import React from "react";
import { Modal } from "./Modal";
import { ModalActionLayout } from "../layouts/ModalActionLayout";
import { Button } from "./button/Button";
import { ButtonVariant } from "./button/constants";

interface DeleteConfirmationType {
  onDelete: () => void;
  button?: string | React.ReactElement;
  buttonClassName?: string;
  popoverClassName?: string;
}
export const DeleteConfirmation: React.FC<DeleteConfirmationType> = ({
  onDelete,
  button,
  buttonClassName,
  popoverClassName,
}) => {
  return (
    <Modal
      buttonClassName={
        buttonClassName ? buttonClassName : "rounded bg-red-500 p-1 text-white"
      }
      popoverClassName={
        popoverClassName
          ? popoverClassName
          : "fixed h-screen w-screen left-0 top-0 bg-gray-900/80 z-20 flex items-center justify-center"
      }
      button={button ? button : "削除"}
    >
      {(open) => (
        <ModalActionLayout>
          <div className="w-full text-center m-2">Do you want delete?</div>
          <div className="w-full flex gap-1">
            <Button
              onClick={() => {
                open.close();
              }}
              variant={ButtonVariant.Cancel}
            />
            <Button
              variant={ButtonVariant.Delete}
              onClick={async () => {
                await onDelete();
                open.close();
              }}
            />
          </div>
        </ModalActionLayout>
      )}
    </Modal>
  );
};
