import { DocumentFields, NDAFieldsType } from "../store/documents";
import { numberWithCommas } from "./numbers";
import { convertDateToJapanese } from "./date";
import { FundType } from "../types/fund";

export function replacePlaceholdersWithInputs(
  htmlText: string,
  fund?: FundType,
  data?: any,
  compare: boolean = false
): [string, object] {
  const regex = /{{(\w+|\w+:\w+)}}/g;
  const matches = htmlText.match(regex);
  if (!matches || !fund) {
    return [htmlText, {}];
  }

  let replacedText = htmlText;
  const autoData: any = {};
  console.log(matches);
  matches.forEach((match) => {
    const field = match.substring(2, match.length - 2);
    const f = field.split(":");
    const fieldName = f[0];
    // Get the text before the current match
    if (DocumentFields[fieldName as keyof NDAFieldsType]) {
      const x = DocumentFields[fieldName as keyof NDAFieldsType];
      if (x.type === "auto") {
        let v = fund[x.key as keyof FundType];
        if (compare && data && typeof data === "object" && data[x.key]) {
          v = data[fieldName];
        }
        autoData[fieldName] = v;
        if (
          x.valueType === "money" &&
          f.length > 1 &&
          f[1] === "man" &&
          typeof v === "number"
        ) {
          v = v * 10000;
        }
        if (
          (x.valueType === "number" || x.valueType === "money") &&
          (typeof v === "number" || typeof v === "string")
        ) {
          v = numberWithCommas(v);
        } else if (f[1] === "date") {
          v = convertDateToJapanese(v);
        }
        replacedText = replacedText.replace(
          match,
          `<b style='color: red'>${v}</b>`
        );
      } else if (x.type) {
        let style = "";
        if (x.type === "category-list" && f[1]) {
          style = `width: ${f[1]}%;display: block; margin: 0 auto;`;
        }
        replacedText = replacedText.replace(
          match,
          `<span style="${style}" class="${
            x.type
          }" data-field="${fieldName}" data-title="${x.title}" ${
            f[1] ? `data-util="${f[1]}"` : ""
          }>${fieldName}</span>`
        );
      }
    }
  });

  return [replacedText, autoData];
}
