import axios from "axios";
import { DocumentsModelingType } from "../../../types/fund";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class DocumentsModelingService {
  private belongs: string;
  constructor(belongs: string) {
    this.belongs = belongs;
  }
  getList() {
    return axios
      .get<DocumentsModelingType[]>(
        API_URL + `/api/v1/admin/funds/documents_modeling/base/${this.belongs}/`
      )
      .then<DocumentsModelingType[]>((res) => {
        return res.data;
      })
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }
  getDocumentsModeling(id: string | number) {
    return axios
      .get<DocumentsModelingType>(
        API_URL + `/api/v1/admin/funds/documents_modeling/${id}/`
      )
      .then<DocumentsModelingType>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }

  updateOrCreate(data: DocumentsModelingType) {
    return axios
      .put<DocumentsModelingType>(
        API_URL + `/api/v1/admin/funds/documents_modeling/`,
        data
      )
      .then<DocumentsModelingType>((res) => res.data)
      .catch((res) => {
        throw JSON.parse(res.request.response);
      });
  }
}

export default DocumentsModelingService;
