import React, { MutableRefObject, MouseEvent } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../../utils";
interface PopoverRenderPropArg {
  open: boolean;
  close(
    focusableElement?:
      | HTMLElement
      | MutableRefObject<HTMLElement | null>
      | MouseEvent<HTMLElement>
  ): void;
}

interface ModalProps {
  children: React.FC<PopoverRenderPropArg>;
  button: string | React.ReactElement;
  buttonClassName?: string;
  popoverClassName?: string;
  openCallback?: () => void;
  ref?: React.Ref<HTMLButtonElement>;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    popoverClassName,
    buttonClassName,
    button,
    openCallback,
    ref,
  } = props;
  return (
    <Popover className="relative">
      {(open) => {
        if (open.open) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
        return (
          <>
            <Popover.Button
              ref={ref}
              onClick={() => {
                !open.open && openCallback && openCallback();
              }}
              className={classNames(
                open.open ? "" : "text-opacity-90",
                buttonClassName
                  ? buttonClassName
                  : "group inline-flex items-center rounded-md bg-accent-main px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              )}
            >
              {button}
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="z-20 fixed left-0 top-0 w-screen h-screen flex justify-center items-center">
                <div
                  className={classNames(
                    popoverClassName
                      ? popoverClassName
                      : "p-2 border w-full sm:w-1/2 bg-blue-50 rounded h-full sm:h-96 relative"
                  )}
                >
                  {children(open)}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
