import React from "react";
import { InputText } from "../../../components/forms/InputText";
import { InputCurrency } from "../../../components/forms/InputCurrency";
import parse, {
  HTMLReactParserOptions,
  Element,
  domToReact,
  attributesToProps,
} from "html-react-parser";
import { PropertyFieldType } from "../../../types/fund";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { numberWithCommas } from "../../../utils/numbers";
import { convertDateToJapanese } from "../../../utils/date";

type ParsedHTMLTYPE<T extends TableData, D extends TableData> = {
  html: string;
  setState: React.Dispatch<React.SetStateAction<T>>;
  state: T;
  origin?: D;
};

export const ParsedDocHTML = <T extends TableData, D extends TableData>({
  html,
  state,
  setState,
  origin,
}: ParsedHTMLTYPE<T, D>) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i?: number,
    parent?: string
  ) => {
    const { name, value } = event.target;
    const dataName = event.target.getAttribute("data-name");
    const dataIndex = event.target.getAttribute("data-index")
      ? parseInt(event.target.getAttribute("data-index") || "")
      : null;
    if (i !== undefined && parent && dataIndex !== null && dataName) {
      const cpData = { ...state };
      cpData.data[parent][i][dataName][dataIndex][name] = value;
      setState(cpData);
    } else if (dataIndex !== null && dataName) {
      const cpData = { ...state };
      cpData.data[dataName][dataIndex][name] = value;
      setState(cpData);
    } else if (dataName) {
      const cpData = { ...state };
      cpData.data[dataName][name] = value;
      setState(cpData);
    } else {
      setState((prevState) => ({
        ...prevState,
        data: { ...prevState.data, [name]: value },
      }));
    }
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const { name } = e.target;
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64 = reader.result;
        setState((prevState) => ({
          ...prevState,
          data: { ...prevState.data, [name]: base64 },
        }));
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleAddCategoryList = (field: string) => {
    const cpData = { ...state };
    cpData.data[field].push({ category: "", data: [{ label: "", value: "" }] });
    setState(cpData);
  };

  const handleRemoveCategoryList = (field: string, i: number) => {
    const cpData = { ...state };
    cpData.data[field].splice(i, 1);
    setState(cpData);
  };

  const handleAddDataList = (field: string, j: number) => {
    const cpData = { ...state };
    cpData.data[field][j].data.push({ label: "", value: "" });
    setState(cpData);
  };

  const handleRemoveDataList = (field: string, i: number, j: number) => {
    const cpData = { ...state };
    cpData.data[field][j].data.splice(i, 1);
    setState(cpData);
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const typeDomNode = domNode as Element;
      const props = attributesToProps(typeDomNode.attribs);
      if (!typeDomNode.attribs) {
        return;
      }
      if (typeDomNode.name === "p") {
        return (
          <div className="paragraph" {...props}>
            {domToReact(typeDomNode.children, options)}
          </div>
        );
      }
      const fieldName = typeDomNode.attribs["data-field"];
      switch (typeDomNode.attribs.class) {
        case "input-textarea":
        case "input-date":
        case "input-text":
          if (origin?.data[fieldName]) {
            let v = origin.data[fieldName];
            if (typeDomNode.attribs["data-util"] === "date")
              v = convertDateToJapanese(v);
            return <span className="inline-block text-red-500">{v}</span>;
          }
          return (
            <span
              className="inline-block"
              style={
                typeDomNode.attribs.class.split("-")[1] === "textarea"
                  ? { width: "100%" }
                  : {}
              }
            >
              <InputText
                label=""
                error=""
                type={
                  typeDomNode.attribs.class.split("-")[1] as
                    | "text"
                    | "date"
                    | "textarea"
                }
                parentClassName={
                  typeDomNode.attribs.class.split("-")[1] === "textarea"
                    ? ""
                    : "w-fit"
                }
                handleChange={handleChange}
                name={fieldName}
                value={state.data[fieldName] || ""}
              />
            </span>
          );
        case "input-currency":
          if (origin?.data[fieldName]) {
            return (
              <span className="inline-block text-red-500">
                {numberWithCommas(origin.data[fieldName])}
              </span>
            );
          }
          return (
            <span className="inline-block">
              <InputCurrency
                label=""
                rightLabel=""
                parentClassName="w-fit"
                name={fieldName}
                value={state.data[fieldName]}
                handleChange={(...props) => {
                  setState((prev) => ({
                    ...prev,
                    data: { ...prev.data, [fieldName]: props[1] },
                  }));
                }}
                error=""
              />
            </span>
          );
        case "input-file":
          if (origin?.data[fieldName]) {
            return (
              <span className="inline-block text-red-500">
                <iframe
                  className="my-2"
                  src={origin.data[fieldName]}
                  title={typeDomNode.attribs["data-title"]}
                />
              </span>
            );
          }
          return (
            <span className="my-2 inline-block">
              <label
                htmlFor={"id_" + fieldName}
                className="cursor-pointer border p-2"
              >
                別紙添付資料
              </label>
              <input
                required={!state.data[fieldName]}
                type="file"
                className="opacity-0 w-1"
                id={"id_" + fieldName}
                name={fieldName}
                onChange={handleFile}
              />
              {state.data[fieldName] && (
                <iframe
                  className="my-2"
                  src={state.data[fieldName]}
                  title={typeDomNode.attribs["data-title"]}
                />
              )}
            </span>
          );
        case "category-list":
          if (origin?.data[fieldName]) {
            return (
              <span className="inline-block" style={props.style}>
                {origin.data[fieldName]?.map(
                  (cat: PropertyFieldType, j: number) => (
                    <div className="flex items-end" key={j}>
                      <div
                        className="p-2 my-2 border-grey-boarder"
                        style={{
                          width: props.style.width ? "100%" : "fit-content",
                        }}
                      >
                        <span className="text-red-500">{cat.category}</span>
                        <table className="table-fixed">
                          <tbody>
                            {cat.data?.map(
                              (
                                val: { label: string; value: string },
                                i: number
                              ) => (
                                <tr key={i}>
                                  <td className="text-red-500">
                                    {val.label || ""}
                                  </td>
                                  <td className="text-red-500">
                                    {val.value || ""}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                )}
              </span>
            );
          }
          return (
            <span className="inline-block m-auto" style={props.style}>
              {state.data[fieldName]?.map(
                (cat: PropertyFieldType, j: number) => (
                  <div className="flex items-end" key={j}>
                    <div
                      className="p-2 my-2 border-grey-boarder"
                      style={{
                        width: props.style.width ? "100%" : "fit-content",
                      }}
                    >
                      <InputText
                        label=""
                        name="category"
                        value={cat.category}
                        handleChange={handleChange}
                        parentClassName="w-fit"
                        error=""
                        attr={{ "data-name": fieldName, "data-index": j }}
                      />
                      <table className="table-fixed">
                        <tbody>
                          {cat.data?.map(
                            (
                              val: { label: string; value: string },
                              i: number
                            ) => (
                              <tr key={i}>
                                <td>
                                  <InputText
                                    label=""
                                    name="label"
                                    value={val.label || ""}
                                    handleChange={(e) => {
                                      handleChange(e, j, fieldName);
                                    }}
                                    attr={{
                                      "data-name": "data",
                                      "data-index": i,
                                    }}
                                    error=""
                                  />
                                </td>
                                <td className="flex items-center">
                                  <InputText
                                    label=""
                                    name="value"
                                    value={val.value || ""}
                                    handleChange={(e) => {
                                      handleChange(e, j, fieldName);
                                    }}
                                    parentClassName="flex-1"
                                    attr={{
                                      "data-name": "data",
                                      "data-index": i,
                                    }}
                                    error=""
                                  />
                                  <div className="flex gap-2">
                                    {i === cat.data.length - 1 && (
                                      <PlusIcon
                                        className="h-6 w-6 text-green-500 cursor-pointer"
                                        onClick={() => {
                                          handleAddDataList(fieldName, j);
                                        }}
                                      />
                                    )}
                                    {i !== 0 && (
                                      <MinusIcon
                                        className="h-6 w-6 text-red-500 cursor-pointer"
                                        onClick={() => {
                                          handleRemoveDataList(fieldName, i, j);
                                        }}
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex gap-2">
                      {j === state.data[fieldName].length - 1 && (
                        <PlusIcon
                          className="h-6 w-6 text-green-500 cursor-pointer"
                          onClick={() => {
                            handleAddCategoryList(fieldName);
                          }}
                        />
                      )}
                      {j !== 0 && (
                        <MinusIcon
                          className="h-6 w-6 text-red-500 cursor-pointer"
                          onClick={() => {
                            handleRemoveCategoryList(fieldName, j);
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              )}
            </span>
          );
        default:
          return;
      }
    },
  };
  return <>{parse(html, options)}</>;
};
