import axios from "axios";
import { PromotionType } from "../../../types/fund";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class PromotionService {
  getList(page: number, limit: number) {
    return axios
      .get<PaginatedDataType<PromotionType>>(
        API_URL + "/api/v1/admin/funds/promotion/",
        {
          params: {
            page: page,
            limit: limit,
          },
        }
      )
      .then<PaginatedDataType<PromotionType>>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  getPromotion(id: string) {
    return axios
      .get<PromotionType>(API_URL + `/api/v1/admin/funds/promotion/${id}/`)
      .then<PromotionType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  createPromotion(data: PromotionType) {
    return axios
      .post<PromotionType>(API_URL + "/api/v1/admin/funds/promotion/", data)
      .then<PromotionType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  deletePromotion(id: number) {
    return axios
      .delete(API_URL + `/api/v1/admin/funds/promotion/${id}/`)
      .catch((error) => {
        throw error.request.response;
      });
  }
  updatePromotion(id: string, data: PromotionType) {
    return axios
      .put<PromotionType>(
        API_URL + `/api/v1/admin/funds/promotion/${id}/`,
        data
      )
      .then<PromotionType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default PromotionService;
