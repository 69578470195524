import React, { useCallback, useEffect, useState } from "react";
import WangEditor from "../../../../components/ui/WangEditor";
import { MainLayout } from "../../../../components/layouts/MainLayout";
import { useParams, useSearchParams } from "react-router-dom";
import NDAModelingService from "../../../../infrastructure/api/funds/documentsModeling";
import { DocumentsModelingType } from "../../../../types/fund";
import { InputText } from "../../../../components/forms/InputText";
import { errorConverter, FastapiErrorT } from "../../../../utils/fastapi";

const getTitle = (
  val:
    | "nda"
    | "contract_agree_form"
    | "ecommerce_details"
    | "anonymous_partnership_contract"
) => {
  switch (val) {
    case "nda":
      return "契約成立前書面のベースを作成";
    case "contract_agree_form":
      return "契約成立時書面のベースを作成";
    case "ecommerce_details":
      return "電子取引業務に係る重要事項のベースを作成";
    case "anonymous_partnership_contract":
      return "匿名組合契約書のベースを作成";
  }
};
const DocumentsModelingCreate: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug } = useParams();
  const [cachedData, setCachedData] = useState<string>("<p><br></p>");
  const [isModified, setIsModified] = useState(false);
  const [data, setData] = useState<DocumentsModelingType>({
    name: "",
    belongs_to: slug,
    content: "<p><br></p>",
  });
  const fetchService = new NDAModelingService(slug || "");
  const [errors, setErrors] = useState<FastapiErrorT>();

  useEffect(() => {
    const id = searchParams.get("documents_modeling_id");
    if (id) {
      fetchService.getDocumentsModeling(id).then((data) => {
        setData(data);
        setCachedData(data.content);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setHtml = (html: string) => {
    setData((prevState) => ({ ...prevState, content: html }));
  };

  const handleSave = () => {
    if (data) {
      fetchService.updateOrCreate(data).then((d) => {
        setData(d);
        const id = searchParams.get("documents_modeling_id");
        setCachedData(d.content);
        if (d.id && (!id || id !== d.id.toString())) {
          setSearchParams({ documents_modeling_id: d.id.toString() });
        }
      });
    }
  };

  const compareData = useCallback(() => {
    // Compare cachedData with the current state
    const hasChanged = cachedData !== data?.content;
    if (hasChanged !== isModified) {
      setIsModified(hasChanged);
    }
  }, [data?.content, cachedData, isModified]);

  useEffect(() => {
    compareData();
  }, [compareData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  if (
    slug === "nda" ||
    slug === "contract_agree_form" ||
    slug === "ecommerce_details" ||
    slug === "anonymous_partnership_contract"
  ) {
    return (
      <MainLayout title={getTitle(slug)}>
        <InputText
          parentClassName="w-full my-2"
          label="文書名"
          name="name"
          value={data?.name || ""}
          handleChange={handleChange}
          error={errorConverter(errors?.detail, "name")}
        />
        <WangEditor html={data?.content || "<p><br></p>"} setHtml={setHtml} />
        <div className="flex justify-end p-2 w-full bg-transparent">
          <div
            className="flex gap-2 container"
            style={{ justifyContent: "end" }}
          >
            <button
              type="button"
              className="p-2 bg-grey-bg rounded border flex-1"
            >
              戻る
            </button>
            <button
              type="button"
              className="p-2 bg-primary-dark rounded border text-white flex-1"
              disabled={!isModified}
              onClick={handleSave}
            >
              保存
            </button>
          </div>
        </div>
      </MainLayout>
    );
  }
  return <></>;
};

export default DocumentsModelingCreate;
