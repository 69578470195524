import React from "react";
import { Label } from "./Label";
import { InputText } from "../forms/InputText";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";

interface AppendTableProps<T extends object> {
  data: T[];
  label: string;
  labelKey: keyof T;
  valueKey: keyof T;
  deleteStartIndex?: number;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  handleDelete: (index: number) => void;
  handleAdd: (index: number) => void;
}

export function AppendTable<T extends object>({
  data,
  label,
  labelKey,
  valueKey,
  deleteStartIndex = data ? data.length : 0,
  handleChange,
  handleDelete,
  handleAdd,
}: AppendTableProps<T>): JSX.Element {
  return (
    <table className="border-collapse border border-grey-boarder overflow-x-scroll w-full">
      <caption className="text-left">
        <Label label={label} />
      </caption>
      <tbody>
        {data.map((obj, index) => (
          <tr key={index}>
            <th className="border p-2 border-grey-boarder">
              <InputText
                label=""
                name={labelKey as string}
                value={(obj[labelKey] as string) || ""}
                handleChange={(event) => handleChange(event, index)}
                error=""
              />
            </th>
            <td className="border p-2 border-grey-boarder">
              <InputText
                label=""
                rows={2}
                type="textarea"
                name={valueKey as string}
                value={(obj[valueKey] as string) || ""}
                handleChange={(event) => handleChange(event, index)}
                error=""
              />
              {index > deleteStartIndex && (
                <MinusIcon
                  onClick={() => handleDelete(index)}
                  className="h-6 w-6 text-red-500 cursor-pointer"
                />
              )}
              {index === data.length - 1 && (
                <PlusIcon
                  onClick={() => handleAdd(index)}
                  className="h-6 w-6 text-green-500 cursor-pointer"
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
