import React from "react";
import { classNames } from "../../utils";

interface LabelProps {
  htmlFor?: string;
  className?: string;
  label: string | number;
  error?: boolean;
}

export const Label: React.FC<LabelProps> = ({
  htmlFor = "",
  className = "",
  label,
  error = false,
}) => (
  <label
    htmlFor={htmlFor}
    className={classNames(
      className,
      "text-sm",
      error ? "text-red-500" : "text-grey-text-light"
    )}
  >
    {label}
  </label>
);
