import axios from "axios";
import {
  CategoryBaseType,
  CategoryCreateType,
  CategoryType,
} from "../../../types/category";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class CategoryService {
  getList(postType: "blog" | "news") {
    return axios
      .get<CategoryType[]>(API_URL + `/api/v1/admin/posts/category/${postType}/`)
      .then<CategoryType[]>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  createCategory(data: CategoryCreateType) {
    return axios
      .post<CategoryType>(API_URL + "/api/v1/admin/posts/category/", data)
      .then<CategoryType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  updateCategory(id: number, data: CategoryCreateType) {
    return axios
      .patch<CategoryType>(API_URL + `/api/v1/admin/posts/category/${id}/`, data)
      .then<CategoryType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  deleteCategory(id: number) {
    return axios
      .delete<CategoryBaseType>(API_URL + `/api/v1/admin/posts/category/${id}/`)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default CategoryService;
