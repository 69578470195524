import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../components/layouts/MainLayout";
import CompanyUserService from "../../../infrastructure/api/company/user";
import useAuthAxios from "../../../hooks/useAuthAxios";
import { UserDetailType } from "../../../types/companyUser";
import profileImg from "../../../assets/images/profile.png";
import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToJapanese } from "../../../utils/date";
import { AddLink } from "../../../components/ui/AddLink";
import { Link } from "react-router-dom";
import { DeleteConfirmation } from "../../../components/ui/DeleteConfirmation";

const TH: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <th className="p-2 text-left text-grey-text-light font-normal">{children}</th>
);

const TD: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <td className="text-sm p-2">{children}</td>
);

const UserImage: React.FC<{ src: string | null; alt: string }> = ({
  src,
  alt,
}) => (
  <img src={src || profileImg} alt={alt} className="h-12 w-12 rounded-2xl" />
);

const UserCheckIcon: React.FC<{ condition: boolean }> = ({ condition }) => (
  <>
    {condition ? (
      <CheckCircleIcon className="h-5 w-5 text-blue-500" />
    ) : (
      <NoSymbolIcon className="h-5 w-5 text-red-500" />
    )}
  </>
);

const JapaneseDate: React.FC<{ date: string | null }> = ({ date }) => (
  <span className="whitespace-pre">
    {convertDateToJapanese(date || "")
      .split(" ")
      .join("\n")}
  </span>
);

const CompanyUserList: React.FC = () => {
  const [users, setUsers] = useState<UserDetailType[]>([]);
  const axios = useAuthAxios();
  const fetchUser = new CompanyUserService(axios);

  useEffect(() => {
    fetchUser.getList().then((data) => {
      setUsers(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (pk: string, index: number) => {
    fetchUser.delete(pk).then(() => {
      const cpUsers = [...users];
      cpUsers.splice(index, 1);
      setUsers(cpUsers);
    });
  };

  return (
    <MainLayout title="アカウント一覧">
      <div className="w-full flex justify-end">
        <AddLink to="create" label="新規追加" />
      </div>
      <div className="grid whitespace-nowrap overflow-scroll grid-cols-1 w-full mt-2">
        <table className="border w-full bg-gray-700/5">
          <thead>
            <tr className="border-b">
              <TH>画像</TH>
              <TH>氏名</TH>
              <TH>メール</TH>
              <TH>有効</TH>
              <TH>スーパーユーザ</TH>
              <TH>グループ</TH>
              <TH>最終ログイン</TH>
              <TH>履歴</TH>
              <TH>操作</TH>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id} className="border-b">
                <TD>
                  <UserImage
                    src={user.thumbnail}
                    alt={`${user.full_name}の画像`}
                  />
                </TD>
                <TD>{user.full_name || "ー"}</TD>
                <TD>{user.email}</TD>
                <TD>
                  <UserCheckIcon condition={user.is_active} />
                </TD>
                <TD>
                  <UserCheckIcon condition={user.is_superuser} />
                </TD>
                <TD>-</TD>
                <TD>
                  <JapaneseDate date={user.last_login} />
                </TD>
                <TD>
                  <div className="text-xs text-gray-500">
                    <div className="flex gap-2 justify-between">
                      <span>作成者:</span> {user.created_by_name || "ー"}
                    </div>
                    <div className="flex gap-2 justify-between">
                      <span>作成日:</span>{" "}
                      <JapaneseDate date={user.created_at || "ー"} />
                    </div>
                    <div className="flex gap-2 justify-between">
                      <span>最終編集者:</span> {user.updated_by_name || "ー"}
                    </div>
                    <div className="flex gap-2 justify-between">
                      <span>最終編集日:</span>
                      <JapaneseDate date={user.updated_at || "ー"} />
                    </div>
                  </div>
                </TD>
                <TD>
                  <div className="flex gap-2 text-primary-dark">
                    <Link to={`${user.id}`}>
                      <PencilIcon className="h-5 w-5 cursor-pointer hover:text-green-500" />
                    </Link>
                    <DeleteConfirmation
                      onDelete={() => {
                        handleDelete(user.id, index);
                      }}
                      buttonClassName="bg-transparent"
                      button={
                        <TrashIcon className="h-5 w-5 cursor-pointer hover:text-red-500" />
                      }
                    />
                  </div>
                </TD>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </MainLayout>
  );
};

export default CompanyUserList;
