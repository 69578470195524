import React from "react";

interface FormType {
  children: React.ReactNode;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: any;
}

export const Form: React.FC<FormType> = (props) => {
  const { handleSubmit, errors, children } = props;

  return (
    <form className="w-full inline-flex flex-col gap-6" onSubmit={handleSubmit}>
      {errors?.detail && typeof errors.detail === "string" && (
        <span className="text-red-500 text-sm w-full text-center font-bold">
          {errors.detail}
        </span>
      )}
      {children}
    </form>
  );
};
