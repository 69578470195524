import axios from "axios";
import {
  DistributionType,
  FundDraftType,
  FundType,
  ImageType,
  ObjectInformationType,
  OperatorsAddType,
  OperatorType,
  PromotionsAddType,
  PromotionType,
} from "../../../types/fund";
import { DocumentsFieldBase } from "../../../store/documents";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class StepService {
  draft(data: FundDraftType) {
    return axios
      .put<FundType>(API_URL + "/api/v1/admin/funds/step/draft/", data)
      .then<FundType>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  firstStep(data: FundType) {
    return axios
      .put<FundType>(API_URL + "/api/v1/admin/funds/step/1/", data)
      .then<FundType>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }
  secondStep(data: DistributionType[]) {
    return axios
      .put<DistributionType[]>(API_URL + "/api/v1/admin/funds/step/2/", data)
      .then<DistributionType[]>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  thirdStep(data: ImageType[]) {
    return axios
      .put<ImageType[]>(API_URL + "/api/v1/admin/funds/step/3/", data)
      .then<ImageType[]>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  fourthStep(data: ObjectInformationType[]) {
    return axios
      .put<ObjectInformationType[]>(
        API_URL + "/api/v1/admin/funds/step/4/",
        data
      )
      .then<ObjectInformationType[]>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  fifthStep(data: OperatorsAddType) {
    return axios
      .put<OperatorType[]>(API_URL + "/api/v1/admin/funds/step/5/", data)
      .then<OperatorType[]>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  sixStep(data: PromotionsAddType) {
    return axios
      .put<PromotionType[]>(API_URL + "/api/v1/admin/funds/step/6/", data)
      .then<PromotionType[]>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

  seventhStepGet(fund_id: string) {
    return axios
      .get<DocumentsFieldBase>(
        API_URL + `/api/v1/admin/funds/step/7/${fund_id}/`
      )
      .then<DocumentsFieldBase>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }

   seventhStep(fund_id: string) {
    return axios
      .put<DocumentsFieldBase>(
        API_URL + `/api/v1/admin/funds/step/7/${fund_id}/`
      )
      .then<DocumentsFieldBase>((res) => res.data)
      .catch((res) => {
        throw res.request.response;
      });
  }
}

export default StepService;
