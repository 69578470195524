import React, { useState, useEffect } from "react";
import { Boot } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  i18nChangeLanguage,
} from "@wangeditor/editor";
import "@wangeditor/editor/dist/css/style.css";
import imageWangEditor from "../../plugins/imageWangEditor";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

Boot.registerModule(imageWangEditor);

interface WangEditorProps {
  html: string;
  setHtml: (html: string) => void;
}

const WangEditor: React.FC<WangEditorProps> = ({ html, setHtml }) => {
  const [editor, setEditor] = useState<IDomEditor | null>(null);

  i18nChangeLanguage("en");

  const toolbarConfig: Partial<IToolbarConfig> = {}; // TS syntax

  const editorConfig: Partial<IEditorConfig> = {
    // TS syntax
    MENU_CONF: {},
    placeholder: "Type here...",
    hoverbarKeys: {
      image: {
        menuKeys: [
          "imageWidth30",
          "imageWidth50",
          "imageWidth100",
          "|",
          "imageFloatNone",
          "imageFloatLeft",
          "imageFloatRight",
          "imageAbsolute",
          "|",
          "editImage",
          "viewImageLink",
          "deleteImage",
        ],
      },
    },
  };
  if (editorConfig.MENU_CONF) {
    editorConfig.MENU_CONF["uploadImage"] = {
      fieldName: "image",
      server: API_URL + "/api/v1/admin/posts/editor/wang/image/",
    };
  }

  // Timely destroy editor, important!
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  return (
    <>
      <div style={{ border: "1px solid #ccc", zIndex: 100 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: "1px solid #ccc" }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={html}
          onCreated={setEditor}
          onChange={(e) => setHtml(e.getHtml())}
          mode="default"
          style={{ height: "500px", overflowY: "hidden" }}
        />
      </div>
    </>
  );
};

export default WangEditor;
