import {
  IButtonMenu,
  IDomEditor,
  DomEditor,
  SlateTransforms,
  SlateNode,
} from "@wangeditor/editor";
import { ImageElement } from "../custom-types";

abstract class ImageFloatBaseClass implements IButtonMenu {
  abstract readonly title: string;
  abstract readonly iconSvg: string;
  abstract readonly value: string;
  readonly tag = "button";

  getValue(editor: IDomEditor): string | boolean {
    return "";
  }

  isActive(editor: IDomEditor): boolean {
    return false;
  }

  private getSelectedNode(editor: IDomEditor): SlateNode | null {
    return DomEditor.getSelectedNodeByType(editor, "image");
  }

  isDisabled(editor: IDomEditor): boolean {
    if (editor.selection == null) return true;

    const imageNode = this.getSelectedNode(editor);
    return imageNode == null;
  }

  exec(editor: IDomEditor, value: string | boolean) {
    if (this.isDisabled(editor)) return;

    const imageNode = this.getSelectedNode(editor);
    if (imageNode == null) return;

    const hoverBar = DomEditor.getHoverbar(editor);
    if (hoverBar) hoverBar.hideAndClean();

    const { style = {} } = imageNode as ImageElement;
    const props: Partial<ImageElement> = {
      style: {
        ...style,
        position: "unset",
        float: this.value, // 修改 float 的值
      },
    };

    SlateTransforms.setNodes(editor, props, {
      match: (n) => DomEditor.checkNodeType(n, "image"),
    });
  }
}

export default ImageFloatBaseClass;
