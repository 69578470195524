import React, {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import logo from "../assets/images/logo.png";
import {Form} from "../components/ui/Form";
import {InputText} from "../components/forms/InputText";
import {errorConverter, FastapiErrorT} from "../utils/fastapi";
import {PasswordField} from "../components/forms/InputPassword";
import {useLocation, useNavigate} from "react-router-dom";

const Login: React.FC = () => {
  const {login, authenticated} = useAuth();
  const [email, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<FastapiErrorT>();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password).catch((err) => {
      setErrors(err);
    });
  };

  useEffect(() => {
    if (authenticated) {
      navigate(location.state?.from ? location.state.from.pathname + location.state.from.search + location.state.from.hash : "/");
    }
  }, [location, navigate, authenticated]);

  return (
    <div className="flex items-center h-full w-full md:bg-transparent">
      <div className="m-auto h-auto px-4 w-[500px] max-[500px]:4-full">
        <img alt="logo" src={logo} className="h-16 m-auto"/>
        <div className="bg-white px-10 py-5 rounded-md">
          <div className="text-center my-3">
            <h2 className="my-2">ログイン</h2>
          </div>
          <Form errors={errors} handleSubmit={handleSubmit}>
            <InputText
              label="メール"
              name="email"
              value={email}
              placeholder="メールを入力してください"
              handleChange={(e) => setUsername(e.target.value)}
              error={errorConverter(errors?.detail, "email")}
              classname="h-12 px-2 border border-gray-300 rounded-md bg-gray-50 focus:border-primary-dark focus:outline-none"
            />
            <PasswordField
              label="パスワード"
              name="password"
              placeholder="パスワードを入力してください"
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              error={errorConverter(errors?.detail, "password")}
              classname="h-12 px-2 border border-gray-300 rounded-md bg-gray-50 focus:border-primary-dark focus:outline-none"
            />
            <button
              type="submit"
              className="bg-primary-dark text-white p-2 my-3 rounded"
            >
              Login
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
