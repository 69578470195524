import axios from "axios";
import { TagBaseType, TagCreateType, TagType } from "../../../types/tag";

const API_URL = process.env.REACT_APP_GATEWAY_URL;

class TagService {
  getList(postType: "blog" | "news") {
    return axios
      .get<TagType[]>(API_URL + `/api/v1/admin/posts/tag/${postType}/`)
      .then<TagType[]>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }
  createTag(data: TagCreateType) {
    return axios
      .post<TagType>(API_URL + "/api/v1/admin/posts/tag/", data)
      .then<TagType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  updateTag(id: number, data: TagCreateType) {
    return axios
      .patch<TagType>(API_URL + `/api/v1/admin/posts/tag/${id}/`, data)
      .then<TagType>((res) => res.data)
      .catch((error) => {
        throw error.request.response;
      });
  }

  deleteTag(id: number) {
    return axios
      .delete<TagBaseType>(API_URL + `/api/v1/admin/posts/tag/${id}/`)
      .catch((error) => {
        throw error.request.response;
      });
  }
}

export default TagService;
