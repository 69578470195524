import { useEffect } from "react";
import { AxiosInstance } from "axios";
import { authAxios } from "../infrastructure/api/axios";
import { useAuth } from "./useAuth";
import { useNavigate } from "react-router-dom";

const useAuthAxios = (): AxiosInstance => {
  const { user, refreshToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // リクエスト前に実行。headerに認証情報を付与する
    const requestIntercept = authAxios.interceptors.request.use(
      (config) => {
        if (!config.headers?.Authorization) {
          config.headers.Authorization = `Bearer ${user?.access_token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // レスポンスを受け取った直後に実行。もし認証エラーだった場合、再度リクエストする。
    const responseIntercept = authAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        // 401認証エラー(headerにaccess_tokenがない。もしくはaccess_tokenが無効)
        if (error?.response?.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true;
          // 新しくaccess_tokenを発行する
          let access_token = null;
          await refreshToken().then((data) => {
            access_token = data.access_token;
          });
          if (access_token !== null) {
            prevRequest.headers = {
              ...prevRequest.headers,
              Authorization: `Bearer ${access_token}`,
            };

            return authAxios(prevRequest);
          }
        } else if (error?.response.status === 401 && prevRequest.sent) {
          navigate("/login", { state: { from: window.location.pathname } });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // 離脱するときにejectする
      authAxios.interceptors.request.eject(requestIntercept);
      authAxios.interceptors.response.eject(responseIntercept);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.access_token]);

  return authAxios;
};

export default useAuthAxios;
