import { Route, Routes } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import CompanyUserList from "./users/list";
import CompanyUserEdit from "./users/edit";
import CompanyUserCreate from "./users/create";
import Company from "./company";

const CompanyRoute = () => {
  const { user } = useAuth();
  if (!user?.is_superuser) {
    return <>U don't have permission!!!</>;
  }
  return (
    <Routes>
      <Route path="/company" element={<Company />} />
      <Route path="/user/*">
        <Route index={true} element={<CompanyUserList />} />
        <Route path="create" element={<CompanyUserCreate />} />
        <Route path=":id" element={<CompanyUserEdit />} />
      </Route>
    </Routes>
  );
};

export default CompanyRoute;
