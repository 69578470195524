import React from "react";
import {
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { ImageContainer } from "../media/ImageContainer";
import MediaService from "../../../infrastructure/api/post/media";
import { InputText } from "../../forms/InputText";
import { errorConverter } from "../../../utils/fastapi";
import {
  SelectMultipleWithActions,
  SelectWithActions,
} from "../../forms/Select";
import { CreateModal } from "../CreateModal";
import { EditModal } from "../EditModal";
import { DeleteConfirmation } from "../DeleteConfirmation";
import { TrashIcon } from "@heroicons/react/24/outline";
import { InjectedPostProps } from "../../../hoc/post/makePost";
import { CategoryType } from "../../../types/category";

interface MenuBarProps extends InjectedPostProps {
  shrinkMenuRef: React.RefObject<HTMLDivElement>;
  handleShrink: () => void;
  handleUpShrink: () => void;
}

export const MenuBar: React.FC<MenuBarProps> = ({
  shrinkMenuRef,
  handleShrink,
  handleUpShrink,
  post,
  selectThumbnail,
  handleChange,
  errors,
  setPost,
  categories,
  createCategory,
  setEditingCategory,
  optionalErrors,
  setOptionalErrors,
  editingCategory,
  updateTag,
  updateCategory,
  tags,
  deleteTag,
  deleteCategory,
  createTag,
  postType,
}) => {
  return (
    <div
      ref={shrinkMenuRef}
      className="z-[5] absolute top-0 sm:top-auto h-1/3 sm:h-full bg-white right-0 w-full sm:w-1/4 transition-all duration-300"
    >
      <div className="absolute left-0 top-1 -ml-8 hidden sm:block">
        <label htmlFor="post_menu_shrink">
          <ArrowsRightLeftIcon className="h-6 w-6 cursor-pointer hover:text-grey-text-light" />
        </label>
        <input
          id="post_menu_shrink"
          type="checkbox"
          className="hidden"
          onChange={handleShrink}
        />
      </div>
      <div className="absolute left-0 -bottom-1 -mb-8 sm:hidden">
        <label htmlFor="post_menu_up_shrink">
          <ArrowsUpDownIcon className="h-6 w-6 cursor-pointer hover:text-grey-text-light" />
        </label>
        <input
          id="post_menu_up_shrink"
          type="checkbox"
          className="hidden"
          onChange={handleUpShrink}
        />
      </div>
      <div className="h-full overflow-scroll p-4">
        <div className="flex flex-col gap-4">
          <div>
            <ImageContainer
              onSelect={selectThumbnail}
              service={MediaService}
              selected={post.media_id ? [post.media_id] : []}
            />
            {post.media_url && (
              <img
                src={post.media_url}
                className="w-full h-48 rounded-lg object-fill object-center mb-2"
                alt="Selected thumbnail"
              />
            )}
          </div>
          <InputText
            label={"URLスラッグ"}
            name="slug"
            value={post.slug}
            handleChange={handleChange}
            error={errors ? errorConverter(errors?.detail, "slug") : ""}
          />
          <SelectWithActions<CategoryType, number>
            label="カテゴリー"
            name="category_id"
            value={post.category_id || 0}
            handleChange={(v) => {
              setPost((prevState) => ({ ...prevState, category_id: v }));
            }}
            error={errors ? errorConverter(errors?.detail, "category_id") : ""}
            options={categories}
            valKey="id"
            labelKey="name"
            labelActions={
              <CreateModal
                handleSave={() => {
                  return createCategory().catch((error) => {
                    throw error;
                  });
                }}
                openCallback={() => {
                  setEditingCategory({
                    name: "",
                    author_id: 11,
                    slug: "",
                    post_type: postType,
                  });
                  optionalErrors && setOptionalErrors(null);
                }}
              >
                <InputText
                  label="カテゴリー名"
                  name="name"
                  value={editingCategory ? editingCategory.name : ""}
                  handleChange={(e) => {
                    setEditingCategory((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  error={
                    optionalErrors
                      ? errorConverter(optionalErrors?.detail, "name")
                      : ""
                  }
                />
                <InputText
                  label="URLスラッグ"
                  name="slug"
                  value={editingCategory ? editingCategory.slug : ""}
                  handleChange={(e) => {
                    setEditingCategory((prevState) => ({
                      ...prevState,
                      slug: e.target.value,
                    }));
                  }}
                  error={
                    optionalErrors
                      ? errorConverter(optionalErrors?.detail, "slug")
                      : ""
                  }
                />
              </CreateModal>
            }
            actions={(obj, index) => (
              <div className="flex gap-1">
                <EditModal
                  button={<PencilIcon className="h-6 w-6 text-green-500" />}
                  handleSave={() => {
                    return updateCategory(obj, index).catch((error) => {
                      throw error;
                    });
                  }}
                  openCallback={() => {
                    setEditingCategory(obj);
                    optionalErrors && setOptionalErrors(null);
                  }}
                >
                  <InputText
                    label="カテゴリー名"
                    name="name"
                    value={editingCategory ? editingCategory.name : ""}
                    handleChange={(e) => {
                      setEditingCategory((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    error={
                      optionalErrors
                        ? errorConverter(optionalErrors?.detail, "name")
                        : ""
                    }
                  />
                  <InputText
                    label="URLスラッグ"
                    name="slug"
                    value={editingCategory ? editingCategory.slug : ""}
                    handleChange={(e) => {
                      setEditingCategory((prevState) => ({
                        ...prevState,
                        slug: e.target.value,
                      }));
                    }}
                    error={
                      optionalErrors
                        ? errorConverter(optionalErrors?.detail, "slug")
                        : ""
                    }
                  />
                </EditModal>
                <DeleteConfirmation
                  button={<TrashIcon className="h-6 w-6 text-red-300" />}
                  onDelete={() => {
                    deleteCategory(obj.id, index);
                  }}
                />
              </div>
            )}
          />

          <SelectMultipleWithActions<CategoryType, number>
            label="タグ"
            name="tag_ids"
            value={post.tag_ids || []}
            handleChange={(v) => {
              setPost((prevState) => ({ ...prevState, tag_ids: v }));
            }}
            error={errors ? errorConverter(errors?.detail, "tag_ids") : ""}
            options={tags}
            valKey="id"
            labelKey="name"
            labelActions={
              <CreateModal
                handleSave={() => {
                  return createTag().catch((error) => {
                    throw error;
                  });
                }}
                openCallback={() => {
                  setEditingCategory({
                    name: "",
                    author_id: 11,
                    slug: "",
                    post_type: postType,
                  });
                  optionalErrors && setOptionalErrors(null);
                }}
              >
                <InputText
                  label="タグー名"
                  name="name"
                  value={editingCategory ? editingCategory.name : ""}
                  handleChange={(e) => {
                    setEditingCategory((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  error={
                    optionalErrors
                      ? errorConverter(optionalErrors?.detail, "name")
                      : ""
                  }
                />
                <InputText
                  label="URLスラッグ"
                  name="slug"
                  value={editingCategory ? editingCategory.slug : ""}
                  handleChange={(e) => {
                    setEditingCategory((prevState) => ({
                      ...prevState,
                      slug: e.target.value,
                    }));
                  }}
                  error={
                    optionalErrors
                      ? errorConverter(optionalErrors?.detail, "slug")
                      : ""
                  }
                />
              </CreateModal>
            }
            actions={(obj, index) => (
              <div className="flex gap-1">
                <EditModal
                  button={<PencilIcon className="h-6 w-6 text-green-500" />}
                  handleSave={() => {
                    return updateTag(obj, index).catch((error) => {
                      throw error;
                    });
                  }}
                  openCallback={() => {
                    setEditingCategory(obj);
                    optionalErrors && setOptionalErrors(null);
                  }}
                >
                  <InputText
                    label="タグー名"
                    name="name"
                    value={editingCategory ? editingCategory.name : ""}
                    handleChange={(e) => {
                      setEditingCategory((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    error={
                      optionalErrors
                        ? errorConverter(optionalErrors?.detail, "name")
                        : ""
                    }
                  />
                  <InputText
                    label="URLスラッグ"
                    name="slug"
                    value={editingCategory ? editingCategory.slug : ""}
                    handleChange={(e) => {
                      setEditingCategory((prevState) => ({
                        ...prevState,
                        slug: e.target.value,
                      }));
                    }}
                    error={
                      optionalErrors
                        ? errorConverter(optionalErrors?.detail, "slug")
                        : ""
                    }
                  />
                </EditModal>
                <DeleteConfirmation
                  button={<TrashIcon className="h-6 w-6 text-red-300" />}
                  onDelete={() => {
                    deleteTag(obj.id, index);
                  }}
                />
              </div>
            )}
          />
          <InputText
            type="textarea"
            label="抜粋"
            name="excerpt"
            value={post.excerpt || ""}
            handleChange={handleChange}
            error={errors ? errorConverter(errors?.detail, "excerpt") : ""}
          />
        </div>
      </div>
    </div>
  );
};
