import React from "react";
import { FundType, ImageType } from "../../../../types/fund";
import { FundImageContainer } from "../../components/FundImageContainer";

interface ThirdStepProps {
  setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
  inputValues: FundType;
}

export const FundImages: React.FC<ThirdStepProps> = ({
  inputValues,
  setInputValues,
}) => (
  <FundImageContainer<ImageType>
    button="ファンド画像を追加"
    buttonClassName="bg-primary-main border p-2 rounded"
    images={inputValues.images || []}
    deleteCallback={(data) => {
      setInputValues((prevState) => ({ ...prevState, images: data }));
    }}
    dropCallback={(data) => {
      setInputValues((prevState) => ({ ...prevState, images: data }));
    }}
    selectCallback={(data) => {
      setInputValues((prevState) => ({ ...prevState, images: data }));
    }}
    relKey="fund_id"
    relValue={inputValues.id}
  />
);
