import React, { createContext, useEffect, useState } from "react";
import { AuthContextType } from "../store/auth";
import { UserContextType } from "../types/companyUser";
import AuthService from "../infrastructure/api/company/auth";

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserContextType | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    new AuthService()
      .refresh_token()
      .then((data) => {
        setUser(data);
        setAuthenticated(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <>Loading</>;
  }
  return (
    <AuthContext.Provider
      value={[user, setUser, authenticated, setAuthenticated]}
    >
      {children}
    </AuthContext.Provider>
  );
};
