import React, { ChangeEvent } from "react";
import { classNames } from "../../utils";
import { Label } from "../ui/Label";
import { convertToNumber, numberWithCommas } from "../../utils/numbers";

interface InputCurrencyProps {
  label: string;
  name: string;
  value: number | null | undefined;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    num?: number | null
  ) => void;
  error: string | string[];
  required?: boolean;
  classname?: string;
  parentClassName?: string;
  labelClassName?: string;
  rightLabel?: string;
  attr?: object;
}

export const InputCurrency: React.FC<InputCurrencyProps> = ({
  label,
  name,
  value,
  handleChange,
  error,
  required = true,
  classname = "",
  parentClassName = "",
  labelClassName = "",
  rightLabel = "万円",
  attr = {}
}) => {
  return (
    <div className={`flex flex-col gap-1 ${parentClassName}`.trim()}>
      {label && (
        <Label
          htmlFor={`id_${name}`}
          className={labelClassName}
          label={label}
          error={error.length > 0}
        />
      )}
      <div className="relative flex items-center">
        <input
          type="text"
          id={`id_${name}`}
          name={name}
          required={required}
          maxLength={19}
          className={classNames(
            classname,
            "border p-1 rounded text-grey-text-main w-full",
            error.length > 0 ? "border-red-500" : "border-grey-boarder"
          )}
          value={numberWithCommas(value)}
          onChange={(event) => {
            const v = convertToNumber(event.target.value);
            handleChange(event, v);
          }}
          {...attr}
        />
        <span className="absolute right-1 text-grey-text-light pointer-events-none bg-white">
          {rightLabel}
        </span>
      </div>

      {Array.isArray(error) ? (
        error.map((text, index) => (
          <span className="text-sm text-red-500 block" key={index}>
            {text}
          </span>
        ))
      ) : (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </div>
  );
};
