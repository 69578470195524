import React from "react";
import { Modal } from "./Modal";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { ModalActionLayout } from "../layouts/ModalActionLayout";
import { Button } from "./button/Button";
import { ButtonVariant } from "./button/constants";

type CreateModalType = {
  button?: string | React.ReactElement;
  handleSave: () => Promise<void>;
  children: React.ReactNode;
  openCallback?: () => void;
};

export const CreateModal: React.FC<CreateModalType> = ({
  button,
  handleSave,
  children,
  openCallback,
}) => {
  return (
    <Modal
      buttonClassName={" "}
      popoverClassName={
        "fixed h-screen w-screen left-0 top-0 bg-gray-900/80 z-20 flex items-center justify-center"
      }
      openCallback={openCallback}
      button={
        button ? (
          button
        ) : (
          <>
            <PlusCircleIcon className="h-6 w-6 text-green-400" />
          </>
        )
      }
    >
      {(open) => (
        <ModalActionLayout>
          {children}
          <div className="w-full flex gap-1">
            <Button
              variant={ButtonVariant.Cancel}
              onClick={() => {
                open.close();
              }}
            />
            <Button
              variant={ButtonVariant.Save}
              value="追加"
              onClick={() => {
                handleSave().then(() => {
                  open.close();
                });
              }}
            />
          </div>
        </ModalActionLayout>
      )}
    </Modal>
  );
};
