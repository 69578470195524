import React, { useEffect, useState } from "react";
import { Modal } from "../../../../components/ui/Modal";
import AccordionLayout from "../../../../components/layouts/AccordionLayout";
import { FundStepProps, FundType, OperatorType } from "../../../../types/fund";
import OperatorService from "../../../../infrastructure/api/funds/operator";

interface FifthStepProps extends FundStepProps {
  setInputValues: React.Dispatch<React.SetStateAction<FundType>>;
}

export const Operators: React.FC<FifthStepProps> = (props) => {
  const { inputValues, setInputValues } = props;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeIndex2, setActiveIndex2] = useState<number>(0);
  const [operators, setOperators] = useState<OperatorType[]>([]);

  useEffect(() => {
    new OperatorService().getList(0, 100).then((data) => {
      setOperators(data.data);
    });
  }, []);

  const handleAdd = (data: OperatorType) => {
    const cpData = { ...inputValues };
    if (cpData.operators) {
      cpData.operators.push(data);
    } else {
      cpData.operators = [data];
    }
    setInputValues(cpData);
  };

  const handleRemove = (data: OperatorType) => {
    const cpData = { ...inputValues };
    if (cpData.operators) {
      const index = cpData.operators.findIndex((value) => value.id === data.id);
      if (index !== -1) {
        cpData.operators.splice(index, 1);
        setInputValues(cpData);
      }
    }
  };
  return (
    <div>
      <Modal button="運営者を選択">
        {(open) => (
          <div className="h-full flex justify-between flex-col overflow-scroll">
            <ul>
              {operators.map((val, index) => (
                <li key={val.id}>
                  <AccordionLayout<number>
                    title={val.name || ""}
                    index={val.id || 0}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                  >
                    <div className="mb-2">
                      URL: <small>{val.url || "ー"}</small>
                    </div>
                    <table className="border-collapse border border-grey-boarder overflow-x-scroll w-full">
                      <tbody>
                        {val.body?.map((v, i) => (
                          <tr key={index + i}>
                            <th className="border p-2 border-grey-boarder">
                              {v.label}
                            </th>
                            <td className="border p-2 border-grey-boarder">
                              {v.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AccordionLayout>
                  <div className="flex gap-2">
                    {(!inputValues.operators ||
                      inputValues.operators.findIndex(
                        (obj) => obj.id === val.id
                      ) === -1) && (
                      <button
                        type="button"
                        className="text-blue-500"
                        onClick={() => {
                          handleAdd(val);
                        }}
                      >
                        選択
                      </button>
                    )}
                    {inputValues.operators &&
                      inputValues.operators.findIndex(
                        (obj) => obj.id === val.id
                      ) !== -1 && (
                        <button
                          type="button"
                          className="text-red-500"
                          onClick={() => {
                            handleRemove(val);
                          }}
                        >
                          取り除く
                        </button>
                      )}
                  </div>
                </li>
              ))}
            </ul>
            <div className="sticky bottom-0 w-full">
              <button
                onClick={() => {
                  open.close();
                }}
                className="flex-1 rounded bg-grey-boarder p-2 text-center w-full"
                type="button"
              >
                戻る
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div>
        {inputValues.operators?.map((val, index) => (
          <div key={val.id}>
            <AccordionLayout<number>
              title={val.name || ""}
              index={val.id || 0}
              activeIndex={activeIndex2}
              setActiveIndex={setActiveIndex2}
              bg="bg-grey-boarder"
            >
              <div className="mb-2">
                URL: <small>{val.url || "ー"}</small>
              </div>
              <table className="border-collapse border border-grey-boarder overflow-x-scroll w-full">
                <tbody>
                  {val.body?.map((v, i) => (
                    <tr key={index + i}>
                      <th className="border p-2 border-grey-boarder">
                        {v.label}
                      </th>
                      <td className="border p-2 border-grey-boarder">
                        {v.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionLayout>
          </div>
        ))}
      </div>
    </div>
  );
};
