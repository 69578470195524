import React, { useCallback, useEffect, useRef, useState } from "react";
import Stepper from "../../../../components/ui/stepper/Stepper";
import {
  DistributionType,
  FundType,
  StepsObjType,
} from "../../../../types/fund";
import { FastapiErrorT } from "../../../../utils/fastapi";
import { fundInitial } from "../../data";
import { Form } from "../../../../components/ui/Form";
import { CreateSummary } from "./CreateSummary";
import { Distribution } from "./Distribution";
import { FundImages } from "../components/FundImages";
import { ObjectsInformation } from "../components/ObjectsInformation";
import { Operators } from "../components/Operators";
import { Promotions } from "../components/Promotions";
import StepService from "../../../../infrastructure/api/funds/step";
import { CompletedButton } from "./CompletedButton";
import { CompleteButton } from "./CompleteButton";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import FundService from "../../../../infrastructure/api/funds/fund";
import logo from "../../../../assets/images/logo.png";
import { TextButton } from "../../../../components/ui/button/Button";
import { CreateDocuments } from "./CreateDocuments";

const FundCreate: React.FC = () => {
  const [inputValues, setInputValues] = useState<FundType>(fundInitial);
  const [errors, setErrors] = useState<FastapiErrorT>();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const currentRef = useRef<number>(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get("fund_id");
    if (id) {
      new FundService()
        .getFund(id)
        .then((data) => {
          const steps = data.steps ? data.steps : inputValues.steps;
          const stepsArr = Object.entries(steps);
          for (let i = 0; i < stepsArr.length; i++) {
            if (!stepsArr[i][1]) {
              currentRef.current = i;
              break;
            }
          }
          setInputValues((prev) => ({ ...prev, ...data, steps: steps }));
        })
        .catch((err) => {
          alert(err.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValues.total_investment || inputValues.investment_amount) {
      const subordinatedInvestment = inputValues.total_investment
        ? inputValues.total_investment * 0.1
        : null;
      const amountRaised = subordinatedInvestment
        ? (inputValues.total_investment as number) - subordinatedInvestment
        : null;
      const investmentAmount = inputValues.investment_amount;

      setInputValues((prevState) => ({
        ...prevState,
        amount_raised: amountRaised,
        subordinated_investment: subordinatedInvestment,
        number_of_units:
          investmentAmount && amountRaised
            ? (amountRaised * 10000) / investmentAmount
            : null,
      }));
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        amount_raised: null,
        subordinated_investment: null,
        number_of_units: null,
      }));
    }
  }, [
    inputValues.total_investment,
    inputValues.investment_amount,
    setInputValues,
  ]);

  useEffect(() => {
    if (inputValues.total_distributions) {
      const distributions: DistributionType[] = [];
      for (let i = 0; i < inputValues.total_distributions; i++) {
        if (inputValues.distributions && inputValues.distributions.length > i) {
          distributions.push({ ...inputValues.distributions[i], order: i });
        } else {
          distributions.push({
            order: i,
            start: "",
            end: "",
            receive_date: "",
          });
        }
      }
      if (
        inputValues.distributions &&
        inputValues.distributions.length > distributions.length
      ) {
        for (
          let i = distributions.length;
          i < inputValues.distributions.length;
          i++
        ) {
          if (inputValues.distributions[i].id) {
            let cpDistribution = inputValues.distributions[i];
            cpDistribution.order = 404;
            distributions.push(cpDistribution);
          }
        }
      }
      setInputValues((prevState) => ({
        ...prevState,
        steps: { ...prevState.steps, fundSecond: false },
        distributions: distributions,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues.total_distributions]);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
      num: number | null = null
    ) => {
      const { name, value } = event.target;
      const dataList = event.target.getAttribute("data-list");
      const dataIndex = parseInt(event.target.getAttribute("data-index") || "");
      if (dataList) {
        if (!isNaN(dataIndex)) {
          const cpData = { ...inputValues };
          // @ts-ignore
          cpData[dataList][dataIndex][name] = value;
          setInputValues(cpData);
        }
      } else {
        setInputValues((prevValues) => ({
          ...prevValues,
          [name]: num ? num : value,
        }));
      }
    },
    [inputValues]
  );

  const handleSubmit = (step: keyof StepsObjType): boolean => {
    const stepService = new StepService();
    const cpData = { ...inputValues };
    let completed: boolean = false;
    switch (step) {
      case "fundFirst":
        cpData.steps.fundFirst = true;
        stepService
          .firstStep(cpData)
          .then((data) => {
            if ((!searchParams.get("fund_id") || !cpData.id) && data.id) {
              setSearchParams({ fund_id: data.id.toString() });
            }
            setInputValues((prevState) => ({ ...prevState, ...data }));
            setErrors(undefined);
            completed = true;
          })
          .catch((err) => {
            cpData.steps.fundFirst = false;
            setErrors(JSON.parse(err));
          });
        return completed;
      case "fundSecond":
        cpData.steps.fundSecond = true;
        if (inputValues.distributions) {
          const cpDist = [...inputValues.distributions];
          cpDist.forEach((val) => {
            val.fund_id = inputValues.id;
          });
          stepService
            .secondStep(cpDist)
            .then((data) => {
              setInputValues((prevState) => ({
                ...prevState,
                steps: cpData.steps,
                distributions: data,
              }));
              setErrors(undefined);
              completed = true;
            })
            .catch((err) => {
              cpData.steps.fundSecond = false;
              setErrors(JSON.parse(err));
            });
        }
        return completed;
      case "fundThird":
        cpData.steps.fundThird = true;
        stepService
          .thirdStep(inputValues.images || [])
          .then((data) => {
            setInputValues((prevState) => ({
              ...prevState,
              steps: cpData.steps,
              images: data,
            }));
            setErrors(undefined);
            completed = true;
          })
          .catch((err) => {
            cpData.steps.fundThird = false;
            setErrors(JSON.parse(err));
          });
        return completed;
      case "fundFourth":
        cpData.steps.fundFourth = true;
        stepService
          .fourthStep(inputValues.objects_of_information || [])
          .then((data) => {
            setInputValues((prevState) => ({
              ...prevState,
              steps: cpData.steps,
              objects_of_information: data,
            }));
            setErrors(undefined);
            completed = true;
          })
          .catch((err) => {
            cpData.steps.fundFourth = false;
            setErrors(JSON.parse(err));
          });
        return completed;
      case "fundFifth":
        cpData.steps.fundFifth = true;
        const operators = inputValues.operators
          ? inputValues.operators?.map((val) => val.id as number)
          : [];
        stepService
          .fifthStep({ fund_id: inputValues.id, operators: operators })
          .then((data) => {
            setInputValues((prevState) => ({
              ...prevState,
              steps: cpData.steps,
              operators: data,
            }));
            setErrors(undefined);
            completed = true;
          })
          .catch((err) => {
            cpData.steps.fundFifth = false;
            setErrors(JSON.parse(err));
          });
        return completed;
      case "fundSix":
        cpData.steps.fundSix = true;
        const promotions = inputValues.promotions
          ? inputValues.promotions?.map((val) => val.id as number)
          : [];
        stepService
          .sixStep({ fund_id: inputValues.id, promotions: promotions })
          .then((data) => {
            setInputValues((prevState) => ({
              ...prevState,
              steps: cpData.steps,
              promotions: data,
            }));
            setErrors(undefined);
            completed = true;
          })
          .catch((err) => {
            cpData.steps.fundSix = false;
            setErrors(JSON.parse(err));
          });
        return completed;
      case "fundSeven":
        if (inputValues.id){
          stepService.seventhStep(inputValues.id)
            .then(() => {
              navigate('/funds/fund')
            })
            .catch((err) => {
              setErrors(JSON.parse(err));
            })
        }
        return false;
      default:
        return false;
    }
  };

  const handleDraft = () => {
    const promotions = inputValues.promotions
      ? inputValues.promotions?.map((val) => val.id as number)
      : [];
    const operators = inputValues.operators
      ? inputValues.operators?.map((val) => val.id as number)
      : [];
    new StepService()
      .draft({ ...inputValues, promotions: promotions, operators: operators })
      .then((data) => {
        if ((!searchParams.get("fund_id") || !inputValues.id) && data.id) {
          setSearchParams({ fund_id: data.id.toString() });
        }
        setInputValues((prevState) => ({ ...prevState, ...data }));
        setErrors(undefined);
      })
      .catch((err) => {
        setErrors(JSON.parse(err));
      });
  };

  if (loading) {
    return <div className="h-full"></div>;
  }

  return (
    <div className="h-full">
      <div className="w-full top-0 border border-grey-boarder p-2 bg-white z-10 flex justify-between items-center">
        <Link to="/">
          <img src={logo} className="h-10 w-18" alt="selected thumnail" />
        </Link>
        <div className="flex justify-end items-center gap-2">
          <TextButton text="下書き保存" onClick={handleDraft} />
        </div>
      </div>
      <div className="relative">
        <Stepper
          completed={Object.entries(inputValues.steps)
            .filter((v) => v[1])
            .map((value) => value[0])}
          top={"3.62rem"}
          startFrom={currentRef.current}
        >

          <Stepper.Steps>
            <Stepper.Step
              id="fundFirst"
              name="概要"
              completed={inputValues.steps.fundFirst}
              completedButton={(incrementCurrentStep) => (
                <CompletedButton
                  id="fundFirst"
                  next={incrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  id="fundFirst"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <Form errors={errors}>
                <CreateSummary
                  setInputValues={setInputValues}
                  inputValues={inputValues}
                  errors={errors}
                  handleChange={handleChange}
                />
              </Form>
            </Stepper.Step>
            <Stepper.Step
              id="fundSecond"
              name="配当スケジュール"
              completed={inputValues.steps.fundSecond}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundSecond"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundSecond"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <Form errors={errors}>
                <Distribution
                  inputValues={inputValues}
                  errors={errors}
                  handleChange={handleChange}
                />
              </Form>
            </Stepper.Step>
            <Stepper.Step
              id="fundThird"
              name="ファンド写真"
              completed={inputValues.steps.fundThird}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundThird"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundThird"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <FundImages
                setInputValues={setInputValues}
                inputValues={inputValues}
              />
            </Stepper.Step>
            <Stepper.Step
              id="fundFourth"
              name="物件情報"
              completed={inputValues.steps.fundFourth}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundFourth"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundFourth"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <ObjectsInformation
                setInputValues={setInputValues}
                inputValues={inputValues}
                errors={errors}
                handleChange={handleChange}
              />
            </Stepper.Step>
            <Stepper.Step
              id="fundFifth"
              name="運営者"
              completed={inputValues.steps.fundFifth}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundFifth"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundFifth"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <Operators
                inputValues={inputValues}
                setInputValues={setInputValues}
                errors={errors}
                handleChange={handleChange}
              />
            </Stepper.Step>
            <Stepper.Step
              id="fundSix"
              name="キャンペーン"
              completed={inputValues.steps.fundSix}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundSix"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundSix"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <Promotions
                setInputValues={setInputValues}
                inputValues={inputValues}
                errors={errors}
                handleChange={handleChange}
              />
            </Stepper.Step>
            <Stepper.Step
              id="fundSeven"
              name="関連書面"
              completed={inputValues.steps.fundSeven}
              completedButton={(incrementCurrentStep, decrementCurrentStep) => (
                <CompletedButton
                  id="fundSeven"
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  setState={setInputValues}
                />
              )}
              button={(incrementCurrentStep, decrementCurrentStep) => (
                <CompleteButton
                  next={incrementCurrentStep}
                  back={decrementCurrentStep}
                  id="fundSeven"
                  onSubmit={handleSubmit}
                />
              )}
            >
              <CreateDocuments
                inputValues={inputValues}
                errors={errors}
                handleChange={handleChange}
              />
            </Stepper.Step>
          </Stepper.Steps>
        </Stepper>
      </div>
    </div>
  );
};

export default FundCreate;
