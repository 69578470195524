import React, { useCallback, useMemo, useState } from "react";
import PostService from "../../infrastructure/api/post/post";
import { Subtract } from "utility-types";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Column, SortingRule } from "react-table";
import { urlDecodeObj, urlEncodeObj } from "../../utils/query";
import { prepareColumnsForTable } from "../../utils/react-table";
import { ReactTable } from "../../components/ui/Table";
import { DeleteConfirmation } from "../../components/ui/DeleteConfirmation";

type WithEditProps = {
  postType: "news" | "blog";
};

export interface InjectedListPostProps extends WithEditProps {
  memorizedTable: JSX.Element;
}

export const listPost =
  <P extends InjectedListPostProps>(
    Component: React.ComponentType<P>
  ): React.FC<Subtract<P, InjectedListPostProps> & WithEditProps> =>
  ({ postType, ...props }: WithEditProps) => {
    const [posts, setPosts] = useState<PaginatedDataType<PostListType>>({
      count: 0,
      data: [],
    });
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(
      location.search || "page=0&limit=20"
    );
    const fetchData = useCallback(
      (page: number, l: number, order_by: SortingRule<PostListType>[]) => {
        const stringURL = urlEncodeObj({
          page: page,
          limit: l,
          order_by: order_by,
        });
        if (searchParams.toString() !== stringURL) {
          setSearchParams(stringURL);
        }
        new PostService()
          .getList(postType, page, l, order_by)
          .then((data) => {
            setPosts(data);
          })
          .catch((error) => {
            console.error(error);
          });
      },
      [postType, searchParams, setSearchParams]
    );

    const handleDelete = useCallback(
      (index: number, id?: number) => {
        if (id) {
          new PostService()
            .deletePost(id)
            .then(() => {
              const cpData = [...posts.data];
              cpData.splice(index, 1);
              setPosts((prevState) => ({
                count: prevState.count - 1,
                data: cpData,
              }));
            })
            .catch((err) => {
              alert(JSON.parse(err));
            });
        }
      },
      [posts.data]
    );
    const memoData = useMemo(() => posts.data, [posts.data]);
    const columns: Column<PostListType>[] = useMemo(
      () => [
        {
          Header: "Title",
          accessor: "title",
          Cell: (row) => (
            <div className="relative group h-10">
              <span>{row.value}</span>
              <div className="z-10 hidden group-hover:flex text-sm divide-x-2">
                <Link
                  to={`${row.row.original.id}`}
                  className="text-blue-600 hover:text-blue-400 px-1"
                >
                  Edit
                </Link>
                <DeleteConfirmation
                  buttonClassName="text-red-600 hover:text-red-400 px-1"
                  onDelete={() => {
                    handleDelete(row.row.index, row.row.original.id);
                  }}
                />
                <button className="text-blue-600 hover:text-blue-400 px-1">
                  View
                </button>
              </div>
            </div>
          ),
        },
        {
          Header: "Author",
          id: "author_id",
          accessor: (d) =>
            d.author ? `${d.author.last_name} ${d.author.first_name}` : "",
        },
        {
          Header: "Status",
          id: "status",
          accessor: (d) => (d.status === "published" ? "公開済み" : "下書き"),
        },
      ],
      [handleDelete]
    );

    const memoColumns = useMemo(
      () => prepareColumnsForTable(columns, memoData),
      [columns, memoData]
    );
    const searchObj = urlDecodeObj(searchParams.toString());
    console.count("Renders");

    const memorizedTable = React.useMemo(
      () => (
        <ReactTable<PostListType>
          columns={memoColumns}
          data={memoData}
          dataCount={posts.count}
          totalPage={Math.ceil(posts.count / Number(searchObj["limit"] || 1))}
          callback={fetchData}
          pageSizeOptions={[20, 50, 100, 300]}
          initialPageSize={Number(searchObj["limit"] || 20)}
          initialSort={searchObj["order_by"] || []}
        />
      ),
      [fetchData, memoColumns, memoData, posts.count, searchObj]
    );

    return <Component {...(props as P)} memorizedTable={memorizedTable} />;
  };
